// en.js
export const locale = {
    __MAIN_TITLE: {
        Home: 'Home',
        Company: 'Company',
        Products: 'Products',
        Quality: 'Quality',
        Contact: 'Contact',
        PVC_Film: 'PVC Film',
        Tape_PVC_Sheet: 'Tape PVC Sheet',
        Semi_Rigid_Film: 'Semi Rigid Film',
        Clear_Film: 'Clear Film',
        PU_Leather: 'PU Leather',
        PU: 'PU',
        No_Sew: 'No Sew'
    },
    __BUTTON_TEXT: {
        more: 'More',
    },
    __HOME: {
        BannerData: {
            banner_1: {
                mainTitle1: 'Formosa Plastics',
                mainTitle2: 'A Global Presence You Can Trust',
                subTitle: 'We provide PVC and PU plastics for  a wide range of applications.'
            },
            banner_2: {
                mainTitle: 'Formosa PVC Film',
                subTitle: 'Excellent choices when versatility and value are a must, \
                        which is quite economical and highly durable – two of the many reasons.'
            },
            banner_3: {
                mainTitle: 'Formosa PU Leather',
                subTitle: 'Customized and forward-looking PU leather product depend on vision, tactile, \
                        physical property and function require.'
            },
            banner_4: {
                mainTitle: 'Customized Service',
                subTitle: 'Providing customized and forward-looking product depend on vision, tactile, \
                        physical property and function require.'
            },
            banner_5: {
                mainTitle: 'Eco-friendly Solutions',
                subTitle: 'Environmentally is perfection aimed value for innovation.'
            },
            PVC_Img_Carousel: [
                'Insulation Tape', 'Partitions', 'Stationery', 'Interior'
            ],
            PU_Img_Carousel: [
                'Saddle leather', 'Gloves leather', 'Bags leather', 'Vehicle leather'
            ]
        }
    },
    __PRODUCTS: {
        PVC_Film_Page: {
            
        }
    }
}