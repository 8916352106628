import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        windows_Width: window.innerWidth,
        windows_Height: window.innerHeight,

        product_Display_index: 0,   // PU頁要顯示哪個產品的index

        // 語系控制寫在navbar.vue裡
        currentLanguage: "",

        // 預設語系
        defaultLanguage: 'en',
        
        // 語系清單
        languageList: ['tw', 'vn']
    },
    mutations: {
        windows_resize_intoVuex(state){
            state.windows_Width = window.innerWidth
            state.windows_Height = window.innerHeight
        },

        // 切換PU頁產品的index
        change_product_Display_index(state, new_Index){
            state.product_Display_index = new_Index
        },

        changeLanguage_mutationsVuex(state, lang){
            // localStorage.removeItem('language')
            // localStorage.setItem('language', lang)
            state.currentLanguage = lang
        }
    },

    actions: {},

    getters: {
        // 切換語系-除預設語系return空白到routerName_Language，給各分頁作router name用
        get_routerName_lang_from_Store: state => {
            return state.currentLanguage === state.defaultLanguage ? "" : "_lang"
        }
    },

    modules: {}
})