<template lang="pug">
main#Home
    banner_Carousel
    article#Why_Choose_Us
        h1 Why Choose Us?
        section.items_Container
            .item_Blcok(v-for="item of Why_Choose_Us_Data" :key="item.title")
                img(:src="getImgUrl(item.imgUrl)")
                h3 {{item.title}}
    
    Home_our_Products
    
    section#aboutCard_Container
        .cardGroup
            router-link.card.wow(v-for="item of homeCard" :key="item.title" :to="{name: item.title}")
                h1 {{item.title}}
                .imgCard
                    img(:src="getImgUrl(item.imgUrl)")
                p {{item.text}}
        .backgroundImg1
            img(src="@/assets/home_backgroundImg1.png")
        .backgroundImg2
            img(src="@/assets/home_backgroundImg2.png")
            img(src="@/assets/home_backgroundImg3.png")

    section#home_contact
        .textBlock
            h1 Contact Us
            p If there's any advice or suggestion, welcome to email or call us. We're willing to respond soon and appreciate your feedbacks.
            Button_Style.button_more(btnTitle="More" linkType="routerName" link="Contact" arrow="right")
        //- .bgImg
        //-     img(src="@/assets/home_page bottom_desktop.jpg")
</template>

<script>
import banner_Carousel from "@/section_components/banner_Carousel.vue"
import Home_our_Products from "@/section_components/Home_our_Products.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            // title: ``,
            // titleTemplate: null,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Formosa plastics vietnam, Provide includes PVC Film products, such as Tape PVC Sheet, Semi Rigid Film and Clear Film. By the way, we also includes PU Leather plastics products for a wide range of applications, such as PU and No Sew PU."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Formosa plastics vietnam, Provide includes PVC Film products, such as Tape PVC Sheet, Semi Rigid Film and Clear Film. By the way, we also includes PU Leather plastics products for a wide range of applications, such as PU and No Sew PU." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Formosa plastics vietnam, Provide includes PVC Film products, such as Tape PVC Sheet, Semi Rigid Film and Clear Film. By the way, we also includes PU Leather plastics products for a wide range of applications, such as PU and No Sew PU." },
            ]
        }
    },

    components: {
        banner_Carousel,
        Home_our_Products,
        Button_Style,
    },

    data() {
        return{
            Why_Choose_Us_Data: [
                {
                    title: "Excellent Product Properties",
                    imgUrl: "whyItem_01.png"
                },
                {
                    title: "Competitive Pricing",
                    imgUrl: "whyItem_02.png"
                },
                {
                    title: "Powerful Development and service",
                    imgUrl: "whyItem_03.png"
                },
                {
                    title: "Eco-friendly",
                    imgUrl: "whyItem_04.png"
                },
            ],

            homeCard: [
                {
                    title: "Company",
                    imgUrl: "homecard_company.jpg",
                    text: "Vietnam Nan Ya plastics plant belongs to the Formosa Industries Corporation, Formosa Plastics Group. It is located in Nhon Trach III Industrial Zone, Hiep Phuoc Town, Nhon Trach District, Dong Nai Province, Vietnam. It was established in 2016 and started production in August 2018. "
                },
                {
                    title: "Quality",
                    imgUrl: "homecard_quality.jpg",
                    text: "Vietnam Nan Ya plastics plant passed the ISO-9001 quality system certification in early 2020; the plastics product of Vietnam Nanya plastics plant mainly serves the domestic market, and is also exported to Asia, Europe, America and other countries and regions."
                }
            ]
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#Home
    +size(100%, auto)

    #Why_Choose_Us
        +size($max_Width_Percent, auto)
        max-width: $max_Container_Width
        margin: 90px auto 0 auto
        // *
            // border: 1px solid #000
        h1
            +text_Setting(font(14), normal, 1.1px, $title2_color, 600, "Noto Sans", center)
        section.items_Container
            +size(100%, auto)
            +flex_Center_Setting(space-between, flex-start)
            margin-top: 50px
            .item_Blcok
                +size(25%, auto)
                +flex_Center_Setting
                flex-direction: column
                img
                    +size(100%)
                    max-width: 200px
                h3
                    +text_Setting(24px, normal, normal, #313a41, 400, "Noto Sans", center)
                    width: 100%
                    margin-top: 30px
                // @for $i from 1 through 4
                //     &:nth-child(#{$i})
                //         animation: fadeInUp 1.5s (0.3s*$i) backwards
        
        @include _under_768_width()
            section.items_Container
                flex-wrap: wrap
                .item_Blcok
                    width: 48%
                    &:nth-child(1)
                        animation: fadeInLeft 1.5s 0.3s backwards
                    &:nth-child(2)
                        animation: fadeInRight 1.5s 0.3s backwards
                    &:nth-child(3)
                        animation: fadeInLeft 1.5s 0.6s backwards
                    &:nth-child(4)
                        animation: fadeInRight 1.5s 0.9s backwards
                    h3
                        font-size: 18px
                .item_Blcok:nth-child(2) ~ .item_Blcok
                    margin-top: 50px
        @include _under_576_width()
            h1
                font-size: 32px


    #aboutCard_Container
        +size(100%, auto)
        padding-top: 150px
        padding-bottom: 242px
        position: relative
        overflow: hidden
        *
            // border: 1px solid #000
        .cardGroup
            +size($max_Width_Percent, 1125px)
            +flex_Center_Setting(space-between, flex-start)
            max-width: $max_Container_Width
            margin: 0 auto
            .card
                +size(47.5%, auto)
                +flex_Center_Setting(space-between, center)
                max-width: 480px
                min-height: 60%
                flex-direction: column
                padding: 30px 40px 50px 40px
                border-radius: 15px
                box-shadow: 10px 10px 11px 0 rgba(101, 136, 141, 0.66)
                transition: 0.5s
                h1
                    +text_Setting(font(12), normal, 1.1px, $title2_color, 600, "Noto Sans", center)
                    padding-bottom: 10px
                .imgCard
                    +size(100%)
                    margin-top: 27px
                    img
                        +size(100%)
                p
                    +size(100%)
                    +text_Setting(font(3), 1.5, 0.18px, $title2_color, 400, "Noto Sans", left)
                    margin-top: 30px
                
                &:first-child
                    border: solid 3px #2edbef
                    animation: fadeInLeft 1.5s 0.5s backwards
                    h1
                        border-bottom: 7px solid #c3f4f9
                &:last-child
                    align-self: flex-end
                    border: solid 3px #e0e849
                    animation: fadeInRight 1.5s 0.5s backwards
                    h1
                        border-bottom: 5px solid #e0e849
                &:hover
                    box-shadow: 5px 7px 6px 0 rgba(0, 0, 0, 0.5)
                    transform: translateY(-8px)
        .backgroundImg1
            position: absolute
            top: -80px
            right: -190px
            z-index: -1
            img
                +size(100%)
        .backgroundImg2
            +size(600px, 900px)
            position: absolute
            bottom: 0
            left: 0
            z-index: -1
            img:first-child
                +size(100%, auto)
                position: absolute
                left: 0
                bottom: -200px
            img:last-child
                +size(auto, 100%)
                position: absolute
                left: -100px
                bottom: 0

        @include _under_992_width()
            .cardGroup
                flex-direction: column
                justify-content: center
                align-items: center
                height: auto
                .card
                    width: 100%
                    &:last-child
                        align-self: auto
                        margin-top: 90px
        @include _under_768_width()
            padding-top: 100px
            padding-bottom: 200px
            .cardGroup 
                .card
                    padding: 18px 27px 38px 27px
                    &:last-child
                        margin-top: 60px
                .imgCard
                    margin-top: 20px
                p
                    margin-top: 32px
                    line-height: 1.2
        @include _under_576_width()
            .cardGroup .card
                h1
                    font-size: 30px
                p
                    font-size: 16px


    #home_contact
        // *
        //     border: 1px solid #fff
        +size(100%, auto)
        position: relative
        background-image: url("~@/assets/home_page bottom_desktop.jpg")
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        .textBlock
            +flex_Center_Setting
            flex-direction: column
            width: $max_Width_Percent
            max-width: $max_Container_Width
            margin: 0 auto
            padding: 100px 0
            h1, p
                width: 100%
                max-width: 900px
            h1
                +text_Setting(font(17), 1, 2.25px, #fafafa, 600, "Noto Sans", center)
            p
                +text_Setting(font(5), 1.62, 2.1px, #fafafa, 400, "Noto Sans", center)
                margin: 30px 0 50px 0
        @include _under_576_width()
            background-image: url("~@/assets/home_page bottom_mobile.jpg")
            .textBlock
                padding: 50px 0
                h1
                    font-size: 30px
                p
                    font-size: 16px
</style>