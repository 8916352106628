<template lang="pug">
#cardBody(:class="{horizontalCard_992: horizontalCard_992_isActive, horizontalCard_768: horizontalCard_768_isActive, horizontalCard_576: horizontalCard_576_isActive}")
    .cardTop
        img(:src="getImgUrl(imgSrc)")
        h2.cardTitle {{cardTitle}}
    .cardBottom
        p Excellent electrical insulation and resistance to chemicals. Available in various colors for identification and purposes.

</template>

<script>
export default {
    props: {
        cardTitle: String,
        imgSrc: String,
        // horizontal_Card_Active: {
        //     type: Boolean,
        //     default: false
        // },
        // horizontal_Active_width: {
        //     type: Number,
        //     default: null
        // },

        horizontalCard_width: {
            type: Number,
        },
    },

    data() {
        return{
            horizontalCard_isActive: false,
            horizontalCard_992_isActive: false,
            horizontalCard_768_isActive: false,
            horizontalCard_576_isActive: false
        }
    },

    // computed: {},

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        horizontalCard_media_Control() {
            switch(this.horizontalCard_width){
                case 992:
                    this.horizontalCard_992_isActive = true
                    break
                case 768:
                    this.horizontalCard_768_isActive = true
                    break
                case 576:
                    this.horizontalCard_576_isActive = true
                    break
            }
        }
    },

    created() {
        this.horizontalCard_media_Control()
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#cardBody
    +flex_Center_Setting(space-between)
    flex-direction: column
    width: 100%
    // border: 1px solid #000
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
    background-color: #fff
    *
        transition: 0.4s
        // border: 1px solid #000
    .cardTop
        +size(100%, 50%)
        position: relative
        &::before
            +pseudoBlock(100%, 100%, rgba(#000, 0.3))
        img
            +size(100%)
        h2.cardTitle
            +text_Setting(font(6), 1, 1px, #fff, 400, "Noto Sans", center)
            width: auto
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
            border-bottom: 4px solid #fff
            padding-bottom: 10px
    .cardBottom
        +size(100%, auto)
        padding: 30px 30px 70px 30px
        p
            +text_Setting(font(3), 1, 1px, #223b51, 400, "Noto Sans", center)
            width: 100%

// =============================================
@mixin horizontalCard_css
    width: 100%
    flex-direction: row
    padding-right: 13px
    .cardTop
        +size(auto, 100%)
        flex: 1
    .cardBottom
        padding: 0
        margin-left: 40px
        flex: 1
        p
            text-align: left
#cardBody.horizontalCard
    +horizontalCard_css
#cardBody.horizontalCard_992
    @include _under_992_width()
        +horizontalCard_css
#cardBody.horizontalCard_768
    @include _under_768_width()
        +horizontalCard_css
#cardBody.horizontalCard_576
    @include _under_576_width()
        +horizontalCard_css
</style>