<template lang="pug">
main#PVC_Film_Page
    pageTop(pageTitle="PVC Film" imgSrc="Products/PVC/PVC page top img_desktop.jpg" imgSrc_Mobile="Products/PVC/PVC page top img_desktop.jpg")
    section.PU_PVC_Introduce_section
        article.PU_PVC_article
            p.introduce_section_Text Depending on the way it’s formulated, PVC film can be either rigid, semi-rigid or flexible. PVC film used in nearly every industry, from automotive, building and advertisement to printing and housewares. 
        section.Introduce_card_section
            h2.PU_PVC_section_Title_h2 PVC Film Products
            section.Introduce_cardGroup
                section.PU_PVC_card(v-for="item of Introduce_card_Data" :key="item.Product_Name")
                    figure
                        img(:src="getImgUrl(`Products/PVC/${item.imgSrc}`)", :alt="item.Product_Name")
                    article.PU_PVC_card_TextBlock
                        h3.PU_PVC_section_Title_h3 {{item.Product_Name}}
                        p.introduce_section_Text {{item.text}}
                        Button_Style.button_more(btnTitle="view more" linkType="routerName" :link="item.href" arrow="right")
    section.pageBottom_section
        article.pageBottom_article
            h2.PU_PVC_section_Title_h2.pageBottomText Formosa Plastics provide you with excellent technical services.
            Button_Style.button_more(btnTitle="Contact Us" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `PVC Film-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Depending on the way it’s formulated, PVC film can be either rigid, semi-rigid or flexible. PVC film used in nearly every industry, from automotive, building and advertisement to printing and housewares."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Depending on the way it’s formulated, PVC film can be either rigid, semi-rigid or flexible. PVC film used in nearly every industry, from automotive, building and advertisement to printing and housewares." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Depending on the way it’s formulated, PVC film can be either rigid, semi-rigid or flexible. PVC film used in nearly every industry, from automotive, building and advertisement to printing and housewares." },
            ]
        }
    },

    components: {
        pageTop,
        Button_Style
    },

    data() {
        return{
            Introduce_card_Data: [
                {
                    Product_Name: "Tape PVC Sheet",
                    imgSrc: "PVC Film_tape sheet_deskstop.jpg",
                    text: "Excellent electrical insulation and resistance to chemicals. \
                            Available in various colors for identification and purposes.",
                    href: "Tape_PVC_Sheet"
                },
                {
                    Product_Name: "Semi-rigid film",
                    imgSrc: "PVC Film_semi_deskstop.jpg",
                    text: "Professional customized product service which apply in interior finishes and various advertisement.",
                    href: "Semi_Rigid_Film"
                },
                {
                    Product_Name: "Clear Film",
                    imgSrc: "PVC Film_clear film_deskstop.jpg",
                    text: "Covering every range in life which applicable to everything in your daily life for both outdoor and indoor use.",
                    href: "Clear_Film"
                },
            ],
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        change_Processing_Swiper(inputIndex) {
            return this.Processing_Swiper_Data.forEach((el, index) => {
                inputIndex === index ? el.isDisplay = true : el.isDisplay = false
            })
        }
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

main#PVC_Film_Page
    h2.PU_PVC_section_Title_h2
        +text_Setting(38px, 1.33, 2.1px, #223b51, 600, "Noto Sans", center)
    h2.pageBottomText
        +text_Setting(36px, 1.5, 0.42px, #fff, 400, "Noto Sans", center)
    h3.PU_PVC_section_Title_h3
        +text_Setting(28px, 1.33, 0.1px, #223b51, 600, "Noto Sans", center)
    p.introduce_section_Text
        +text_Setting(20px, 1.33, 0.1px, #074150, 400, "Noto Sans", center)
    .button_more > a
        @include _under_576_width
            letter-spacing: 2px
    img, image
        +size(100%)

    @include _under_576_width
        h2.PU_PVC_section_Title_h2
            font-size: 30px
            letter-spacing: 0.1px
        h3.PU_PVC_section_Title_h3
            font-size: 22px
        p.introduce_section_Text
            font-size: 16px

    *
        // border: 1px solid #000

    section.PU_PVC_Introduce_section
        padding-top: 107px
        width: $max_Width_Percent
        max-width: $max_Container_Width
        margin: 0 auto
        @include _under_576_width
            padding-top: 60px

        article.PU_PVC_article
            width: 100%
            border: 2px solid #28eada
            padding: #{ percentage(50px/$max_Container_Width) } #{ percentage(80px/$max_Container_Width) }

        section.Introduce_card_section
            width: 100%
            padding: 100px 0
            section.Introduce_cardGroup
                section.PU_PVC_card
                    +flex_Center_Setting(space-between)
                    padding: 70px 0
                    &:first-child ~ .PU_PVC_card
                        border-top: 1px solid #707070
                    figure, article.PU_PVC_card_TextBlock
                        +size(48.5%, auto)
                    article.PU_PVC_card_TextBlock
                        +flex_Center_Setting(flex-end, flex-start)
                        align-self: stretch
                        flex-direction: column
                        h3, p
                            width: 100%
                            text-align: left
                            margin-bottom: 20px
                        .button_more
                            width: 100%
                            max-width: 240px
                            align-self: flex-end
                            & > a
                                height: 60px
                                background-image: linear-gradient(105deg, #d4de17 -13%, #d4de17 -1%, #2edbef 112%)
                                border-radius: 5px
            @include _under_768_width
                section.Introduce_cardGroup
                    section.PU_PVC_card
                        flex-direction: column
                        padding: 50px 0
                        max-width: 450px
                        margin: 0 auto
                        figure, article.PU_PVC_card_TextBlock
                            width: 100%
                        article.PU_PVC_card_TextBlock
                            align-self: auto
                            margin-top: 20px
                            .button_more
                                align-self: left
                                margin-top: 30px
            @include _under_576_width
                padding: 60px 0
                section.Introduce_cardGroup
                    section.PU_PVC_card article.PU_PVC_card_TextBlock
                        .button_more
                            max-width: 180px
                            & > a
                                min-width: auto
                                padding: 0
                                height: 55px
                                .arrowRight
                                    display: none
    
    section.pageBottom_section
        background-image: url("~@/assets/Products/PVC/pvc film_page bottom_desktop.jpg")
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        position: relative
        z-index: 1
        &::before
            +pseudoBlock(100%, 100%, rgba(#000, 0.2))
            left: 0
            top: 0
            z-index: -1
        article.pageBottom_article
            +flex_Center_Setting
            flex-direction: column
            width: $max_Width_Percent
            max-width: $max_Container_Width
            margin: 0 auto
            padding: 120px 0
            h2
                width: 100%
            .button_more
                margin-top: 40px

        @include _under_576_width()
            background-image: url("~@/assets/Products/PVC/pvc film_page bottom_mobile.jpg")
            // background-attachment: fixed
            article.pageBottom_article
                h2
                    font-size: 24px
</style>