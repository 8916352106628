<template lang="pug">
#app(:class="{appScrollsDown: scrollsDown_to_hide}")
    header
        navbar(:navbar_hide_Control="scrollsDown_to_hide")
    transition(name="fade" mode='out-in')
        keep-alive
            router-view
    footer_section
    button.backButton(:class="{backBtn_ScrollsDown: scrollsDown_to_hide}" v-scroll-to="{el:'#navbar'}")
        b-icon(icon="chevron-up")
</template>

<script>
// import Global_Function from "@/Global_Function.js"
// import {debounce} from "@/Global_Function.js"
import navbar from "@/section_components/navbar.vue"
import footer_section from "@/section_components/footer.vue"

export default {
    metaInfo() {
        return{
            htmlAttrs: {
                lang: 'en'
            },
            // title: '',
            titleTemplate: `%sFormosa Industries Corporation (FPG Vietnam) | PVC film & PU leather`,

            // meta: [
            //     {
            //         vmid: 'description',
            //         name: 'description',
            //         content: "Provide PVC and PU plastics for a wide range of applications, \
            //             and provide customized and forward-looking products depending on the vision, tactile, physical properties, and function requirements."
            //     },
            // ]
        }
    },

    name: "App",
    // mixins: [
    //     Global_Function,
    // ],
    components: {
        navbar,
        footer_section
    },

    data() {
        return{
            scrollsDown_to_hide: false
        }
    },

    // methods: {},

    mounted() {

        // 網頁向下捲動隱藏控制
        let prev_ScrollPosition = window.pageYOffset
        window.onscroll = () => {
            let current_ScrollPosition = window.pageYOffset
            // 兩條件判斷：
            // 原始 < 當下的Y位置，則隱藏 (三元運算式Expressions - Conditional operator) or 
            // 捲動到頁面底部，維持隱藏navbar
            prev_ScrollPosition < current_ScrollPosition || (window.innerHeight + window.scrollY) >= document.body.offsetHeight ? 
                this.scrollsDown_to_hide = true : 
                this.scrollsDown_to_hide = false

            prev_ScrollPosition = current_ScrollPosition
        }
    },

    created() {
        let timer = null
        window.onresize = () => {
            if(timer != null){
                clearTimeout(timer)
            }
            timer = setTimeout(()=>{
                this.$store.commit('windows_resize_intoVuex')
            },15)
        }
    }
};
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import "@/css/resetCSS.sass"

+resetCSS()

body::-webkit-scrollbar
    width: 12px
    background-color: #F5F5F5
body::-webkit-scrollbar-thumb
    border-radius: 20px
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0, 0.6)
    background-color: #aaa

// .fade-enter, .fade-leave-to
//     opacity: 0
// .fade-enter-to, .fade-leave
//     opacity: 1
.fade-enter-active
    animation: fadeIn 0.3s
.fade-leave-active
    animation: fadeOut 0.3s
    // animation: fadeIn 0.3s reverse

#app
    // *
        // border: 1px solid #000
    overflow: hidden
    padding-top: 85px
    transition: padding-top 0.5s
    .backButton
        +size(60px)
        border: 2px solid white
        background-color: #212529
        border-radius: 50%
        padding: 15px
        position: fixed
        bottom: 30px
        right: 30px
        z-index: 100
        transition: 0.3s
        svg.b-icon
            +size(100%)
            color: white
        &:hover
            transform: scale(1.1)
    @include _under_992_width
        padding-top: 70px
        .backButton
            +size(50px)
            padding: 10px
            transform: translateX(0)
    @include _under_576_width()
        .backButton
            bottom: 20px
            right: 20px
    .backButton.backBtn_ScrollsDown
        bottom: -100px
#app.appScrollsDown
    @include _under_992_width
        padding-top: 0
</style>
