// vn.js
export const locale = {
    __MAIN_TITLE: {
        Home: 'trang đầu',
        Company: 'Hồ sơ công ty',
        Products: 'Mô tả Sản phẩm',
        Quality: 'Chứng nhận chất lượng',
        Contact: 'liên hệ chúng tôi',
        PVC_Film: 'PVC Film越南文',
        Tape_PVC_Sheet: 'Tape PVC Sheet越南文',
        Semi_Rigid_Film: 'Semi Rigid Film越南文',
        Clear_Film: 'Clear Film越南文',
        PU_Leather: 'PU Leather越南文',
        PU: 'PU越南文',
        No_Sew: 'No Sew越南文'
    },
}