<template lang="pug">
main#Company
    pageTop(pageTitle="Company" imgSrc="company/company page top img_desktop.jpg" imgSrc_Mobile="company/company page top img_mobile.jpg")
    section.CompanyPage
        article.introduce
            p The business philosophy of Formosa Plastics is basically to pursue the rationalization of everything with a "hardworking and simple" attitude. Emphasize that doing everything must be traced to the roots and the truth, and know how to properly handle it.After understanding the truth of things, we must work hard and strive for excellence, so that we can achieve the greatest operational benefits by ending at the best.
        
        section.aboutContainer
            article.contentBlock
                h1 About Formosa Plastics
                p Vietnam Nanya plastics plant belongs to the Formosa Industries Corporation, Formosa Plastics Group. It is located in Nhon Trach III Industrial Zone, Hiep Phuoc Town, Nhon Trach District, Dong Nai Province, Vietnam. It was established in 2016 and started production in August 2018. 
            .swiperBlock
                swiper.aboutSwiper(:options='aboutSwiper')
                    swiper-slide.aboutSwiperSlide
                        img(:src="getImgUrl(`company/company_introduce1.jpg`)")
                    swiper-slide.aboutSwiperSlide
                        img(:src="getImgUrl(`company/company_introduce2.jpg`)")
                    .aboutSwiper-pagination(slot="pagination")
                    .swiper-button-next.aboutSwiper-next(slot="button-next")
                        b-icon(icon="chevron-right")
                    .swiper-button-prev.aboutSwiper-prev(slot="button-prev")
                        b-icon(icon="chevron-left")

        section.historyContainer
            article.contentBlock
                h1 History of Formosa Plastics Factory
                p Nan Ya Plastics Corp. was established in 1958 and is a member of the Formosa Plastics Group. After years of development, Nanya has established four PU leather factories in Asia. They are located in Taiwan, Jiangsu Province, Guangzhou Province, and Vietnam.
            .bottom
                .ADtime
                    button.yearButton(v-for="item of timelineData" :key="item.id"
                                        :class="{yearActive: item.isActive}"
                                        @click="year_onClick(item.id)"
                                        v-scroll-to="{el:`.timeline-article${item.id}`, offset: -200}") {{item.year}}
                .historyBlock
                    .timeLine
                        .timeLine-content
                            .timeline-article(v-for="item of timelineData" :key="item.id" :class="[`timeline-article${item.id}`]")
                                .dateCircle(:class="{yearActive: item.isActive}")
                                    h2 {{item.year}}
                                .left-Container(v-if="item.leftContainer_Active")
                                    img(:src="getImgUrl(item.imgSrc)")
                                    p.historyText {{item.text}}
                                .right-Container(v-if="item.rightContainer_Active")
                                    img(:src="getImgUrl(item.imgSrc)")
                                    p.historyText {{item.text}}

    section.pageBottomContainer
        //- img(src="@/assets/company/company_page bottom_desktop.jpg")
        article.contentBlock
            h1 Social Responsibility
            p we have been adhering to the spirit of equal emphasis on environmental protection and industrial development, and we adhere to the goal of “zero accidents and ensuring sustainable operations”.
            Button_Style.button_more(btnTitle="More" linkType="routerName" link="Quality" arrow="right")



</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `Company-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Vietnam Nanya plastics plant belongs to the Formosa Industries Corporation, Formosa Plastics Group. It is located in Nhon Trach III Industrial Zone, Hiep Phuoc Town, Nhon Trach District, Dong Nai Province, Vietnam. It was established in 2016 and started production in August 2018."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Vietnam Nanya plastics plant belongs to the Formosa Industries Corporation, Formosa Plastics Group. It is located in Nhon Trach III Industrial Zone, Hiep Phuoc Town, Nhon Trach District, Dong Nai Province, Vietnam. It was established in 2016 and started production in August 2018." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Vietnam Nanya plastics plant belongs to the Formosa Industries Corporation, Formosa Plastics Group. It is located in Nhon Trach III Industrial Zone, Hiep Phuoc Town, Nhon Trach District, Dong Nai Province, Vietnam. It was established in 2016 and started production in August 2018." },
            ]
        }
    },

    components: {
        pageTop,
        Button_Style
    },

    data() {
        return{
            aboutSwiper: {
                spaceBetween: 0,
                effect: 'slide',
                pagination: {
                    el: '.aboutSwiper-pagination',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    nextEl: '.aboutSwiper-next',
                    prevEl: '.aboutSwiper-prev'
                },
            },

            timelineData: [
                {
                    id: 1,
                    isActive: true,
                    year: "2016",
                    leftContainer_Active: true,
                    rightContainer_Active: false,
                    imgSrc: "company/company_history2016.jpg",
                    text: "In 2016, invested in the construction of Vietnam Dong Nai Plant."
                },
                {
                    id: 2,
                    isActive: false,
                    year: "2001",
                    leftContainer_Active: false,
                    rightContainer_Active: true,
                    imgSrc: "company/company_history2001.jpg",
                    text: "In 2001, invested in the construction of Guangdong Huizhou Plant."
                },
                {
                    id: 3,
                    isActive: false,
                    year: "1996",
                    leftContainer_Active: true,
                    rightContainer_Active: false,
                    imgSrc: "company/company_history1996.jpg",
                    text: "In 1996, invested in the construction of Jiangsu Nantong Plant."
                },
                {
                    id: 4,
                    isActive: false,
                    year: "1975",
                    leftContainer_Active: false,
                    rightContainer_Active: true,
                    imgSrc: "company/company_history1975.jpg",
                    text: "In 1975, invested in the construction of Taiwan Shulin Plant."
                },
                {
                    id: 5,
                    isActive: false,
                    year: "1958",
                    leftContainer_Active: true,
                    rightContainer_Active: false,
                    imgSrc: "company/company_history1958.jpg",
                    text: "In 1958, Nan Ya Plastics Corporation was established in Taiwan."
                },
            ]
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        year_onClick(getId) {
            this.timelineData.map( (value, index) => {
                getId === index + 1 ? value.isActive = true : value.isActive = false
            })
        }
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#Company
    button.yearButton
        +text_Setting(28px, 1.33, 4.2px, #c4cdd5, 600, "Noto Sans", center)
        @include _under_768_width()
            font-size: 22px
        @include _under_576_width()
            font-size: 18px
            letter-spacing: 1px
    p.historyText
        +text_Setting(22px, 1.4, 0.28px, #17333a, 400, "Noto Sans", left)
        @include _under_768_width()
            font-size: 18px
        @include _under_576_width()
            font-size: 16px
    
    *
        // border: 1px solid #000
    .CompanyPage
        width: $max_Width_Percent
        max-width: $max_Container_Width
        margin: 0 auto
        padding: 72px 0
        .introduce
            +size(100%, auto)
            border: 2px solid #2ec1ef
            // padding: 50px 80px
            // padding 上下左右設定50px 80px，換算成百分比
            padding: #{ percentage(50px/$max_Container_Width) } #{ percentage(80px/$max_Container_Width) }
            p
                +text_Setting(calc(12px + 0.4vw), normal, 0.24px, #074150, 400, "Noto Sans", center)
        
        .aboutContainer
            +size(100%, auto)
            +flex_Center_Setting
            margin-top: 80px
            .contentBlock, .swiperBlock
                +size(50%, auto)
            .contentBlock
                margin-right: 76px
                h1
                    +text_Setting(calc(16px + 1.2vw), 1.33, 1.1px, $title2_color, 400, "Noto Sans", left)
                p
                    +text_Setting(calc(12px + 0.4vw), 1.46, 0.24px, #074150, 400, "Noto Sans", left)
                    margin-top: 28px
            .swiperBlock > .aboutSwiper
                .aboutSwiperSlide
                    img
                        +size(100%)
                .aboutSwiper-pagination
                    +flex_Center_Setting
                    padding-top: 20px
                    span
                        +size(15px)
                        background-color: #2edbef
                .aboutSwiper-next, .aboutSwiper-prev
                    +size(50px, calc(100% - 24px - 18px))
                    top: 0
                    margin-top: 0
                    &::after
                        content: none
                    .b-icon
                        +size(100%)
                        color: #fff
                        stroke-width: 150px
                .aboutSwiper-next
                    right: 0
                .aboutSwiper-prev
                    left: 0
            
            @include _under_992_width()
                flex-direction: column
                .contentBlock, .swiperBlock
                    width: 100%
                .contentBlock
                    margin-right: 0
                .swiperBlock
                    margin-top: 50px
            @include _under_576_width()
                margin-top: 45px
                .contentBlock
                    p
                        margin-top: 10px
                .swiperBlock
                    margin-top: 25px


        .historyContainer
            +size(100%, auto)
            margin-top: 150px
            @include _under_576_width()
                margin-top: 50px
            .contentBlock
                h1
                    +text_Setting(calc(16px + 1.2vw), 1.33, 1.1px, $title2_color, 600, "Noto Sans", center)
                p
                    +text_Setting(calc(14px + 0.4vw), 1.33, 0.24px, #074150, 400, "Noto Sans", center)
                    margin-top: 32px
            .bottom
                .ADtime
                    +flex_Center_Setting(space-between)
                    width: 100%
                    max-width: 800px
                    margin: 80px auto 0
                    .yearButton
                        padding-bottom: 10px
                    .yearButton.yearActive
                        position: relative
                        color: $title2_color
                        &::before
                            +pseudoBlock(100%, 6px, $title2_color)
                            bottom: 0
                @include _under_576_width()
                    .ADtime
                        margin-top: 45px

                .historyBlock
                    +size(100%, auto)
                    +flex_Center_Setting
                    margin-top: 80px
                    .timeLine
                        +size(100%, auto)
                        position: relative
                        padding: 120px 0 100px 0
                        &::before
                            +pseudoBlock(8px, 100%, #707070)
                            left: 50%
                            top: 0
                            transform: translateX(-50%)
                            z-index: -1
                        .timeLine-content
                            +size(100%, auto)
                            .timeline-article
                                // +flex_Center_Setting(space-between)
                                +size(100%, auto)
                                position: relative
                                margin-top: 80px
                                overflow: hidden    // 解決float元素時父容器無法被撐開問題
                                &:first-child
                                    margin-top: 0
                                .dateCircle
                                    +size(calc(80px + 3vw))
                                    +flex_Center_Setting
                                    max-width: 130px
                                    max-height: 130px
                                    border: 1px solid #707070
                                    border-radius: 50%
                                    background-color: #fff
                                    position: absolute
                                    left: 50%
                                    top: 50%
                                    transform: translate(-50%, calc(-50% - 34px - 30px))
                                    // only for IE 時
                                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
                                        top: 5%
                                        transform: translateX(-50%)
                                    h2
                                        +text_Setting(calc(14px + 1.1vw), 1.33, 2.1px, $title2_color, 400, "Noto Sans", center)
                                        @include _Upper_1920_width()
                                            font-size: 36px
                                .dateCircle.yearActive
                                    background-color: $title2_color
                                    h2
                                        color: #fff

                                .left-Container, .right-Container
                                    +size(100%, auto)
                                    display: inline-block
                                    max-width: calc(50% - 90px - 3vw)
                                    img
                                        +size(100%, auto)
                                    p
                                        margin-top: 20px
                                .right-Container
                                    float: right
                    @include _under_992_width()
                        .timeLine
                            padding: 0
                            &::before
                                left: calc((80px + 3vw) / 2)
                            .timeLine-content
                                .timeline-article
                                    padding-left: calc((80px + 3vw) + 20px)
                                    .dateCircle
                                        left: 0
                                        top: 0
                                        transform: none
                                    .left-Container
                                        float: right
                                    .left-Container, .right-Container
                                        max-width: 100%
                    @include _under_576_width()
                        margin-top: 45px
                        .timeLine
                            &::before
                                left: calc((60px + 3vw) / 2)
                            .timeLine-content 
                                .timeline-article
                                    padding-left: calc((60px + 3vw) + 10px)
                                    .dateCircle
                                        +size(calc(60px + 3vw))


    .pageBottomContainer
        +size(100%, auto)
        background: url("~@/assets/company/company_page bottom_desktop.jpg") no-repeat fixed center center / auto 100%
        // background: url("~@/assets/company/company_page bottom_desktop.jpg") center no-repeat cover
        // background-attachment: fixed
        // *
        //     border: 1px solid #000
        .contentBlock
            +flex_Center_Setting
            width: 100%
            padding: 80px #{ (100% - $max_Width_Percent) / 2}
            flex-direction: column
            h1
                +text_Setting(calc(16px + 1.2vw), 1.33, 2.25px, #fafafa, 600, "Noto Sans", center)
                width: 100%
            p
                +text_Setting(calc(12px + 0.4vw), 1.62, 1.05px, #fafafa, 400, "Noto Sans", center)
                width: 80%
                margin-top: 30px
            .button_more
                margin-top: 50px
</style>
