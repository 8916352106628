<template lang="pug">
section#pageTop
    img.pageDesktopImg(:src="getImgUrl(imgSrc)", :alt="pageTitle")
    img.pageMobileImg(:src="getImgUrl(imgSrc_Mobile)")
    b-breadcrumb.breadcrumb(:items='breadcrumb_link')
    h1 {{pageTitle}}
</template>

<script>
export default {
    props: {
        pageTitle: String,
        imgSrc: String,
        imgSrc_Mobile: String
    },

    // data() {
    //     return{}
    // },

    computed: {
        breadcrumb_link() {
            return [
                {
                    text: this.$t('__MAIN_TITLE.Home'),
                    to: { name: `Home${this.$store.getters.get_routerName_lang_from_Store}` },
                },
                ...this.get_Breadcrumb_Router()
            ]
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
        
        get_Breadcrumb_Router() {
            const store_currentLanguage = this.$store.state.currentLanguage
            const store_defaultLanguage = this.$store.state.defaultLanguage
            const route_breadcrumbArray = this.$route.meta.breadcrumb

            // 當前語系 = 預設主語系 --> 直接return route.js中的meta.breadcrumb資料
            if(store_currentLanguage === store_defaultLanguage){
                return route_breadcrumbArray
            }
            return this.get_Breadcrumb_Router_othersLanguages(route_breadcrumbArray)
        },

        // 其他語系中的route name帶有_lang結尾，取_lang前的字串作為index，抓i18n語系中的標題字
        // return出新的breadcrumb陣列
        get_Breadcrumb_Router_othersLanguages(inputArray) {
            return inputArray.map(element1 => {
                const routeNameIndex = element1.to.name.split('_lang').filter(element2 => element2).join('')  // Company_lang --> Company
                return {text: this.$t(`__MAIN_TITLE.${routeNameIndex}`), to: element1.to }
            })
        }
    },
    // created() {},
    // watch: {}
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#pageTop
    *
        // border: 1px solid #000
    position: relative
    img
        +size(100%, auto)
        min-width: 676px
        position: relative
    img.pageDesktopImg
        +size(100%, auto)
        display: block
    img.pageMobileImg
        +size(100%, auto)
        display: none
    .breadcrumb
        position: absolute
        top: calc(8px + 0.8vw)
        left: 5%
        background-color: transparent
        .breadcrumb-item
            +text_Setting(calc(14px + 0.5vw), normal, 2.2px, #fff, 300, "Noto Sans", center)
            padding: 0
            & > .router-link-active
                color: #fff
            &::before
                color: #fff
                padding: 0 10px
        // @include _under_768_width()
        //     .breadcrumb-item::before
        //         padding: 0 3px
    h1
        +text_Setting(calc(18px + 3.2vw), normal, 7px, #fff, 500, "Noto Sans",center)
        +size(100%, auto)
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        text-shadow: 3px 5px 8px rgba(0, 0, 0, 0.2)
        @include _Upper_1920_width()
            font-size: 72px
    
    @include _under_768_width()
        img.pageDesktopImg
            display: none
        img.pageMobileImg
            display: block
            // left: -100px
            // width: calc(768px + 100px)
        .breadcrumb
            .breadcrumb-item::before
                padding: 0 3px
    @include _under_576_width()
        h1
            letter-spacing: 2px
        // img.pageMobileImg
            // width: calc(576px + 100px)
        // .breadcrumb > .breadcrumb-item
            // font-size: font(4)
    @include _under_375_width()
        .breadcrumb > .breadcrumb-item
            font-size: font(3)
            &::before
                padding: 0 5px
</style>