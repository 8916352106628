import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import { locale as en } from '@/languages/i18n/en.js'
import { locale as tw } from '@/languages/i18n/tw.js'
import { locale as vn } from '@/languages/i18n/vn.js'
Vue.use(VueI18n)
let messages = {}
messages = { ...messages, en, tw, vn}

// 判斷瀏覽器語言，作為預設語言，如果全部都不符合，預設英文。跑過第一次後會寫入localStorage
// const browser_DefaultLanguage = () => {
//     const browserLanguageCode = window.navigator.language.toLowerCase()
//     switch(browserLanguageCode){
//         case "zh-tw":
//             localStorage.setItem('language', 'tw')
//             store.commit('changeLanguage_mutationsVuex', 'tw')
//             console.log('default: ' + 'tw');
//             return "tw"
//         case "vi":
//             localStorage.setItem('language', 'vn')
//             store.commit('changeLanguage_mutationsVuex', 'vn')
//             console.log('default: ' + 'vn');
//             return "vn"
//         default:
//             localStorage.setItem('language', 'en')
//             store.commit('changeLanguage_mutationsVuex', 'en')
//             console.log('default: ' + 'en');
//             return "en"
//     }
// }

// 若有訪問過一次後，再度訪問會根據localStorage紀錄瀏覽器上次切換語系打開相對應網站語系，否則就根據瀏覽器預設語言
const localLanguage = () => {
    // const local_LanguageCode = localStorage.getItem('language')
    // const defaultLanguage = store.state.defaultLanguage
    // const languageList = store.state.languageList

    // if(languageList.includes(local_LanguageCode)){
    //     // localStorage.setItem('language', local_LanguageCode)
    //     store.commit('changeLanguage_mutationsVuex', local_LanguageCode)
    //     return local_LanguageCode
    // }
    // else{
    //     // localStorage.setItem('language', defaultLanguage)
    //     store.commit('changeLanguage_mutationsVuex', defaultLanguage)
    //     return defaultLanguage
    // }
    const defaultLanguage = store.state.defaultLanguage
    store.commit('changeLanguage_mutationsVuex', defaultLanguage)
    return defaultLanguage
}

// 語系執行localLanguage()
const i18n = new VueI18n({
    locale: localLanguage(),
    messages,
    silentTranslationWarn: false   //清除有關vue-i18n的警告
})
export default i18n