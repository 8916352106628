// tw.js
export const locale = {
    __MAIN_TITLE: {
        Home: '首頁',
        Company: '公司介紹',
        Products: '產品介紹',
        Quality: '品質認證',
        Contact: '聯絡我們',
        PVC_Film: 'PVC膠布',
        Tape_PVC_Sheet: 'Tape PVC Sheet中文',
        Semi_Rigid_Film: 'Semi Rigid Film中文',
        Clear_Film: 'Clear Film中文',
        PU_Leather: 'PU Leather中文',
        PU: 'PU中文',
        No_Sew: 'No Sew中文'
    },
    __PRODUCTS: {
        PVC_Film_Page: {
            
        }
    }
}