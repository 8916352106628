<template lang="pug">
section#banner_Carousel
    swiper.bannerSwiper(:options='banner_Swiper')
        //- banner_1
        swiper-slide.bannerSlide
            figure.bannerImg
                img.desktop_banner(src="@/assets/banner/banner_desktop1.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
                img.mobile_banner(src="@/assets/banner/banner_mobile1.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
            article.item_Group
                hgroup.title_Block
                    h1.mainTitle {{$t('__HOME.BannerData.banner_1.mainTitle1')}}
                    h1.mainTitle {{$t('__HOME.BannerData.banner_1.mainTitle2')}}
                    h2.subtitle {{$t('__HOME.BannerData.banner_1.subTitle')}}
                .button_Blcok
                    Button_Style.button_PVC(:btnTitle="$t('__MAIN_TITLE.PVC_Film')" linkType="routerName" link="PVC_Film" arrow="right")
                    Button_Style.button_PU(:btnTitle="$t('__MAIN_TITLE.PU_Leather')" linkType="routerName" link="PU_Leather" arrow="right")
        
        //- PVC
        swiper-slide.bannerSlide.PVC_Banner(data-swiper-autoplay="13500")
            figure.bannerImg
                img.desktop_banner(src="@/assets/banner/banner_desktop2.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
                img.mobile_banner(src="@/assets/banner/banner_mobile2.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
            article.item_Group
                hgroup.title_Block
                    h1.mainTitle {{$t('__HOME.BannerData.banner_2.mainTitle')}}
                    h2.subtitle {{$t('__HOME.BannerData.banner_2.subTitle')}}
                .button_Blcok
                    Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.more')" linkType="routerName" link="PVC_Film" arrow="right")
            //- 小圖CSS輪播
            section.Img_Carousel_Container
                figure.imgCard(v-for="icon of PVC_Img_Carousel" :class="{Img_Animation: Img_Animation_isActive}")
                    figcaption {{icon.text}}
                    img(:src="getImgUrl(icon.img)")
            .bottomColor_PVC
        
        //- PU
        swiper-slide.bannerSlide.PU_Banner(data-swiper-autoplay="13500")
            figure.bannerImg
                img.desktop_banner(src="@/assets/banner/banner_desktop3.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
                img.mobile_banner(src="@/assets/banner/banner_mobile3.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
            article.item_Group
                hgroup.title_Block
                    h1.mainTitle {{$t('__HOME.BannerData.banner_3.mainTitle')}}
                    h2.subtitle {{$t('__HOME.BannerData.banner_3.subTitle')}}
                .button_Blcok
                    Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.more')" linkType="routerName" link="PU_Leather" arrow="right")
            //- 小圖CSS輪播
            section.Img_Carousel_Container
                figure.imgCard(v-for="icon of PU_Img_Carousel" :class="{Img_Animation: Img_Animation_isActive}")
                    figcaption {{icon.text}}
                    img(:src="getImgUrl(icon.img)")
            .bottomColor_PU
        
        swiper-slide.bannerSlide 
            figure.bannerImg
                img.desktop_banner(src="@/assets/banner/banner_desktop4.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
                img.mobile_banner(src="@/assets/banner/banner_mobile4.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
            article.item_Group
                hgroup.title_Block
                    h1.mainTitle {{$t('__HOME.BannerData.banner_4.mainTitle')}}
                    h2.subtitle {{$t('__HOME.BannerData.banner_4.subTitle')}}
                .button_Blcok
                    Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.more')" linkType="routerName" link="Contact" arrow="right")
        
        swiper-slide.bannerSlide 
            figure.bannerImg
                img.desktop_banner(src="@/assets/banner/banner_desktop5.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
                img.mobile_banner(src="@/assets/banner/banner_mobile5.jpg" :class="{BannerZoomin_Animation: BannerZoomin_Animation_isActive}")
            article.item_Group
                hgroup.title_Block
                    h1.mainTitle {{$t('__HOME.BannerData.banner_5.mainTitle')}}
                    h2.subtitle {{$t('__HOME.BannerData.banner_5.subTitle')}}
                .button_Blcok
                    Button_Style.button_more(:btnTitle="$t('__BUTTON_TEXT.more')" linkType="routerName" link="Quality" arrow="right")
        .swiper_Pagination_Block
            .banner_Carousel-pagination(slot="pagination")
</template>

<script>
// import $ from "jquery"
import Button_Style from "@/components/Button_Style.vue"

export default {

    components: {
        Button_Style
    },

    data() {
        return{
            banner_Swiper: {
                spaceBetween: 20,
                // effect: 'slide',
                effect: 'fade',
                // fadeEffect: { crossFade: true }, // 解決淡入淡出重疊問題
                // paginationClickable: false,
                pagination: {
                    el: '.banner_Carousel-pagination',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                // pagination: '.swiper-pagination',
                autoplay: {delay: 5000},   // 輪播
                speed: 1200,   // 切換速度
                loop: false,
                // slidesPerView: 1,
                // centeredSlides: true,
                // mousewheel: false,
                // grabCursor: true,  //移入後手掌形狀
                // lazyLoading: true,
                // slideShadows : true,
                // slideToClickedSlide: false
                on: {
                    slideChange: (() => {
                        this.img_Carousel_Control_methods()
                        this.BannerZoomin_Control_methods()
                    })
                }
            },

            BannerZoomin_Animation_isActive: false,
            
            Img_Animation_isActive: false,
            Img_Animation_Timer1: 0,
            Img_Animation_Timer2: 0,

            // PVC_Img_Carousel: [
            //     {text: "Insulation Tape", img: "banner/PVC_Img1.jpg"},
            //     {text: "Partitions", img: "banner/PVC_Img2.jpg"},
            //     {text: "Stationery", img: "banner/PVC_Img3.jpg"},
            //     {text: "Interior", img: "banner/PVC_Img4.jpg"},
            // ],

            // PU_Img_Carousel: [
            //     {text: "Saddle leather", img: "banner/PU_Img1.jpg"},
            //     {text: "Gloves leather", img: "banner/PU_Img2.jpg"},
            //     {text: "Bags leather", img: "banner/PU_Img3.jpg"},
            //     {text: "Vehicle leather", img: "banner/PU_Img4.jpg"},
            // ],
        }
    },

    computed: {
        PVC_Img_Carousel() {
            return [
                {text: this.$t('__HOME.BannerData.PVC_Img_Carousel[0]'), img: "banner/PVC_Img1.jpg"},
                {text: this.$t('__HOME.BannerData.PVC_Img_Carousel[1]'), img: "banner/PVC_Img2.jpg"},
                {text: this.$t('__HOME.BannerData.PVC_Img_Carousel[2]'), img: "banner/PVC_Img3.jpg"},
                {text: this.$t('__HOME.BannerData.PVC_Img_Carousel[3]'), img: "banner/PVC_Img4.jpg"},
            ]
        },
        PU_Img_Carousel() {
            return [
                {text: this.$t('__HOME.BannerData.PU_Img_Carousel[0]'), img: "banner/PU_Img1.jpg"},
                {text: this.$t('__HOME.BannerData.PU_Img_Carousel[1]'), img: "banner/PU_Img2.jpg"},
                {text: this.$t('__HOME.BannerData.PU_Img_Carousel[2]'), img: "banner/PU_Img3.jpg"},
                {text: this.$t('__HOME.BannerData.PU_Img_Carousel[3]'), img: "banner/PU_Img4.jpg"},
            ]
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        img_Carousel_Control_methods() {
            clearTimeout(this.Img_Animation_Timer1)
            clearTimeout(this.Img_Animation_Timer2)
            
            this.Img_Animation_isActive = false
            this.Img_Animation_Timer1 = setTimeout(()=>{
                this.Img_Animation_isActive = true
                // console.log('Timer1')
            },10)

            this.Img_Animation_Timer2 = setTimeout(()=>{
                this.Img_Animation_isActive = false
                // console.log('Timer2')
            },13500)
        },

        BannerZoomin_Control_methods() {
            this.BannerZoomin_Animation_isActive = false
            setTimeout(()=>{
                this.BannerZoomin_Animation_isActive = true
            },100)
        },

        // on_SlideChange() {
        //     this.img_Carousel_Control_methods()
        //     this.BannerZoomin_Control_methods()
        // },
    },

    // mounted() {},

    created() {
        this.Img_Animation_isActive = true
        this.BannerZoomin_Animation_isActive = true
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

section#banner_Carousel
    +size(100%, auto)
    max-height: calc(100vh - 85px)
    // max-width: 1920px
    margin: 0 auto
    position: relative

    h1.mainTitle
        +text_Setting(64px, normal, 1px, #fff, 600, "Noto Sans", left)
        text-shadow: 0 3px 3px rgba(0, 0, 0, 0.3)
    h2.subtitle
        +text_Setting(26px, normal, 1px, #fff, 500, "Noto Sans", left)
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5)
        margin-top: 20px
    figcaption
        +text_Setting(22px, normal, 1.3px, #fafafa, 500, "Noto Sans", center)
        text-shadow: black 0.1em 0.1em 5px
    #Button_Style > a
        +text_Setting(22px, normal, 1.5px, #fafafa, 500, "Noto Sans", center)

    img
        +size(100%)

    @include _under_768_width()
        h1.mainTitle
            font-size: 36px
        h2.subtitle
            font-size: 24px
        figcaption
            font-size: 18px
    @include _under_576_width()
        h1.mainTitle
            font-size: 38px
            letter-spacing: normal
        h2.subtitle
            font-size: 18px
        #Button_Style > a
            font-size: 18px
    @include _under_375_width()
        h1.mainTitle
            font-size: 28px
        h2.subtitle
            font-size: 14px

    // *
    //     border: 1px solid #fff

    .bannerSwiper
        +size(100%)
        // max-height: calc(100vh - 85px)
        // max-width: 1920px
        // max-height: 1080px
        .bannerSlide
            +size(100%)
            max-height: calc(100vh - 85px)
            position: relative
            overflow: hidden
            .bannerImg
                +size(100%, auto)
                min-width: 1600px
                float: right
                .mobile_banner
                    display: none

                & > img.BannerZoomin_Animation
                    // transform: scale(1.2)
                    @keyframes BannerZoomin_Animate
                        0%
                            transform: scale(1)
                        100%
                            transform: scale(1.2)
                    animation: BannerZoomin_Animate 5s linear 0s 1
                    animation-fill-mode: forwards
            &.PVC_Banner, &.PU_Banner
                & > .bannerImg > img.BannerZoomin_Animation
                    animation: BannerZoomin_Animate 13s ease-in 0s 1
                    animation-fill-mode: forwards
            
            article.item_Group
                +size($max_Width_Percent, auto)
                max-width: $max_Container_Width
                position: absolute
                left: calc((100% - #{$max_Width_Percent}) / 2 + 5%)
                top: calc(50% - 50px)
                transform: translateY(-50%)

                .button_Blcok
                    +flex_Center_Setting(space-between)
                    width: 100%
                    max-width: 550px
                    margin-top: 70px
                    #Button_Style
                        width: 47.5%
                        max-width: 250px
                        a
                            padding: 0 25px
                    .button_PVC > .button_url
                        opacity: 0.8
                        background-image: linear-gradient(58deg, #63c6bc 11%, #83cd8e 49%,  #d4de17 90%)
                        &:hover
                            background-image: linear-gradient(-58deg, #63c6bc 27%, #83cd8e 62%,  #d4de17 86%)
                        // width: 47.5%
                        // max-width: 260px
                    .button_PU > .button_url
                        opacity: 0.8
                        background-image: linear-gradient(to right, #fe7e17 -3%, #e6b617 82%, #d4de17 108%)
                        &:hover
                            background-image: linear-gradient(to left, #fe7e17 -3%, #e6b617 82%, #d4de17 108%)
                    .button_more > .button_url
                        background-color: rgba(#0c151c, 0.5)

            .Img_Carousel_Container
                +size($max_Width_Percent, auto)
                +flex_Center_Setting(space-between)
                max-width: 700px
                position: absolute
                right: calc((100% - #{$max_Width_Percent}) / 2)
                bottom: 96px
                figure.imgCard
                    +size(23%, auto)
                    border-radius: 8px
                    overflow: hidden
                    position: relative
                    figcaption
                        width: 100%
                        padding: 0
                        position: absolute
                        top: 50%
                        transform: translateY(-50%)
                        opacity: 0
                        transition: 0.3s
                    &::before
                        +pseudoBlock(100%, 100%, rgba(black, 0.6))
                        position: absolute
                        left: 0
                        top: 0
                        transition-timing-function: linear
                        transition: 0.3s

                    &:hover
                        figcaption
                            opacity: 1
                        &::before
                            opacity: 0

                    // 小圖播圖動畫
                    @keyframes Img_Carousel_Animate
                        0%
                            opacity: 1
                        40%
                            opacity: 0
                        70%
                            opacity: 0
                        100%
                            opacity: 1

                    // 小圖播圖內字的動畫
                    @keyframes Img_Carousel_Animate_text
                        0%
                            opacity: 0
                        40%
                            opacity: 1
                        70%
                            opacity: 1
                        100%
                            opacity: 0
                    &.Img_Animation
                        @for $i from 1 through 4
                            &:nth-child(#{$i})
                                figcaption
                                    animation: Img_Carousel_Animate_text 4s #{0.5 + ($i - 1) * 3}s 1
                                &::before
                                    animation: Img_Carousel_Animate 4s #{0.5 + ($i - 1) * 3}s 1

            .bottomColor_PVC, .bottomColor_PU
                +size(100%, 50px)
                position: absolute
                left: 0
                bottom: 0
            .bottomColor_PVC
                background-image: linear-gradient(to right, #d4de17 -40%, #d4de17 -26%, #2edbef 111%)
            .bottomColor_PU
                background-image: linear-gradient(106deg, #fdc215 5%, #fdac15 24%, #fe9117 52%, #fe8017 77%, #ff7b18 96%)

    .swiper_Pagination_Block
        +size(100%, 50px)
        position: absolute
        left: 0
        bottom: 0
        z-index: 10
        .banner_Carousel-pagination
            +size($max_Width_Percent, 100%)
            +flex_Center_Setting(space-between)
            max-width: 500px
            margin: 0 auto
            // width: 100%
            // height: 50px
            .swiper-pagination-bullet
                +size(calc(100%/5 - 1%*4), 7px)
                // +size(20%, 7px)
                // max-width: 90px
                outline: none
                border-radius: 1px
                background: #fff
                opacity: 0.4
                // margin: 0 5px
                // &:first-child
                //     margin-left: 0
                // &:last-child
                //     margin-right: 0
            .swiper-pagination-bullet-active
                opacity: 1
    
    .bannerSwiper
        @include _under_992_width()
            .bannerSlide
                max-height: calc(100vh - 70px)
                &:nth-child(1), &:nth-child(4), &:nth-child(5)
                    article.item_Group
                        top: calc(50% - 50px)
                article.item_Group
                    left: calc((100% - #{$max_Width_Percent}) / 2)
        @include _under_768_width()
            .bannerSlide
                .bannerImg
                    min-width: auto
                    .desktop_banner
                        display: none
                    .mobile_banner
                        display: block
                article.item_Group
                    .button_Blcok
                        flex-direction: column
                        align-items: flex-start
                        max-width: none
                        #Button_Style
                            width: 100%
                        .button_PU
                            margin-top: 30px
        // @include _under_576_width()
        //     .bannerSlide
        //         article.item_Group
        //             top: calc(50% - 30px)
        //             .button_Blcok
        //                 margin-top: 30px
        //                 #Button_Style
        //                     & > .button_url
        //                         min-width: auto
        //         .Img_Carousel_Container
        //             display: none
        //         .bottomColor_PVC, .bottomColor_PU
        //             height: 30px
        //     .swiper_Pagination_Block
        //         height: 30px
        
        // iphone 6/7/8(max-height: 414px)-橫向螢幕時一併觸發
        @media screen and (max-width: 576px), (max-height: 414px)
            .bannerSlide
                article.item_Group
                    top: calc(50% - 30px)
                    .button_Blcok
                        margin-top: 30px
                        #Button_Style
                            max-width: 180px
                            & > .button_url
                                min-width: auto
                                height: 55px
                                padding: 0
                                .arrowRight
                                    display: none
                .Img_Carousel_Container
                    display: none
                .bottomColor_PVC, .bottomColor_PU
                    height: 30px
            .swiper_Pagination_Block
                height: 30px
</style>