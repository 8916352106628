<template lang="pug">
main#PU_Leather_Page
    pageTop(pageTitle="PU Leather" imgSrc="Products/PU/PU page top img_desktop.jpg" imgSrc_Mobile="Products/PU/PU page top img_mobile.jpg")
    section.PU_PVC_Introduce_section
        article.PU_PVC_article
            p.introduce_section_Text Vietnam Nan ya Synthetic Leather Plant has first-class production equipment, inspection equipment, and environmental protection facilities to meet customer needs with the most economical and fastest production methods. 
        section.Introduce_card_section
            h2.PU_PVC_section_Title_h2 PU Leather Products
            section.Introduce_cardGroup
                section.PU_PVC_card(v-for="item of Introduce_card_Data" :key="item.Product_Name")
                    figure
                        img(:src="getImgUrl(`Products/PU/${item.imgSrc}`)", :alt="item.Product_Name")
                    article.PU_PVC_card_TextBlock
                        h3.PU_PVC_section_Title_h3 {{item.Product_Name}}
                        p.introduce_section_Text {{item.text}}
                        Button_Style.button_more(btnTitle="view more" linkType="routerName" :link="item.href" arrow="right" @click.native="mutations_product_Display_index(0)")
        section.Applications_section
            article.Applications_article
                h2.PU_PVC_section_Title_h2 Applications
                p.introduce_section_Text The main applications of PU artificial leather and Nosew are for middle and high-end shoes, furniture, gloves, luggage, clothing, etc.
            section.itemsGroup
                .leftColumn
                    figure
                        img(:src="getImgUrl(`Products/PU/${Applications_ImgSrc[0].imgSrc}`)", :alt="Applications_ImgSrc[0].Product_Name")
                        figcaption {{Applications_ImgSrc[0].Product_Name}}
                .rightColumn
                    .rightTop_Block
                        figure(v-for="item of Applications_ImgSrc.slice(1, 3)")
                            img(:src="getImgUrl(`Products/PU/${item.imgSrc}`)", :alt="item.Product_Name")
                            figcaption {{item.Product_Name}}
                    .rightBottom_Block
                        figure(v-for="item of Applications_ImgSrc.slice(3)")
                            img(:src="getImgUrl(`Products/PU/${item.imgSrc}`)", :alt="item.Product_Name")
                            figcaption {{item.Product_Name}}
            section.Catalogue_section
                h2.PU_PVC_section_Title_h2 Catalogue
                .fileGroup
                    a.file(v-for="file of Catalogue_PDF_file" :key="file.fileName"
                                    :href="`./pdf/Catalogue/${file.fileSrc}`", :title="file.fileName" target="_blank")
                        .pdf_icon
                            img(:src="getImgUrl(`pdfIcon.png`)", alt="")
                        p {{file.fileName}}
                        b-icon(icon="download")

        section.Processing_section
            .Processing_container
                h2.PU_PVC_section_Title_h2 Processing
                section.Processing
                    .buttonGroup
                        button.Processing_button(v-for="(btn, index) of Processing_Swiper_Data"
                                                :class="{Processing_Show: btn.isDisplay}"
                                                @click="change_Processing_Swiper(index)") {{btn.title}}
                    swiper.Processing_Swiper(v-for="swiper of Processing_Swiper_Data" :key="swiper.title" 
                                            :options="Processing_Swiper"
                                            v-show="swiper.isDisplay")
                        swiper-slide(v-for="img of swiper.swiper_Img_Data" :key="img")
                            figure
                                img(:src="getImgUrl(`Products/PU/Processing/${img}`)", :alt="swiper.title")
                        .Processing_Swiper-pagination(slot="pagination")
                        .Processing_Swiper-button-prev(slot='button-prev')
                            b-icon.icon(icon="chevron-left")
                        .Processing_Swiper-button-next(slot='button-next')
                            b-icon.icon(icon="chevron-right")
                    p.introduce_section_Text(v-for="swiperText of Processing_Swiper_Data" 
                                            v-show="swiperText.isDisplay") {{swiperText.text}}

        article.Cooperation_article
            h2.PU_PVC_section_Title_h2 Cooperation
            p.introduce_section_Text Our Synthetic Leather Plant has been certified by nine brands. 
                | Including MIZUNO, ASICS, CONVERSE, SALOMON, etc., serving more than 60 customers.
            .figureGroup
                figure.wow(v-for="img of Cooperation_imgSrc")
                    img(:src="getImgUrl(`Products/PU/${img}`)", alt="Cooperation")
            Button_Style.button_more(btnTitle="Contact Us" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `PU Leather-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Vietnam Nan ya Synthetic Leather Plant has first-class production equipment, inspection equipment, and environmental protection facilities to meet customer needs with the most economical and fastest production methods."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Vietnam Nan ya Synthetic Leather Plant has first-class production equipment, inspection equipment, and environmental protection facilities to meet customer needs with the most economical and fastest production methods." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Vietnam Nan ya Synthetic Leather Plant has first-class production equipment, inspection equipment, and environmental protection facilities to meet customer needs with the most economical and fastest production methods." },
            ]
        }
    },

    components: {
        pageTop,
        Button_Style
    },

    data() {
        return{
            Introduce_card_Data: [
                {
                    Product_Name: "PU",
                    imgSrc: "PU_deskstop.jpg",
                    text: "We providing customized and forward-looking PU products depend on vision, tactile, physical property and function require.",
                    href: "PU"
                },
                {
                    Product_Name: "No Sew",
                    imgSrc: "NO SEW_deskstop.jpg",
                    text: "No sew material adjust the texture, color and composition according to customer applications \
                            and needs to reach customer target and optimize the final product.",
                    href: "No_Sew"
                },
            ],

            Applications_ImgSrc: [
                {
                    Product_Name: "Shoes",
                    imgSrc: "Shoes PU products application straight_desktop.jpg",
                },
                {
                    Product_Name: "Bags",
                    imgSrc: "Bags PU products application horizontal_desktop.jpg",
                },
                {
                    Product_Name: "Vehicle",
                    imgSrc: "Vehicle PU products application horizontal_desktop.jpg",
                },
                {
                    Product_Name: "Funiture",
                    imgSrc: "Funiture PU products application horizontal_desktop.jpg",
                },
                {
                    Product_Name: "Gloves",
                    imgSrc: "Gloves PU products application horizontal_desktop.jpg"
                }
            ],

            Catalogue_PDF_file: [
                {
                    fileName: "2021 Shoes",
                    fileSrc: "PU_ISO9001.pdf"
                },
                {
                    fileName: "2021 Funiture",
                    fileSrc: "PU_ISO9001.pdf"
                },
            ],

            Processing_Swiper: {
                spaceBetween: 0,
                effect: 'slide',
                // observeParents: true,
                // observer: true,
                pagination: {
                    el: '.Processing_Swiper-pagination',
                    // type: 'fraction',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.Processing_Swiper-button-prev',
                    nextEl: '.Processing_Swiper-button-next'
                },
                // autoplay: {delay: 5000},   // 輪播
                speed: 300,   // 切換速度
                loop: false,
                grabCursor: true,  //移入後手掌形狀
                // autoHeight: true,
                slidesPerGroup: 1,
                slidesPerView: 1,
                breakpoints: {
                    // 992: { slidesPerGroup: 2, slidesPerView: 2.5 },
                    // 768: { slidesPerGroup: 2, slidesPerView: 1.5 },
                    // 576: { slidesPerGroup: 1, slidesPerView: 1 }
                },
            },

            Processing_Swiper_Data: [
                {
                    title: "High-frequency",
                    isDisplay: true,
                    text: "High-frequency processing is to convert electrical energy into heat and output it to the mold. By using different shape molds, pressure and time, applying on the PU leather to create a variety of appearances. Or by welding TPU products with mesh, to carry out a new composite material suitable for shoes, bag, ball and clothing use.",
                    swiper_Img_Data: [
                        "High-frequency PU Processing1.jpg",
                        "High-frequency PU Processing2.jpg",
                        "High-frequency PU Processing3.jpg",
                        "High-frequency PU Processing4.jpg"
                    ]
                },
                {
                    title: "Embossing Process",
                    isDisplay: false,
                    text: "Embossing processing is to use heating, arc lamp irradiation, etc. to soften the PU leather, and then use wheels engraved with lines, reptiles, animal leather and other lines to press the PU leather into different lines to make the leather more It is unique.",
                    swiper_Img_Data: [
                        "Embossing PU Processing1.jpg",
                        "Embossing PU Processing2.jpg",
                        "Embossing PU Processing3.jpg",
                        "Embossing PU Processing4.jpg"
                    ]
                },
                {
                    title: "Printing process",
                    isDisplay: false,
                    text: "The printing process uses precision electro-engraving equipment to engrave patterns on the surface of high-steel wheels, and then use the relief printing method gravure to borrow the treatment agent and the pigment, to print the totem on PU leather and TPU Film products to increase product added value and increase product diversity. At the same time, according to the trend, we will develop wheel tools such as reptile skin patterns, animal skins, and camouflage technology, which can be matched with different skin textures to activate the leather texture to meet the popular and customer needs.",
                    swiper_Img_Data: [
                        "printing PU Processing1.jpg",
                        "printing PU Processing2.jpg",
                        "printing PU Processing3.jpg",
                        "printing PU Processing4.jpg"
                    ]
                },
            ],

            Cooperation_imgSrc: [
                "Converse-Zeichen-2003-2007-650x366.jpg",
                "081065d8c8904740e685fe.jpg",
                "emtmjd.png",
                "Crocs.jpg",
                "Asics-logo.jpg",
            ]
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        change_Processing_Swiper(inputIndex) {
            return this.Processing_Swiper_Data.forEach((el, index) => {
                inputIndex === index ? el.isDisplay = true : el.isDisplay = false
            })
        },

        mutations_product_Display_index(input_index) {
            this.$store.commit('change_product_Display_index', input_index);
        }
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import "@/css/section_Style"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

main#PU_Leather_Page
    h2.PU_PVC_section_Title_h2
        +text_Setting(38px, 1.33, 2.1px, #c25d04, 600, "Noto Sans", center)
    h3.PU_PVC_section_Title_h3
        +text_Setting(28px, 1.33, 2.1px, #c25d04, 600, "Noto Sans", center)
    p.introduce_section_Text
        +text_Setting(20px, 1.5, 0.1px, #3e2418, 400, "Noto Sans", center)
    .button_more > a
        @include _under_576_width
            letter-spacing: 2px
    figcaption
        +text_Setting(22px, 1.33, 1.1px, #fafafa, 400, "Noto Sans", center)
    a.file
        +text_Setting(18px, 1.33, 1.2px, #3e2418, 400, "Noto Sans", center)
    button.Processing_button
        +text_Setting(20px, 1.33, 1.2px, #b7a593, 400, "Noto Sans", center)
    img, image
        +size(100%)

    @include _under_576_width
        h2.PU_PVC_section_Title_h2
            font-size: 32px
            letter-spacing: normal
        p.introduce_section_Text
            font-size: 16px
        button.Processing_button
            font-size: 16px

    *
        // border: 1px solid #000

    section.PU_PVC_Introduce_section
        padding-top: 107px
        @include _under_576_width
            padding-top: 60px

        article.PU_PVC_article
            width: $max_Width_Percent
            max-width: $max_Container_Width
            margin: 0 auto
            border: 2px solid #f89019
            padding: #{ percentage(50px/$max_Container_Width) } #{ percentage(80px/$max_Container_Width) }

        section.Introduce_card_section
            width: $max_Width_Percent
            max-width: $max_Container_Width
            margin: 0 auto
            padding: 100px 0
            section.Introduce_cardGroup
                section.PU_PVC_card
                    +flex_Center_Setting(space-between)
                    padding: 70px 0
                    &:first-child ~ .PU_PVC_card
                        border-top: 1px solid #707070
                    figure, article.PU_PVC_card_TextBlock
                        +size(48.5%, auto)
                    article.PU_PVC_card_TextBlock
                        +flex_Center_Setting(flex-end, flex-start)
                        align-self: stretch
                        flex-direction: column
                        h3, p
                            width: 100%
                            text-align: left
                            margin-bottom: 20px
                        .button_more
                            width: 100%
                            max-width: 240px
                            align-self: flex-end
                            & > a
                                height: 60px
                                background-image: linear-gradient(97deg, #fdc215 -8%, #fdac15 15%, #fe9117 51%, #fe8017 82%, #ff7b18 106%)
                                border-radius: 5px
            @include _under_768_width
                section.Introduce_cardGroup
                    section.PU_PVC_card
                        flex-direction: column
                        padding: 50px 0
                        max-width: 450px
                        margin: 0 auto
                        figure, article.PU_PVC_card_TextBlock
                            width: 100%
                        article.PU_PVC_card_TextBlock
                            align-self: auto
                            margin-top: 20px
                            .button_more
                                align-self: left
                                margin-top: 30px
            @include _under_576_width
                padding: 60px 0 10px
                section.Introduce_cardGroup
                    section.PU_PVC_card article.PU_PVC_card_TextBlock
                        .button_more
                            max-width: 180px
                            & > a
                                min-width: auto
                                padding: 0
                                height: 55px
                                .arrowRight
                                    display: none

        section.Applications_section
            background-color: #fbe7d3
            padding: 80px 0
            article.Applications_article, section.itemsGroup, section.Catalogue_section
                width: $max_Width_Percent
                max-width: $max_Container_Width
                margin: 0 auto
            
            article.Applications_article
                p
                    margin-top: 25px
            section.itemsGroup
                +flex_Center_Setting(space-between, stretch)
                margin-top: 60px
                .leftColumn, .rightColumn
                    figure
                        position: relative
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
                        &::before
                            +pseudoBlock(100%, 100%, rgba(0,0,0,0.3))
                            opacity: 0
                            transition: 0.3s
                        figcaption
                            position: absolute
                            left: 50%
                            top: 50%
                            transform: translate(-50%, -50%)
                            &::before
                                +pseudoBlock(0, 4px, #fafafa)
                                left: 0
                                top: 130%
                                transition: 0.3s
                        &:hover
                            &::before
                                opacity: 1
                            figcaption::before
                                width: 100%
                .leftColumn
                    width: calc(100% / 3 - 11px)    // 卡片間隔11px
                .rightColumn
                    width: calc(100% / 3 * 2 - 11px)
                    .rightTop_Block, .rightBottom_Block
                        +size(100%, 50%)
                        display: flex
                        justify-content: space-between
                        figure
                            +size(calc(100% / 2 - 11px), calc(100% - 11px))
                    .rightTop_Block
                        align-items: flex-start
                    .rightBottom_Block
                        align-items: flex-end
            
            section.Catalogue_section
                margin-top: 80px
                h2
                    text-align: left
                    color: #4e4740
                    border-bottom: 1px solid #707070
                    padding-bottom: 10px
                +fileGroup(#e68e0b) // 在css/section_Style.sass檔案裡面

            @include _under_768_width
                section.itemsGroup
                    position: relative
                    padding-bottom: calc(25% + 5.5px)
                    .leftColumn
                        width: calc(100% / 2 - 5.5px)   // 卡片間隔5.5px
                    .rightColumn
                        width: calc(100% / 2 - 5.5px)
                        // .rightTop_Block, .rightBottom_Block
                        //     figure::before
                        //         opacity: 1!important
                        .rightTop_Block
                            flex-direction: column
                            justify-content: space-between
                            height: 100%
                            figure
                                +size(100%, calc(50% - 5.5px))
                        .rightBottom_Block
                            +size(100%, auto)
                            position: absolute
                            left: 0
                            bottom: 0
                            figure
                                +size(calc(50% - 5.5px), auto)
            @include _under_576_width
                padding: 40px 0
                section.itemsGroup
                    margin-top: 30px
                section.Catalogue_section
                    margin-top: 45px

        section.Processing_section
            background-color: #fffaf5
            padding: 100px 0
            position: relative
            &::before
                +pseudoBlock(100%, 13px)
                background-image: linear-gradient(7deg, #fdc215 -189%, #fdac15 -91%, #fe9117 60%, #fe8017 194%, #ff7b18 296%)
                left: 0
                bottom: 0
            .Processing_container
                width: $max_Width_Percent
                max-width: $max_Container_Width
                margin: 0 auto
                h2
                    text-align: left
                    border-bottom: 1px solid #707070
                    padding-bottom: 10px
                section.Processing
                    .buttonGroup
                        +flex_Center_Setting(space-between)
                        width: 100%
                        max-width: 950px
                        margin: 60px auto 0
                        overflow-x: auto
                        padding-bottom: 15px
                        -ms-overflow-style: none // IE and Edge scrollbar樣式修改
                        scrollbar-width: none   // Firefox scrollbar樣式修改
                        // scrollbar樣式修改(僅限webkit瀏覽器)
                        &::-webkit-scrollbar
                            display: none
                            // height: 7px
                            // background-color: #F5F5F5
                        // &::-webkit-scrollbar-thumb
                            // border-radius: 20px
                            // background-color: #fff
                            // background-image: -webkit-linear-gradient(10deg, #fdc215 -9%, #fdac15 15%, #fe9117 51%, #fe8017 83%, #ff7b18 107%)
                        button.Processing_button
                            width: calc( (100% - 2%*2) / 3 )
                            min-width: 230px
                            padding: 17px 10px
                            border-radius: 11px
                            border: solid 3px #decebe
                            position: relative
                            overflow: hidden
                            z-index: 1
                            transition: color 0.2s
                            &:nth-child(1) ~ .Processing_button
                                margin-left: 2%
                            &::before
                                +pseudoBlock(100%, 100%)
                                background-image: linear-gradient(98deg, #fdc215 -8%, #fdac15 15%, #fe9117 51%, #fe8017 82%, #ff7b18 106%)
                                left: 0
                                top: 0
                                opacity: 0
                                z-index: -1
                                transition: opacity 0.3s
                            &.Processing_Show
                                padding: 20px 0
                                color: #fff
                                border: solid 0px
                                &::before
                                    opacity: 1
                    .Processing_Swiper
                        max-width: calc(950px - 200px)
                        position: relative
                        animation: fadeIn 0.3s
                        margin-top: 40px
                        .swiper-slide
                            // padding: 0 calc((100% - 750px) / 2)
                            figure
                                +size(100%, auto)
                        .Processing_Swiper-pagination
                            +flex_Center_Setting
                            margin-top: 20px
                            span
                                +size(15px)
                                background-color: #2e1004
                                margin: 0 5px
                        .Processing_Swiper-button-prev, .Processing_Swiper-button-next
                            +size(50px, calc(100% - 20px - 20px))
                            position: absolute
                            top: 0
                            z-index: 1
                            .b-icon
                                +size(100%)
                                color: #cfbaa5
                        .Processing_Swiper-button-prev
                            left: 0
                        .Processing_Swiper-button-next
                            right: 0
                    p
                        width: 100%
                        max-width: 950px
                        margin: 30px auto 0
            @include _under_576_width
                padding: 60px 0
                .Processing_container 
                    section.Processing 
                        .buttonGroup button.Processing_button
                            min-width: 200px

        article.Cooperation_article
            +flex_Center_Setting
            flex-direction: column
            width: $max_Width_Percent
            max-width: $max_Container_Width
            margin: 0 auto
            padding: 100px 0
            h2, p
                width: 100%
            p
                margin-top: 20px
            .figureGroup
                +size(100%)
                +flex_Center_Setting(space-between)
                margin-top: 50px
                figure
                    +size(calc( (100% - 2%*4) / 5), auto)
                    @for $i from 1 through 5
                        &:nth-child(#{$i})
                            animation: fadeInUp 1.5s (0.3s*$i) backwards
            .button_more
                width: 260px
                margin-top: 70px
                & > a
                    background-color: #fe9b17
                    box-shadow: 3px 3px 6px 0 rgba(29, 81, 97, 0.34)
            @include _under_576_width()
                padding: 60px 0
                .figureGroup
                    flex-wrap: wrap
                    justify-content: center
                    figure
                        width: calc(100% / 3)
                        &:nth-child(3) ~ figure
                            margin-top: 40px
</style>