<template lang="pug">
#Product_Template_2
    section._Container
        //- 一般全覽選單
        section.blockSection.products_List
            .item(v-for="(item, index) of importProduct_Data.products_List" 
                :class="{showItem: index === Block_currentDisplay_index}")
                button.item_buttonText(@click="change_products(index)" v-scroll-to="{el:'.prosucts_section', offset: -300}") {{item.product_name}}
        //- 手機版下拉選單
        section.blockSection.products_dropdownList
            h3.productTemplate2_H3 Selected item：
            .select_Body
                select(name="products_List" v-model="Block_currentDisplay_index")
                    option(v-for="(item, index) of importProduct_Data.products_List" :value="index") {{item.product_name}}
                b-icon.select_icon(icon="caret-down-fill")
        section.prosucts_section
            section.blockSection(v-for="(productBlock, index) of importProduct_Data.products_List" v-show="index === Block_currentDisplay_index")
                article
                    hgroup
                        h2.productTemplate2_H2 {{productBlock.swiper_title1}}
                        h3.productTemplate2_H3 {{productBlock.swiper_title2}}
                    p.intro_Text {{productBlock.swiper_text}}
                swiper.prosucts_Swiper(:options='prosucts_Swiper')
                    swiper-slide.prosucts_Slide(v-for="(img, index) of productBlock.swiper_Img" :key="index")
                        figure
                            img(:src="getImgUrl(`Products/PU/${img}`)", alt="alt")
                    .prosucts_Swiper-pagination(slot="pagination")
                    .swiper-button-prev(slot='button-prev')
                        b-icon.icon(icon="chevron-left")
                    .swiper-button-next(slot='button-next')
                        b-icon.icon(icon="chevron-right")
        
        //- Specification規格表
        section.blockSection.specification_Form_Container
            h2.productTemplate2_H2 Specifications
            .formatTable_Container(ref="scroll_container" @mousewheel="scrollX")
                section.formatTable
                    ul(v-for="item of importProduct_Data.specification_table_Data")
                        li.form_head {{item.thead}}
                        li.form_items(v-for="td of item.td_Array") {{td}}
    Button_Style.button_more(btnTitle="Contact Us" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    components: {
        pageTop,
        Button_Style
    },

    props: {
        // colorType: {
        //     type: String,
        //     default: "coil"
        // },
        importProduct_Data: {
            type: Object
        }
    },

    data() {
        return{
            // Block_currentDisplay_index: 0,

            prosucts_Swiper: {
                spaceBetween: 10,
                effect: 'slide',
                pagination: {
                    el: '.prosucts_Swiper-pagination',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next'
                },
                speed: 300,   // 切換速度
                loop: false,
                grabCursor: true,  //移入後手掌形狀
                autoHeight: true
            }
        }
    },

    computed: {
        Block_currentDisplay_index: {
            get(){
                return this.$store.state.product_Display_index
            },
            set(input_index) {
                return this.$store.commit('change_product_Display_index', input_index);
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
        change_products(input_index) {
            // return input_index !== this.Block_currentDisplay_index ? 
            //     this.Block_currentDisplay_index = input_index : false
            this.$store.commit('change_product_Display_index', input_index);
        },
        scrollX(event) {
            event.preventDefault(); // 滑鼠游標停在上面後，垂直的捲動條不作動
            this.$refs['scroll_container'].scrollLeft += event.deltaY;
        }
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import "@/css/section_Style"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

#Product_Template_2
    // h1.productTemplate_H1
    //     +text_Setting(32px, 1.3, 1.6px, #223b51, 500, "Noto Sans TC", left)
    h2.productTemplate2_H2
        +text_Setting(42px, 1.3, 1px, #fe8017, 600, "Noto Sans TC", left)
    h3.productTemplate2_H3
        +text_Setting(20px, 1.3, 0.28px, #1c1005, 400, "Noto Sans TC", left)
    // a.file
    //     +text_Setting(18px, 1.33, 1.2px, #3e2418, 400, "Noto Sans", center)
    p.intro_Text
        +text_Setting(20px, 1.36, 0.22px, #515354, 300, "Noto Sans TC", left)
    button.item_buttonText
        +text_Setting(20px, 1.6, 0.2px, #968982, 400, "Noto Sans TC", left)
    select
        +text_Setting(16px, 1.3, normal, #968982, 400, "Noto Sans TC", left)
    li.form_head
        +text_Setting(18px, 1.6, 0.2px, #515354, 400, "Noto Sans TC", left)
    li.form_items
        +text_Setting(18px, 1.6, 0.2px, #515354, 300, "Noto Sans TC", left)    
    img
        +size(100%)
    padding: 60px 0 80px

    @include _under_768_width()
        h2.productTemplate2_H2
            font-size: 32px
    @include _under_576_width()
        h2.productTemplate2_H2
            font-size: 28px
        p.intro_Text
            font-size: 16px
        li.form_head
            font-size: 14px
        li.form_items
            font-size: 14px
    @include _under_375_width()
        li.form_head
            font-size: 12px
        li.form_items
            font-size: 12px

    // *
    //     border: 1px solid #000
    
    section.blockSection
        +size($max_Width_Percent, auto)
        max-width: $max_Container_Width
        margin: 0 auto

    section._Container
        
        section.products_List
            +flex_Center_Setting(flex-start)
            flex-wrap: wrap
            .item
                +flex_Center_Setting(flex-start)
                width: calc( (100% - 2%*3)/4)
                button.item_buttonText
                    padding-bottom: 10px
                &:not(:nth-child(4n+1))
                    margin-left: 2%
                &:nth-child(4) ~ div
                    margin-top: 20px
                &.showItem
                    pointer-events: none
                    button.item_buttonText
                        color: #fe7e17
                        text-shadow: 1px 0px 0px rgba(0, 0, 0, 0.3)
                        position: relative
                        &::before
                            +pseudoBlock(100%, 4px)
                            background-image: linear-gradient(to right, #fdc215 -9%, #fdac15 15%, #fe9117 51%, #fe8017 83%, #ff7b18 107%)
                            left: 0
                            bottom: 0
                            animation: fadeIn 0.3s
            @include _under_992_width()
                .item
                    width: calc( (100% - 2%*2)/3)
                    &:nth-child(3) ~ div
                        margin-top: 30px
                    &:not(:nth-child(4n+1))
                        margin-left: 0
                    &:not(:nth-child(3n+1))
                        margin-left: 2%
            @include _under_768_width()
                display: none

        section.products_dropdownList
            justify-content: center
            align-items: center
            flex-direction: column
            display: none
            h3, .select_Body
                width: 100%
            h3
                text-align: center
            .select_Body
                max-width: 300px
                margin-top: 20px
                position: relative
                select
                    width: 100%
                    padding: 10px
                    border-radius: 4px
                    border: solid 1px #c8c6c6
                    appearance: none    // select預設下拉的icon取消
                    &::-ms-expand   // select預設下拉的icon取消(for IE)
                        display: none
                .select_icon
                    +size(15px)
                    color: #707070
                    top: 50%
                    right: 10px
                    transform: translateY(-50%)
                    position: absolute
            @include _under_768_width()
                display: flex

        section.prosucts_section
            background-color: #fff2e6
            padding: 55px 0 60px
            position: relative
            margin-top: 30px
            &::before
                +pseudoBlock(100%, 10px)
                background-image: linear-gradient(to right, #fdc215 -9%, #fdac15 15%, #fe9117 51%, #fe8017 83%, #ff7b18 107%)
                left: 0
                bottom: 0
            section.blockSection
                +flex_Center_Setting(space-between)
                animation: fadeIn 0.5s
                article
                    width: 50%
                    h3
                        margin-top: 10px
                    p
                        margin-top: 15px
                .prosucts_Swiper
                    width: 40%
                    margin: 0
                    .prosucts_Swiper-pagination
                        text-align: center
                        bottom: 0
                        margin-top: 20px
                        span
                            +size(11px)
                            background-color: #4f352a
                    .swiper-button-prev, .swiper-button-next
                        +size(40px, calc(100% - 20px - 24px))   // 高度100%減掉margin-top和pagination點點的height
                        display: flex
                        top: 0
                        color: #a49d9d
                        margin-top: 0
                        &::after
                            display: none
                        .icon
                            +size(100%, auto)
                            color: #fefefe
                            // font-weight: 700
                            // stroke-width: 3
                    .swiper-button-prev
                        left: 0
                    .swiper-button-next
                        right: 0
                        
            @include _under_768_width()
                padding-top: 40px
                section.blockSection
                    flex-direction: column
                    h2, h3, p
                        text-align: center
                    article, .prosucts_Swiper
                        width: 100%
                    .prosucts_Swiper
                        max-width: 500px
                        margin-top: 30px

        //- Specification規格表
        section.specification_Form_Container
            margin-top: 90px
            h2
                border-bottom: 1px solid #707070
                padding-bottom: 18px
                margin-bottom: 30px
            .formatTable_Container
                +size(100%)
                overflow-x: auto
                // scrollbar樣式修改(僅限webkit瀏覽器)
                &::-webkit-scrollbar
                    // width: 6px
                    height: 7px
                    background-color: #F5F5F5
                &::-webkit-scrollbar-thumb
                    border-radius: 20px
                    background-color: #fff
                    background-image: -webkit-linear-gradient(10deg, #fdc215 -9%, #fdac15 15%, #fe9117 51%, #fe8017 83%, #ff7b18 107%)
                    // -webkit-box-shadow: inset 0 0 10px rgba(#fe8017, 0.5)
                    // background-color: #555
                @media all and (max-width: 1340px)
                    box-shadow: 5px 0px 35px -8px rgba(0,0,0,0.2)
                    
            section.formatTable
                +flex_Center_Setting(center, stretch)
                background-color: #fff
                min-width: $max_Container_Width
                ul
                    display: flex
                    flex-direction: column
                    border: solid 
                    border-color: #b5baba
                    border-width: 0 0 1px 1px
                    // Products
                    &:first-child
                        text-align: center
                    &:last-child
                        border-right-width: 1px
                    li
                        display: flex
                        align-items: center
                        box-sizing: border-box
                        white-space: pre-line
                        &.form_head
                            height: 50px
                            justify-content: center
                            background-color: #633003
                            color: #fafafa
                        &.form_items
                            color: #707070
                            height: 100%
                            position: relative
                            // padding-left: 5px
                            &:not(:last-child)
                                &::before
                                    +pseudoBlock(100%, 1px, #b5baba)
                                    left: 0
                                    bottom: 0
                                    // transform: translateY(50%)

    .button_more
        width: 260px
        margin: 80px auto 0
        & > a
            width: 260px
            box-shadow: 3px 3px 6px 0 rgba(29, 81, 97, 0.34)
            background-color: #fe9b17
</style>        