<template lang="pug">
main#Quality
    pageTop(pageTitle="Quality" imgSrc="Quality/Quality_page_top_img_desktop.jpg" imgSrc_Mobile="Quality/Quality page top img_mobile.jpg")
    section.Quality_Page
        article.introduce_Quality
            figure
                img(:src="getImgUrl(`Quality/Group_2182.png`)" alt="")
            h1.Quality_h1 Quality Assurance
            p.Quality_Text The quality of Nan Ya has been verified by you and has passed the tests of heavy metals, organic solvents, azo dyes, REACH SVHC 168 substances, etc. It is a reliable choice for you to purchase with peace of mind. Your inquiries and suggestions are welcome.
        section.Certificate_file_Container
            swiper.Certificate_group(:options='Certificate_File_Swiper')
                swiper-slide
                    figure.Certificate_file
                        img(:src="getImgUrl(`Quality/PU_ISO9001.jpg`)", alt="alt")
                        a.fileLink(href="./pdf/PU_ISO9001.pdf", title="PU ISO9001", target="_blank")
                            figcaption ISO9001 2015:PU
                                b-icon(icon="search")
                swiper-slide
                    figure.Certificate_file
                        img(:src="getImgUrl(`Quality/PVC_ISO9001.jpg`)", alt="alt")
                        a.fileLink(href="./pdf/PVC_ISO9001.pdf", title="PVC ISO9001", target="_blank")
                            figcaption ISO9001 2015:PVC
                                b-icon(icon="search")
                swiper-slide
                    figure.Certificate_file
                        img(:src="getImgUrl(`Quality/GRS_CERTIFICATION 2020.jpg`)", alt="alt")
                        a.fileLink(href="./pdf/GRS_CERTIFICATION_2020.pdf", title="GRS Certification 2020", target="_blank")
                            figcaption GRS Certification 2020
                                b-icon(icon="search")
        section.Quality_Policy_Container
            article.Quality_Policy
                h1.Quality_h1 Quality Policy
                p.Quality_Text Since our company was founded in 1958, 
                    | we have been adhering to the spirit of equal emphasis on environmental protection and industrial development, 
                    | and we adhere to the goal of “zero accidents and ensuring sustainable operations”.
                p.Quality_Text In order to implement the objectives and concepts, the Company's adopting strategy is to "comply with regulatory commitments, 
                    | cope with energy conservation and waste reduction", "strengthen pollution prevention, implement continuous improvement", 
                    | "implement risk management, deepen green culture" and "pursuing sustainable development.
                p.Quality_Text "Do your best in social responsibility." 
                figure.backgroundImg
                    img(:src="getImgUrl(`home_backgroundImg1.png`)", alt="alt")
            section.Quality_Policy_cardGroup
                section.Quality_Policy_card(v-for="articleCard of Quality_Policy_cardGroup_Content" :key="articleCard.title")
                    figure.Quality_Policy_card_Img
                        img(:src="getImgUrl(articleCard.imgSrc)", alt="alt")
                    article.Quality_Policy_card_article
                        h2.Quality_h2 {{articleCard.title}}
                            b-icon(icon="check2")
                        p.Quality_Text {{articleCard.text}}
                figure.backgroundImg.bg_1
                    img(:src="getImgUrl(`home_backgroundImg1.png`)", alt="alt")
                figure.backgroundImg.bg_2
                    img(:src="getImgUrl(`home_backgroundImg3.png`)", alt="alt")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
// import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `Quality-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "The quality of Nan Ya has been verified by you and has passed the tests of heavy metals, organic solvents, azo dyes, REACH SVHC 168 substances, etc. It is a reliable choice for you to purchase with peace of mind. Your inquiries and suggestions are welcome."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "The quality of Nan Ya has been verified by you and has passed the tests of heavy metals, organic solvents, azo dyes, REACH SVHC 168 substances, etc. It is a reliable choice for you to purchase with peace of mind. Your inquiries and suggestions are welcome." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "The quality of Nan Ya has been verified by you and has passed the tests of heavy metals, organic solvents, azo dyes, REACH SVHC 168 substances, etc. It is a reliable choice for you to purchase with peace of mind. Your inquiries and suggestions are welcome." },
            ]
        }
    },

    components: {
        pageTop,
        // Button_Style
    },

    data() {
        return{
            Certificate_File_Swiper: {
                spaceBetween: 30,
                effect: 'slide',
                allowTouchMove: false,
                // observeParents: true,
                // observer: true,
                // pagination: {
                //     el: '.PVC_Swiper-pagination',
                //     type: 'fraction',
                //     // clickable: true   // 滑鼠可點擊導覽點點
                // },
                // navigation: {
                //     prevEl: '.PVC_swiper-button-prev',
                //     nextEl: '.PVC_swiper-button-next'
                // },
                // autoplay: {delay: 5000},   // 輪播
                speed: 300,   // 切換速度
                loop: false,
                // autoHeight: true,
                slidesPerGroup: 3,
                slidesPerView: 3,
                breakpoints: {
                    992: { spaceBetween: 20 },
                    768: { slidesPerGroup: 1, slidesPerView: 1.8, allowTouchMove: true, centeredSlides: true, grabCursor: true },
                    // 576: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 20},
                },
            },

            Quality_Policy_cardGroup_Content: [
                {
                    title: "Air pollution prevention and management",
                    text: "The use of best available control technology and continuous improvement of pollution prevention and control systems to meet or exceed national standards, \
                    and through independent inspections, automatic monitoring facilities and other measures, \
                    is committed to maintaining the surrounding environment and safety of the plant area.",
                    imgSrc: "Quality/Air pollution-01-01.jpg"
                },
                {
                    title: "Water pollution prevention and management",
                    text: "Pursuant to relevant government laws and regulations, \
                    in accordance with relevant government laws and regulations, \
                    management measures have been formulated to promote wastewater waste reduction, \
                    effective treatment of wastewater and discharge control, etc., in compliance with environmental protection laws and regulations.",
                    imgSrc: "Quality/Water pollution-01.jpg"
                },
                {
                    title: "Toxic chemical operation management",
                    text: "With the goal of sustainable use of resources and rationalization of related cleanup costs, \
                    management is mainly based on process waste reduction, followed by reuse, incineration and landfill as the last resort.",
                    imgSrc: "Quality/Toxic chemical-01.jpg"
                },
                {
                    title: "Waste management measures",
                    text: "Operate according to the relevant regulations of the government, and work to prevent environmental pollution caused by leakage or endanger human health, \
                    and promote management measures such as reduction and computer control operations.",
                    imgSrc: "Quality/Waste management-01.jpg"
                }
            ]
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

main#Quality
    *
        // border: 1px solid #000
    h1, p
        width: 100%
    h1.Quality_h1
        +text_Setting(38px, 1, 2.1px, #223b51, 600, "Noto Sans", center)
        @include _under_768_width()
            font-size: 32px
    h2.Quality_h2
        +text_Setting(28px, 1.2, 1px, #223b51, 600, "Noto Sans", left)
        @include _under_768_width()
            font-size: 24px
        @include _under_576_width()
            font-size: 18px
            letter-spacing: normal
    p.Quality_Text
        +text_Setting(20px, 1.33, 0.24px, #223b51, 400, "Noto Sans", left)
        @include _under_576_width()
            font-size: 16px
    figcaption
        +text_Setting(18px, 1.36, 1px, #092a46, 400, "Noto Sans", center)
        @include _under_576_width()
            font-size: 14px
    img
        +size(100%)

    section.Quality_Page
        +size(100%, auto)
        padding: 53px 0 0

        .introduce_Quality
            +size($max_Width_Percent, auto)
            +flex_Center_Setting
            flex-direction: column
            max-width: $max_Container_Width
            margin: 0 auto
            figure
                +size(100%, auto)
                max-width: 600px
            p.Quality_Text
                margin-top: 30px
                text-align: center
            @include _under_768_width()
                h1.Quality_h1
                    margin-top: 30px

        .Certificate_file_Container
            margin-top: 70px
            padding-bottom: 78px
            background: linear-gradient(to top, #fcfcd5 0%, #fcfcd5 60%, transparent 60%, transparent 100%)
            .Certificate_group
                +size($max_Width_Percent, auto)
                max-width: $max_Container_Width
                .Certificate_file
                    +size(100%)
                    +flex_Center_Setting
                    flex-direction: column
                    padding-top: 15px
                    img
                        box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.16)
                        transition: transform 0.3s
                        &:hover
                            transform: translateY(-15px)
            a.fileLink
                display: inline-block
                width: 100%
                max-width: 300px
                background-color: #fff
                border: solid 1px #707070
                border-radius: 45px
                margin-top: 34px
                padding: 15px
                position: relative
                overflow: hidden
                &::before
                    +pseudoBlock(100%, 100%, $theme_color)
                    transform: translateX(-100%)
                    transition: transform 0.5s
                    left: 0
                    top: 0
                    z-index: 1
                &:hover
                    &::before
                        transform: translateY(0)
                    figcaption
                        color: #fff
                figcaption
                    +size(100%)
                    +flex_Center_Setting(space-between)
                    margin: 0 auto
                    position: relative
                    transition: color 0.5s
                    z-index: 2
                    .b-icon
                        +size(25px)
            @include _under_768_width()
                padding-bottom: 60px
                .Certificate_group
                    width: 100%
                .swiper-slide
                    img
                        opacity: 0.5
                    a.fileLink
                        transition: opacity 0.3s
                        pointer-events: none
                        opacity: 0
                .swiper-slide-active
                    img
                        opacity: 1
                    a.fileLink
                        pointer-events: auto
                        opacity: 1

    section.Quality_Policy_Container
        +size(100%, auto)
        padding: 120px 0 250px
        // padding-bottom: 250px
        // margin-top: 120px
        // position: relative
        .Quality_Policy, .Quality_Policy_cardGroup
            width: $max_Width_Percent
            max-width: $max_Container_Width
            margin: 0 auto
        .Quality_Policy
            position: relative
            h1.Quality_h1
                margin-bottom: 50px
            p.Quality_Text
                text-align: center
                &:nth-child(2) ~ p.Quality_Text
                    margin-top: 30px
            figure.backgroundImg
                +size(500px, auto)
                position: absolute
                right: 0
                top: 0
                transform: translateX(100%)
        .Quality_Policy_cardGroup
            margin-top: 70px
            position: relative
            .Quality_Policy_card
                +flex_Center_Setting(space-between, center)
                border-radius: 12px
                border: 2px solid #2edbef
                box-shadow: 5px 7px 6px 0 rgba(0, 0, 0, 0.16)
                padding: 30px 25px
                transition: 0.5s
                &:nth-child(1) ~ .Quality_Policy_card
                    margin-top: 45px
                &:nth-child(2n)
                    background-color: #f1feff
                &:nth-child(2n+1)
                    background-color: #fff
                &:hover
                    box-shadow: 5px 7px 6px 0 rgba(0, 0, 0, 0.5)
                    transform: translate(-5px, -5px)

                figure.Quality_Policy_card_Img
                    +size(auto, 100%)
                    max-width: 22.5%
                    border-radius: 50%
                    overflow: hidden

                article.Quality_Policy_card_article
                    +size(75%, auto)
                    max-width: 75%
                    display: flex
                    flex-direction: column
                    justify-content: center
                    h2.Quality_h2
                        +flex_Center_Setting(flex-end)
                        flex-direction: row-reverse
                        .b-icon
                            +size(45px)
                            min-width: 45px
                            align-self: flex-start
                            border-radius: 50%
                            padding: 5px
                            color: #70f1e6
                            border: 3px solid #70f1e6
                            margin-right: 20px
                    p.Quality_Text
                        margin-top: 20px
            figure.backgroundImg
                position: absolute
                z-index: -1
            .bg_1
                +size(700px, auto)
                left: 0
                top: 0
                transform: translate(-50%, -70px)
            .bg_2
                +size(670px, auto)
                right: 0
                bottom: 0
                transform: translate(50%, 30%)
        @include _under_768_width()
            padding: 70px 0
        @include _under_576_width()
            .Quality_Policy_cardGroup
                .Quality_Policy_card
                    flex-direction: column
                    figure.Quality_Policy_card_Img
                        +size(100%, auto)
                        max-width: 200px
                    article.Quality_Policy_card_article
                        width: 100%
                        max-width: none
                        margin-top: 25px
                        h2.Quality_h2
                            .b-icon
                                +size(35px)
                                min-width: 35px
                                margin-right: 12px
                                align-self: auto
                        // p
                        //     text-align: center
</style>
