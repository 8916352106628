<template lang="pug">
nav#navbar(:class="{navbarHide: navbar_hide_Control}")
    .navbar.navbar-expand-lg.navbar-light(:class="{navbar_isExpand: navbar_isExpand}")
        router-link.logoLink.navbar-brand(:to="{name: `Home${get_routerName_lang_from_Store}`}" v-scroll-to="{el:'#navbar'}")
            img.logoImg(src="@/assets/logo.png")
            img.mobileImg(src="@/assets/logo.png" @click="click_toggle_Button" v-show="!navbar_isExpand")
            img.mobileImg(src="@/assets/logo_white.png" @click="click_toggle_Button" v-show="navbar_isExpand")
        button#toggle_Button.navbar-toggler.collapsed(@click="navbar_toggle_event" type='button' data-toggle='collapse' data-target='#navbarContent' aria-controls='navbarContent' aria-expanded='false' aria-label='Toggle navigation')
            //- span.navbar-toggler-icon
            .line.line1
            .line.line2
            .line.line3
        #navbarContent.collapse.navbar-collapse.justify-content-end
            ul.navbar-nav.navbarList

                //- 公司介紹
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `Company${get_routerName_lang_from_Store}`}" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.Company')}}
                        //- span.sr-only (current)
                
                //- 產品資訊
                li.nav-item.nav-productMenu.d-none.d-lg-block(@mouseover="open_productMenu" @mouseleave="close_productMenu")
                    .nav-link.navLink_Setting {{$t('__MAIN_TITLE.Products')}}
                    //- 產品hover選單
                    transition(name="hover_productMenu" :duration="{ enter: 300, leave: 300 }")
                        section.productMenu_Container(v-show="productMenu_isActive")
                            .row
                                .productMenu
                                    //- PVC Film
                                    router-link.menuProduct_Link_Title(:to="{name: `PVC_Film${get_routerName_lang_from_Store}`}" 
                                                                        @click.native="close_productMenu" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.PVC_Film')}}
                                    .productMenuList
                                        //- Tape PVC Sheet
                                        router-link.menuProduct_Link(:to="{name: `Tape_PVC_Sheet${get_routerName_lang_from_Store}`}" 
                                                                            @click.native="close_productMenu" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.Tape_PVC_Sheet')}}
                                            b-icon(icon="arrow-right")
                                        //- Semi-rigid PVC Film
                                        router-link.menuProduct_Link(:to="{name: `Semi_Rigid_Film${get_routerName_lang_from_Store}`}" 
                                                                            @click.native="close_productMenu" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.Semi_Rigid_Film')}}
                                            b-icon(icon="arrow-right")
                                        //- Clear Film
                                        router-link.menuProduct_Link(:to="{name: `Clear_Film${get_routerName_lang_from_Store}`}" 
                                                                            @click.native="close_productMenu" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.Clear_Film')}}
                                            b-icon(icon="arrow-right")
                                .productMenu
                                    //- PU Leather
                                    router-link.menuProduct_Link_Title(:to="{name: `PU_Leather${get_routerName_lang_from_Store}`}" 
                                                                        @click.native="close_productMenu" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.PU_Leather')}}
                                    .productMenuList
                                        //- PU
                                        router-link.menuProduct_Link(:to="{name: `PU${get_routerName_lang_from_Store}`}" 
                                                                            @click.native="close_productMenu; mutations_product_Display_index(0)" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.PU')}}
                                            b-icon(icon="arrow-right")
                                        //- No Sew
                                        router-link.menuProduct_Link(:to="{name: `No_Sew${get_routerName_lang_from_Store}`}" 
                                                                            @click.native="close_productMenu; mutations_product_Display_index(0)" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.No_Sew')}}
                                            b-icon(icon="arrow-right")


                //- 產品資訊-摺疊
                li.nav-item.d-block.d-lg-none.nav-item_FoldMenu
                    .navbar-toggler.collapsed.navLink_Setting(data-toggle='collapse' data-target='#products_List' aria-controls='products_List' aria-expanded='false' aria-label='Toggle navigation') {{$t('__MAIN_TITLE.Products')}}
                        b-icon(icon="chevron-up")
                        //- b-icon(icon="chevron-down")
                    #products_List.collapse.navbar-collapse
                        ul.navbar-nav.ml-auto
                            li.nav-item(@click="click_toggle_Button")
                                //- PVC Film
                                router-link.navLink_Setting(:to="{name: `PVC_Film${get_routerName_lang_from_Store}`}" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.PVC_Film')}}
                                    .arrowRight
                            li.nav-item(@click="click_toggle_Button")
                                //- PU Leather
                                router-link.navLink_Setting(:to="{name: `PU_Leather${get_routerName_lang_from_Store}`}" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.PU_Leather')}}
                                    .arrowRight
                
                //- 品質
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `Quality${get_routerName_lang_from_Store}`}" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.Quality')}}
                
                //- 聯絡我們
                li.nav-item(@click="click_toggle_Button")
                    router-link.nav-link.navLink_Setting(:to="{name: `Contact${get_routerName_lang_from_Store}`}" v-scroll-to="{el:'#navbar'}") {{$t('__MAIN_TITLE.Contact')}}
                
                //- 語言清單-dropdown list
                li.nav-item.dropdown.d-none.d-lg-block
                    a#languageDropdown_List.nav-link.dropdown-toggle.navLink_Setting(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false') {{currentLanguage_Display}}
                    .dropdown-menu.languageDropdown_List(aria-labelledby='languageDropdown_List')
                        button.dropdown-item.language_item(v-for="langItem of languageList" @click="changeLanguage_Button(langItem.lang)"
                                                            v-show="langItem.lang !== currentLanguage" ) {{langItem.language}}
                        //- button.dropdown-item.language_item 繁體中文
                //- 語言清單-摺疊
                li.nav-item.d-block.d-lg-none.nav-item_FoldMenu
                    .nav-item.navbar-toggler.collapsed.navLink_Setting(data-toggle='collapse' data-target='#languageList' aria-controls='languageList' aria-expanded='false' aria-label='Toggle navigation') {{currentLanguage_Display}}
                        b-icon(icon="chevron-up")
                        //- b-icon(icon="chevron-down")
                    #languageList.collapse.navbar-collapse
                        ul.navbar-nav.ml-auto
                            li.nav-item(@click="click_toggle_Button")
                                button.navLink_Setting(v-for="langItem of languageList" @click="changeLanguage_Button(langItem.lang)"
                                                        v-show="langItem.lang !== currentLanguage" ) {{langItem.language}}
                            //- li.nav-item(@click="click_toggle_Button")
                            //-     button.navLink_Setting VN
                            //-         .arrowRight
                            //- li.nav-item(@click="click_toggle_Button")
                            //-     button.navLink_Setting 繁體中文
                            //-         .arrowRight

</template>

<script>
import $ from "jquery"
import { mapGetters } from 'vuex'

export default {

    props: {
        navbar_hide_Control: {
            type: Boolean
        }
    },

    data() {
        return{
            // 控制navbar背景顏色
            navbar_isExpand: false,

            // 控制摺疊按鈕延遲
            button_Click: false,

            // 控制產品選單hover
            productMenu_isActive: false,

            // 這裡可直接新增需要的語言清單，後續可以直接增加
            languageList: [
                {
                    lang: "en",
                    language: "English"
                },
                // {
                //     lang: "vn",
                //     language: "Việt Ngữ"
                // },
                // {
                //     lang: "tw",
                //     language: "繁體中文"
                // },
            ]
        }
    },

    computed: {
        // 從Vuex拿getters
        ...mapGetters(['get_routerName_lang_from_Store']),
        
        // 當前的語系，從Vuex抓語系值，取值後return對應到的語系並顯示在按鈕上
        currentLanguage_Display() {
            return this.languageList.find(el => {
                return el.lang === this.currentLanguage
            })?.language
        },

        currentLanguage() {
            return this.$store.state.currentLanguage
        }

    },

    methods: {
        // 點LOGO模擬click收合的button
        click_toggle_Button() {
            if(this.$store.state.windows_Width < 992  && this.navbar_isExpand === true) {
                $('#toggle_Button').trigger("click")
            }
        },

        // expand_navbar() {
        //     // this.click_toggle_Button()
        //     this.navbar_isExpand = true
        // },

        // collapse_navbar() {
        //     this.click_toggle_Button()
        // },

        // 控制navbar背景顏色
        navbar_toggle_event() {
            if(this.button_Click) { return }
            
            this.navbar_isExpand = !this.navbar_isExpand
            
            // 控制摺疊按鈕延遲
            this.button_Click = true
            setTimeout(()=>{
                this.button_Click = false
            },300)
        },

        open_productMenu() {
            this.productMenu_isActive = true
        },

        close_productMenu() {
            this.productMenu_isActive = false
        },

        mutations_product_Display_index(input_index) {
            this.$store.commit('change_product_Display_index', input_index);
        },

        // 切換語系button
        changeLanguage_Button(input_lang) {
            this.$store.commit('changeLanguage_mutationsVuex', input_lang)
            this.$i18n.locale = input_lang
            this.changeLanguage_URL(input_lang)
        },

        // 語系切換動作: URL切換、router以name切換
        changeLanguage_URL(input_lang) {
            const currentPage_Name = this.$route.name                   // 當前頁面router name
            const previousParams_lang = this.$route.params.lang         // 切換前的lang變數
            const storeLanguageList = this.$store.state.languageList    // 網站語系清單
            const defaultLanguage = this.$store.state.defaultLanguage   // 預設語系

            // 主語系 --> others子語系
            if(!previousParams_lang){
                this.$router.push({ name: `${currentPage_Name}_lang`, params: {lang: input_lang} });    // Company --> Company_lang
            }

            // others子語系 --> 主語系
            if(previousParams_lang){
                const defaultPage_Name = currentPage_Name.split('_lang').filter(el=>el).join('')    // Company_lang --> Company
                this.$router.push({ name: defaultPage_Name });
            }

            // others子語系 --> others子語系
            if(storeLanguageList.includes(previousParams_lang) && input_lang !== defaultLanguage){
                this.$router.push({ name: currentPage_Name, params: {lang: input_lang} });
            }
        }

    },

    created() {
        let isExpand = $('#toggle_Button').attr('aria-expanded')
        isExpand === 'true' ? this.navbar_isExpand = true : this.navbar_isExpand = false
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap')

.hover_productMenu-enter, .hover_productMenu-leave-to
    opacity: 0
.hover_productMenu-enter-to, .hover_productMenu-leave
    opacity: 1
.hover_productMenu-enter-active
    transition: 0.3s
    // animation: fadeIn 0.3s
.hover_productMenu-leave-active
    transition: 0.3s
    // animation: fadeOut 0.3s

#navbar.navbarHide
    @include _under_992_width
        top: -70px

nav#navbar
    transition: top 0.5s
    *
        // border: 1px solid #000
    // 產品選單連結文字設定
    a.menuProduct_Link_Title
        +text_Setting(20px, 2, 1px, #60ade7, 500, "Noto Sans TC", center)

    a.menuProduct_Link
        +text_Setting(18px, 2.5, 0.9px, #fff, 400, "Noto Sans TC", center)
    a.menuProduct_Mall_Link
        @extend .menuProduct_Link_Title
        letter-spacing: 2.3px
    
    +size(100%, auto)
    +flex_Center_Setting
    position: fixed
    left: 0
    top: 0
    z-index: 1030
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1)
    // transition: top 0.3s
    
    .navbar
        width: 100%
        max-width: 1400px
        // padding-left: 50px
        padding: 0 50px
        background-color: #F8F8F8
        position: static
        .logoLink
            +size(auto, 100%)
            +flex_Center_Setting
            // max-width: 300px
            max-width: 300px
            min-height: 50px
            padding: 0
            img
                +size(100%)
            .mobileImg
                display: none
        &::before
            +pseudoBlock(100%, 100%, #F8F8F8)
            left: 0
            top: 0
            z-index: -1

    .navbarList
        +size(90%, auto)
        +flex_Center_Setting(space-between)
        // @include _under_1440_width()
        //     width: 95%
        // @include _under_1200_width
        //     width: 100%
        
        li.nav-item
            font-size: font(6)
            text-align: center
            flex-grow: 1
            height: 85px

            .toggle
                display: none

            & > .navLink_Setting
                +flex_Center_Setting
                +size(100%)
                padding: 0
                font-size: font(4)
                color: black
                line-height: normal
                border: 0px solid transparent

            &:hover
                background-color: #0b151d
                & > .navLink_Setting
                    color: white

        .nav-productMenu
            & > .navLink_Setting
                cursor: default
            // *
            //     border: 1px solid #fff
            .productMenu_Container
                +size(100%, auto)
                max-width: $max_Container_Width
                background-color: rgba(#0b151d, 0.9)
                padding: 40px
                position: absolute
                left: 50%
                top: 85px
                transform: translateX(-50%)
                animation: fadeIn 0.5s
                z-index: 10
                .row
                    align-items: flex-start
                    flex-direction: column
                    .productMenu
                        +flex_Center_Setting(space-between)
                        width: 100%
                        a
                            display: block
                        .menuProduct_Link_Title
                            position: relative
                            height: 100%
                            &::before
                                +pseudoBlock(0%, 4px, #60ade7)
                                left: 0
                                bottom: 0
                                transition: width 0.3s
                            &:hover::before
                                width: 100%
                        .productMenuList
                            +flex_Center_Setting(flex-start)
                            // flex-wrap: wrap
                            width: 85%
                            .menuProduct_Link
                                +flex_Center_Setting(space-between)
                                width: calc( (100% - 40px*2) / 3)
                                padding-right: 10px
                                border-bottom: 1px solid #fff
                                transition: padding-right 0.3s
                                margin-right: 40px
                                &:nth-child(3n)
                                    margin-right: 0
                                &:hover
                                    padding-right: 0
                                .b-icon
                                    +size(25px)
                        &:first-child ~ .productMenu
                            margin-top: 30px

        .languageDropdown_List
            width: 100%
            padding: 0
            min-width: auto
            & > .language_item
                font-size: font(6)
                text-align: center
                padding: 5px 0
                &:hover
                    background-color: $theme_color
                    color: white

    @include _under_992_width
        .navbar
            padding: 10px 4%
            .logoLink
                .logoImg
                    display: none
                .mobileImg
                    display: block
            
            // 摺疊選單展開時按鈕線條定位為XX叉叉
            button.navbar-toggler
                +size(40px)
                background-color: $theme_color
                padding: 0
                position: relative
                border: solid 1px #bbb
                outline: none
                .line
                    transition: 0.3s    // 切換class動畫0.3s
                    // -ms-width: 42.72px
                    +size(calc(20px * 1.41421), 3px)
                    background-color: #fff
                    position: absolute
                .line.line1
                    left: 50%
                    top: 50%
                    transform-origin: left
                    transform: rotate(45deg) translate(-50%, -50%)
                .line.line2
                    opacity: 0
                .line.line3
                    left: 50%
                    bottom: 50%
                    transform-origin: left
                    transform: rotate(-45deg) translate(-50%, -(3/2)+3+px)  // line線條高度3px，相等於：translate( -50%, calc(-50% + 3px) ) -->IE的calc不支援

            // 摺疊選單收合後按鈕線條定位為3條直線
            button.navbar-toggler.collapsed
                +flex_Center_Setting(space-around)
                flex-direction: column
                position: static
                padding: 5px 10px
                .line
                    width: 100%
                    opacity: 1
                    position: static
                    transform: rotate(0deg) translate(0)

            .navbarList
                width: 100%
                margin-left: 0
                display: block
                margin-top: 25px
                max-height: calc(100vh - 100px - 25px)  // 導覽列高100px，navbarList的margin-top: 25px
                overflow-y: scroll
                
                //--- 隱藏scrollbar ---
                &::-webkit-scrollbar    // Chrome、Edge、Safari
                    display: none
                scrollbar-width: none   // firefox
                -ms-overflow-style: none    // IE
                //--- 隱藏scrollbar ---
                
                li.nav-item
                    height: auto
                    text-align: left
                    position: relative
                    border-bottom: solid 1px #bbb
                    &:last-child
                        border-bottom: solid 0px
                    &:hover
                        background-color: transparent
                        & > .navLink_Setting
                            color: black
                    & > .navLink_Setting
                        justify-content: space-between
                        padding: 20px 40px

                    .toggle
                        +size(100%)
                        position: absolute
                        background-color: transparent
                        display: block

                .nav-item_FoldMenu
                    #products_List, #languageList
                        // margin-bottom: 30px
                        li.nav-item
                            border: solid 0px transparent
                            // &:first-child
                            //     margin-top: 0px
                            &:last-child
                                margin-bottom: 30px
                            .navLink_Setting
                                padding: 10px 85px
                                
                    .b-icon
                        transition: 0.3s
                    // 折疊的icon旋轉
                    .collapsed > .b-icon
                        transform: rotate(-180deg)
        
        // 摺疊選單展開後CSS
        .navbar.navbar_isExpand
            background-color: $theme_color
            .navbarList
                li.nav-item > .navLink_Setting
                    color: #fff
                .nav-item_FoldMenu
                    #products_List, #languageList
                        li.nav-item > .navLink_Setting
                            +flex_Center_Setting(space-between)
                            color: $title1_color
                            +arrowRight(35px, 8px)

    @include _under_768_width
        .navbar
            // .logoLink
                // max-width: 170px
            button#toggle_Button
                // +size(40px)
                .line
                    +size(calc(20px * 1.41421), 3px)
                &.collapsed > .line
                    width: 100%

    @include _under_576_width()
        .navbar
            // padding-top: 10px
            // padding-bottom: 10px
            .logoLink
                max-width: 250px
            // button.navbar-toggler
            //     +size(40px)
            .navbarList 
                li.nav-item > .navLink_Setting
                    font-size: 16px
                    padding: 15px 30px
                .nav-item_FoldMenu
                    #products_List, #languageList
                        li.nav-item > .navLink_Setting
                            padding: 10px 40px
    @include _under_375_width()
        .navbar
            .logoLink
                max-width: 220px
            button#toggle_Button
                +size(35px)
</style>