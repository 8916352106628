<template lang="pug">
main#NoSew
    pageTop(pageTitle="No Sew PU" imgSrc="Products/PU/NoSew/page top img_no sew_desktop.jpg" imgSrc_Mobile="Products/PU/NoSew/page top img_no sew_mobile.jpg")
    Product_Template_2(:importProduct_Data="NoSew_Data")

</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template_2 from "@/components/Product_Template_2.vue"

export default {
    metaInfo() {
        return{
            title: 'NoSew-',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "TPU hot melt film, Hot melt film are Environmental friendly, easy to process, worry-free HF processing"
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "TPU hot melt film, Hot melt film are Environmental friendly, easy to process, worry-free HF processing" },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "TPU hot melt film, Hot melt film are Environmental friendly, easy to process, worry-free HF processing" },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template_2
        // Button_Style
    },

    data() {
        return{
            NoSew_Data: {
                products_List: [
                    {
                        product_name: "TPU hot melt film",
                        swiper_title1: "TPU hot melt film",
                        swiper_title2: "Environmental friendly, easy to process, worry-free HF processing",
                        swiper_text: "Adjust the texture, color and composition according to customer applications and needs to reach customer target and optimize the final product.",
                        swiper_Img: [
                            "NoSew/TPU HOT MELT FILM products discription_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "Hot melt film",
                        swiper_title1: "Hot melt film",
                        swiper_title2: "Environmental friendly, easy to process",
                        swiper_text: "Application of Hot melt film is laminated with mesh by heat pressing, the thickness needs to be thinner, and the cloth shading can be displayed.",
                        swiper_Img: [
                            "NoSew/HOT MELT FILM products discription_deskstop.jpg",
                        ],
                    },
                ],

                specification_table_Data: [
                    {
                        thead: "Products",
                        td_Array: ["HOT MELT FILM", "TPU HOT \n MELT FILM"]
                    },
                    {
                        thead: "Feature",
                        td_Array: ["HEATING WELDING, \n ADHESIVE, NO-SEW APPLICATION", "HEATING WELDING, \n HEATING CUT,  NO-SEW APPLICATION"]
                    },
                    {
                        thead: "Cloth",
                        td_Array: ["HOT MELT", "TPU + HOT MELT"]
                    },
                    {
                        thead: "Application",
                        td_Array: ["SHOES/OTHERS", "SHOES/OTHERS"]
                    },
                    {
                        thead: "Specification",
                        td_Array: ['0.20-0.40m/m * 54"', '0.30-1.0m/m * 54"']
                    },
                    {
                        thead: "Unit",
                        td_Array: ["YARD", "YARD"]
                    },
                    {
                        thead: "Remark",
                        td_Array: ["MOQ 100Y", "MOQ 100Y"]
                    }
                ],
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

#NoSew
    *
        // border: 1px solid #000

    #Product_Template_2
        section.formatTable
            height: 250px
            
            ul
                &:nth-child(1), &:nth-child(3), &:nth-child(5)
                    flex: 2
                &:nth-child(2), &:nth-child(4)
                    flex: 3
                &:nth-child(6)
                    flex: 1
                &:nth-child(7)
                    flex: 1.5
                &:nth-child(1), &:nth-child(6), &:nth-child(7)
                    li
                        justify-content: center
                li.form_items
                    flex: 1
</style>        