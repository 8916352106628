<template lang="pug">
main#PU
    pageTop(pageTitle="PU" imgSrc="Products/PU/PU/page top img_PU_desktop.jpg" imgSrc_Mobile="Products/PU/PU/page top img_PU_mobile.jpg")
    Product_Template_2(:importProduct_Data="PU_Data")

</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template_2 from "@/components/Product_Template_2.vue"

export default {
    metaInfo() {
        return{
            title: 'PU-',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Double coating leather, Shifting effect leather, Two-tone leather, Printing leather, 4-way stretchable leather, Anti-bacterial leather, Microfriber, Prote PU, WPN PFCs free leather, PU skin"
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Double coating leather, Shifting effect leather, Two-tone leather, Printing leather, 4-way stretchable leather, Anti-bacterial leather, Microfriber, Prote PU, WPN PFCs free leather, PU skin" },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Double coating leather, Shifting effect leather, Two-tone leather, Printing leather, 4-way stretchable leather, Anti-bacterial leather, Microfriber, Prote PU, WPN PFCs free leather, PU skin" },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template_2
        // Button_Style
    },

    data() {
        return{
            PU_Data: {
                products_List: [
                    {
                        product_name: "Double coating leather",
                        swiper_title1: "Double coating leather",
                        swiper_title2: "Unique Camouflage Image Design",
                        swiper_text: "Camouflage printing design to increase product specificity.",
                        swiper_Img: [
                            "PU/DOUBLE COATING PU products discription1_deskstop.jpg",
                            "PU/DOUBLE COATING PU products discription2_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "Shifting effect leather",
                        swiper_title1: "Shifting effect leather",
                        swiper_title2: "Chrome design material, outstanding feature",
                        swiper_text: "Chrome design, catch fashion, creating product value.",
                        swiper_Img: [
                            "PU/SHIFTING EFFECT PU products discription1_deskstop.jpg",
                            "PU/SHIFTING EFFECT PU products discription2_deskstop.jpg",
                            "PU/SHIFTING EFFECT PU products discription3_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "Two-tone leather",
                        swiper_title1: "Two-tone leather",
                        swiper_title2: "Two-Tone design, fashional  performance",
                        swiper_text: "By production technology to present two-color effect, and meets fashion needs",
                        swiper_Img: [
                            "PU/TWO-TONE PU products discription_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "Printing leather",
                        swiper_title1: "Printing leather",
                        swiper_title2: "Geometry stacked Image",
                        swiper_text: "Special ridge pattern, creating stacking impression, providing the best visual change",
                        swiper_Img: [
                            "PU/PRINTING PU products discription_deskstop1.jpg",
                            "PU/PRINTING PU products discription_deskstop2.jpg",
                        ],
                    },
                    {
                        product_name: "4-way stretchable leather",
                        swiper_title1: "4-way stretchable leather",
                        swiper_title2: "4-Way stretchable ",
                        swiper_text: "By using unique fabric, enhance stretchable feature for various applications.",
                        swiper_Img: [
                            "PU/4-WAY STRETCHABLE PU products discription1_deskstop.jpg",
                            "PU/4-WAY STRETCHABLE PU products discription2_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "Anti-bacterial leather",
                        swiper_title1: "Anti-bacterial leather",
                        swiper_title2: "Clean, Safe new Lifestyle",
                        swiper_text: "Providing efficient and durable anti Anti-Bacterial performance.",
                        swiper_Img: [
                            "PU/ANTI-BACTERIAL PU products discription1_deskstop.jpg",
                            "PU/PU products discription2_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "Microfriber",
                        swiper_title1: "Microfriber",
                        swiper_title2: "Leather -Like,  rich volumn",
                        swiper_text: "Soft and rich touch, best replacement for leather.",
                        swiper_Img: [
                            "PU/MICROFIBER PU products discription_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "Prote PU",
                        swiper_title1: "Prote PU",
                        swiper_title2: "Green to Earth, Low carbon footprint",
                        swiper_text: "Low carbon footprint material, less solvent formula process.",
                        swiper_Img: [
                            "PU/PROTE PU products discription1_deskstop.jpg",
                            "PU/PROTE PU products discription2_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "WPN PFCs free leather",
                        swiper_title1: "WPN PFCs free leather",
                        swiper_title2: "Low-wicking design, PFCs Free type",
                        swiper_text: "Comply with update AFIRM guldeline.Reduce the use and impact of harmful substances in the footwear supply chain.",
                        swiper_Img: [
                            "PU/WPN PFCs FREE PU products discription1_deskstop.jpg",
                            "PU/WPN PFCs FREE PU products discription2_deskstop.jpg",
                            "PU/WPN PFCs FREE PU products discription3_deskstop.jpg",
                        ],
                    },
                    {
                        product_name: "PU skin",
                        swiper_title1: "PU skin",
                        swiper_title2: "No-Substrate material, for laminating use",
                        swiper_text: "No-substrate product, for numberious liminating applications.",
                        swiper_Img: [
                            "PU/PU SKIN products discription1_deskstop.jpg",
                            "PU/PU SKIN products discription2_deskstop.jpg",
                        ],
                    },
                    // {
                    //     product_name: "Eco PU",
                    //     swiper_title1: "Eco PU",
                    //     swiper_title2: "Unique Camouflage Image Design",
                    //     swiper_text: "Camouflage printing design to increase product specificity.",
                    //     swiper_Img: [
                    //         "PU/ANTI-BACTERIAL PU products discription1_deskstop.jpg",
                    //         "PU/ANTI-BACTERIAL PU products discription1_deskstop.jpg",
                    //         "PU/ANTI-BACTERIAL PU products discription1_deskstop.jpg",
                    //         "PU/ANTI-BACTERIAL PU products discription1_deskstop.jpg",
                    //     ],
                    // },
                ],

                specification_table_Data: [
                    {
                        thead: "Products",
                        td_Array: ["PU coating on \n Non-woven", "PU coating on \n woven", "PU coating on \n Knit", "Microfriber", "PU skin"]
                    },
                    {
                        thead: "Feature",
                        td_Array: ["ESTER AND ETHER TYPE", "ETHER (ANTI-HYDROLYSIS) \n ANTI-BACTERIAL", "ESTER (HYDROLYSIS) \n ANTI-BACTERIAL", 
                            "ESTER/ETHER TYPE STRETCABLE, RECYCLE POLYESTER FIBER \n (0.3~0.8mm) ", "LEATHER-LIKE, RICH TOUCH", "PU FILM, NO SUBSTRATE"]
                    },
                    {
                        thead: "Cloth",
                        td_Array: ["POLY ESTER", "P/N 70/30", "P/N 50/50", "P/N 15/85", "ER/ER 80/20", "ER/ER 60/40", "EC/EC 42/38", 
                            "ER/C 50/60", "E 40D", "E 75D", "E 100D", "N+SPANDEX 40D", "N 70D", "0.001D", "PU"]
                    },
                    {
                        thead: "Application",
                        td_Array: ["SHOES", "SHOES/WATER PROOF SHOES", "SHOES", "SHOES", "SHOES", "SHOES/BAG/FURNITURE", "CLOTHES", 
                            "SHOES/CLOTHES", "WATER PROOF \n SHOES/CLOTHES", "SHOES/CLOTHES/GLOVES", "SHOES/OTHERS", "SHOES/CLOTHES", "SHOES", "SHOES"]
                    },
                    {
                        thead: "Specification",
                        td_Array: ['1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', 
                            '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', 
                            '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"', '1.1~1.3MM * 54"']
                    },
                    {
                        thead: "Unit",
                        td_Array: ["YARD", "YARD", "YARD", "YARD", "YARD"]
                    },
                    {
                        thead: "Remark",
                        td_Array: ["MOQ 100Y", "MOQ 100Y", "MOQ 100Y", "MOQ 100Y", "MOQ 100Y"]
                    }
                ],
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },

    // created() {
    //     console.log(this.$route.params.PU_index);
    // }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

main#PU 
    *
        // border: 1px solid #000

    // 垂直分配共19份
    #Product_Template_2
        section.formatTable
            height: 550px
            
            // Products
            ul:nth-child(1)
                flex: 2
                li.form_items
                    justify-content: center
                    &:nth-child(2)
                        flex: 5
                    &:nth-child(3)
                        flex: 5
                    &:nth-child(4)
                        flex: 7
                    &:nth-child(4) ~ li.form_items
                        flex: 1
            // Feature
            ul:nth-child(2)
                flex: 3
                li.form_items
                    &:nth-child(2)
                        flex: 5
                    &:nth-child(3)
                        flex: 2
                    &:nth-child(4)
                        flex: 3
                    &:nth-child(5)
                        flex: 7
                    &:nth-child(5) ~ li.form_items
                        flex: 1
            // Cloth
            ul:nth-child(3)
                flex: 2
                li.form_items
                    flex: 1
                    &:nth-child(2)
                        flex: 2
                    &:nth-child(6)
                        flex: 2
                    &:nth-child(12)
                        flex: 3
            // Application
            ul:nth-child(4)
                flex: 3
                li.form_items
                    flex: 1
                    &:nth-child(7)
                        flex: 5
                    &:nth-child(10)
                        flex: 2
            // Specification
            ul:nth-child(5)
                flex: 2
                li.form_items
                    flex: 1
                    &:nth-child(7), &:nth-child(13)
                        flex: 2
            // Unit
            ul:nth-child(6)
                flex: 1
                li.form_items
                    flex: 1
                    justify-content: center
                    &:nth-child(2), &:nth-child(3)
                        flex: 5
                    &:nth-child(4)
                        flex: 7
            // Remark
            ul:nth-child(7)
                flex: 1.5
                li.form_items
                    flex: 1
                    justify-content: center
                    &:nth-child(2), &:nth-child(3)
                        flex: 5
                    &:nth-child(4)
                        flex: 7
</style>        