import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import i18n from '../languages/vue-i18n'
Vue.use(VueRouter)

import Home from '@/views/Home.vue'
import Company from '@/views/Company.vue'
import Quality from '@/views/Quality.vue'
import Contact from '@/views/Contact.vue'

import PU_Leather from '@/views/PU_Leather.vue'
import PVC_Film from '@/views/PVC_Film.vue'

import Tape_PVC_Sheet from '@/views/PVC/Tape_PVC_Sheet.vue'
import Semi_Rigid_Film from '@/views/PVC/Semi_Rigid_Film.vue'
import Clear_Film from '@/views/PVC/Clear_Film.vue'
import PU from '@/views/PU/PU.vue'
import No_Sew from '@/views/PU/No_Sew.vue'

const defaultLanguage = store.state.defaultLanguage

const routes = [
    {path: '*', redirect: '/' },
    // {path: '/', name: 'Home', component: Home},
    // {path: '/Company', name: 'Company', component: Company},
    // {path: '/Quality', name: 'Quality', component: Quality},
    // {path: '/Contact', name: 'Contact', component: Contact},

    // {path: '/PU_Leather', name: 'PU_Leather', component: PU_Leather},
    // {path: '/PVC_Film', name: 'PVC_Film', component: PVC_Film},

    // {path: '/PVC_Film/Tape_PVC_Sheet', name: 'Tape_PVC_Sheet', component: Tape_PVC_Sheet},
    // {path: '/PVC_Film/Semi_Rigid_Film', name: 'Semi_Rigid_Film', component: Semi_Rigid_Film},
    // {path: '/PVC_Film/Clear_Film', name: 'Clear_Film', component: Clear_Film},
    // {path: '/PU_Leather/PU', name: 'PU', component: PU},
    // {path: '/PU_Leather/No_Sew', name: 'NoSew', component: NoSew},

    // 巢狀Nested Routes寫法(2021/10/28改寫)
    {
        path: '/',
        component: { render: h => h('router-view') },   //  每一個子節點都要return回自己的router-view
        beforeEnter: (to, from, next) => {
            store.commit('changeLanguage_mutationsVuex', defaultLanguage)
            i18n.locale = defaultLanguage
            return next()
        },
        children: [
            {path: '', name: 'Home', component: Home},
            {path: 'Company', name: 'Company', component: Company, 
                meta: {
                    breadcrumb: [
                        {text: 'Company', to: { name: 'Company'}}
                    ]
                },
            },
            {path: 'Quality', name: 'Quality', component: Quality,
                meta: {
                    breadcrumb: [
                        {text: 'Quality', to: { name: 'Quality'}}
                    ]
                },
            },
            {path: 'Contact', name: 'Contact', component: Contact,
                meta: {
                    breadcrumb: [
                        {text: 'Contact', to: { name: 'Contact'}}
                    ]
                },
            },
            
            // PVC_Film
            {
                path: 'PVC_Film',
                component: { render: h => h('router-view') },   // 子頁節點return回自己的router-view
                children: [
                    {
                        path: '', name: 'PVC_Film', component: PVC_Film,
                        meta: {
                            breadcrumb: [
                                {text: 'PVC Film', to: { name: 'PVC_Film'}}
                            ]
                        },
                    },
                    {path: 'Tape_PVC_Sheet', name: 'Tape_PVC_Sheet', component: Tape_PVC_Sheet,
                        meta: {
                            breadcrumb: [
                                {text: 'PVC Film', to: { name: 'PVC_Film'}},
                                {text: 'Tape PVC Sheet', to: { name: 'Tape_PVC_Sheet'}}
                            ]
                        },
                    },
                    {path: 'Semi_Rigid_Film', name: 'Semi_Rigid_Film', component: Semi_Rigid_Film,
                        meta: {
                            breadcrumb: [
                                {text: 'PVC Film', to: { name: 'PVC_Film'}},
                                {text: 'Semi Rigid Film', to: { name: 'Semi_Rigid_Film'}}
                            ]
                        },
                    },
                    {path: 'Clear_Film', name: 'Clear_Film', component: Clear_Film,
                        meta: {
                            breadcrumb: [
                                {text: 'PVC Film', to: { name: 'PVC_Film'}},
                                {text: 'Clear Film', to: { name: 'Clear_Film'}}
                            ]
                        },
                    },
                ]
            },
            
            // PU_Leather
            {
                path: 'PU_Leather',
                component: { render: h => h('router-view') },   // 子頁節點return回自己的router-view
                children: [
                    {path: '', name: 'PU_Leather', component: PU_Leather,
                        meta: {
                            breadcrumb: [
                                {text: 'PU Leather', to: { name: 'PU_Leather'}}
                            ]
                        },
                    },
                    {path: 'PU', name: 'PU', component: PU,
                        meta: {
                            breadcrumb: [
                                {text: 'PU Leather', to: { name: 'PU_Leather'}},
                                {text: 'PU', to: { name: 'PU'}}
                            ]
                        },
                    },
                    {path: 'No_Sew', name: 'No_Sew', component: No_Sew,
                        meta: {
                            breadcrumb: [
                                {text: 'PU Leather', to: { name: 'PU_Leather'}},
                                {text: 'No Sew', to: { name: 'No_Sew'}}
                            ]
                        },
                    },
                ]
            },
        ]
    },
// ====================================================================================================
    // 其他語系router
    {
        path: '/:lang',
        component: { render: h => h('router-view') },   //  每一個子節點都要return回自己的router-view
        beforeEnter: (to, from, next) => {
            const langUrl = to.params.lang
            const languageList = store.state.languageList
            
            // URL有出現在語系Array中，則置換 i18n 語言
            if(languageList.includes(langUrl)){
                store.commit('changeLanguage_mutationsVuex', langUrl)
                i18n.locale = langUrl
            }
            // 預設引導至預設英文語系
            else {
                // router.push({ path: '/'})
                store.commit('changeLanguage_mutationsVuex', defaultLanguage)
                i18n.locale = defaultLanguage
            }
            return next()
        },
        children: [
            {path: '/', name: 'Home_lang', component: Home},
            {path: 'Company', name: 'Company_lang', component: Company,
                meta: {
                    breadcrumb: [
                        {text: 'Company', to: { name: 'Company_lang'}}
                    ]
                },
            },
            {path: 'Quality', name: 'Quality_lang', component: Quality,
                meta: {
                    breadcrumb: [
                        {text: 'Quality', to: { name: 'Quality_lang'}}
                    ]
                },
            },
            {path: 'Contact', name: 'Contact_lang', component: Contact,
                meta: {
                    breadcrumb: [
                        {text: 'Contact', to: { name: 'Contact_lang'}}
                    ]
                },
            },
            
            // PVC_Film
            {
                path: '/:lang/PVC_Film',
                component: { render: h => h('router-view') },   // 子頁節點return回自己的router-view
                children: [
                    {path: '/', name: 'PVC_Film_lang', component: PVC_Film,
                        meta: {
                            breadcrumb: [
                                {text: 'PVC Film', to: { name: 'PVC_Film_lang'}}
                            ]
                        },
                    },
                    {path: 'Tape_PVC_Sheet', name: 'Tape_PVC_Sheet_lang', component: Tape_PVC_Sheet,
                    meta: {
                        breadcrumb: [
                            {text: 'PVC Film', to: { name: 'PVC_Film_lang'}},
                            {text: 'Tape PVC Sheet', to: { name: 'Tape_PVC_Sheet_lang'}}
                        ]
                    },
                },
                    {path: 'Semi_Rigid_Film', name: 'Semi_Rigid_Film_lang', component: Semi_Rigid_Film,
                        meta: {
                            breadcrumb: [
                                {text: 'PVC Film', to: { name: 'PVC_Film_lang'}},
                                {text: 'Semi Rigid Film', to: { name: 'Semi_Rigid_Film_lang'}}
                            ]
                        },
                    },
                    {path: 'Clear_Film', name: 'Clear_Film_lang', component: Clear_Film,
                        meta: {
                            breadcrumb: [
                                {text: 'PVC Film', to: { name: 'PVC_Film_lang'}},
                                {text: 'Clear Film', to: { name: 'Clear_Film_lang'}}
                            ]
                        },
                    },
                ]
            },
            
            // PU_Leather
            {
                path: '/:lang/PU_Leather',
                component: { render: h => h('router-view') },   // 子頁節點return回自己的router-view
                children: [
                    {path: '/', name: 'PU_Leather_lang', component: PU_Leather,
                        meta: {
                            breadcrumb: [
                                {text: 'PU Leather', to: { name: 'PU_Leather_lang'}}
                            ]
                        },
                    },
                    {path: 'PU', name: 'PU_lang', component: PU,
                        meta: {
                            breadcrumb: [
                                {text: 'PU Leather', to: { name: 'PU_Leather_lang'}},
                                {text: 'PU', to: { name: 'PU_lang'}}
                            ]
                        },
                    },
                    {path: 'No_Sew', name: 'No_Sew_lang', component: No_Sew,
                        meta: {
                            breadcrumb: [
                                {text: 'PU Leather', to: { name: 'PU_Leather_lang'}},
                                {text: 'No Sew', to: { name: 'No_Sew_lang'}}
                            ]
                        },
                    },
                ]
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,

    // Router切換，滾動到畫面最上方
    scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    }
    else {
        // return { x: 0, y: 0 }
        return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                }, 300)
            })
        }
    }
})

export default router