<template lang="pug">
#Tape_PVC_Sheet
    pageTop(pageTitle="Tape PVC Sheet" imgSrc="Products/PVC/Tape_PVC_Sheet/page top img_tape_desktop.jpg" imgSrc_Mobile="Products/PVC/Tape_PVC_Sheet/page top img_tape_desktop.jpg")
    Product_Template_1(:importProduct_Data="Tape_PVC_Sheet_Data")

</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template_1 from "@/components/Product_Template_1.vue"

export default {
    metaInfo() {
        return{
            title: 'Tape PVC Sheet-',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Excellent electrical insulation and resistance to chemicals. Available in various colors for identification and purposes."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Excellent electrical insulation and resistance to chemicals. Available in various colors for identification and purposes." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Excellent electrical insulation and resistance to chemicals. Available in various colors for identification and purposes." },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template_1
        // Button_Style
    },

    data() {
        return{
            Tape_PVC_Sheet_Data: {
                introduce: {
                    title: "Tape PVC Sheet",
                    introText1: "Excellent electrical insulation and resistance to chemicals. Available in various colors for identification and purposes.",
                    introText2: "Stable physical and chemical properties provide excellent strength, toughness and weather resistance. \
                                Resists corrosion at high temperature and offers great electrical insulation, \
                                dielectric, and flame-retardant properties.",
                    swiper_Img: [
                        "Products/PVC/Tape_PVC_Sheet/products discription_tape PVC1.jpg",
                        "Products/PVC/Tape_PVC_Sheet/products discription_tape PVC2.jpg",
                    ],
                    featureList: [
                        "Provides high voltage resistance with low flammability.",
                        "High flexibility, waterproof, acid and alkaline resistance, corrosion resistance, and weather resistance.",
                        "Low toxicity and low heavy metal content can be controlled under customer’s requirement.",
                        "Available in multiple colors and customized dimensions.",
                    ]
                },
                productApp: {
                    appText: "Insulation tape, packaging tape, barrier warning tape, and automotive wire harnesses tape.",
                    circle_Img:[
                        {
                            imgSrc: "Products/PVC/Tape_PVC_Sheet/products application_Tape PVC1.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Tape_PVC_Sheet/products application_Tape PVC2.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Tape_PVC_Sheet/products application_Tape PVC3.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Tape_PVC_Sheet/products application_Tape PVC4.jpg",
                            altText: ""
                        }
                    ]
                },

                specification_table_Data: [
                    {
                        thead: "Thickness",
                        td_Array: ["0.07~0.50mm"]
                    },
                    {
                        thead: "Width",
                        td_Array: ["54”(max)"]
                    },
                    {
                        thead: "Put up",
                        td_Array: ["500yard(min)"]
                    },
                    {
                        thead: "PHR",
                        td_Array: ["32~58PHR"]
                    }
                ],

                Catalogue_PDF_file: [
                    {
                        fileName: "Tape PVC sheet",
                        fileSrc: "Tape PVC sheet.pdf"
                    },
                    // {
                    //     fileName: "2021 Funiture",
                    //     fileSrc: "PU_ISO9001.pdf"
                    // },
                ]
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

</style>        