<template lang="pug">
#Clear_Film
    pageTop(pageTitle="Clear Film" imgSrc="Products/PVC/Clear PVC Film/page top img_clear_desktop.jpg" imgSrc_Mobile="Products/PVC/Clear PVC Film/page top img_clear_mobile.jpg")
    Product_Template_1(:importProduct_Data="Clear_Film_Data")

</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template_1 from "@/components/Product_Template_1.vue"

export default {
    metaInfo() {
        return{
            title: 'Clear Film-',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Covering every range in life which applicable to everything for both outdoor and indoor use."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Covering every range in life which applicable to everything for both outdoor and indoor use." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Covering every range in life which applicable to everything for both outdoor and indoor use." },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template_1
        // Button_Style
    },

    data() {
        return{
            Clear_Film_Data: {
                introduce: {
                    title: "Clear PVC Film",
                    introText1: "Covering every range in life which applicable to everything for both outdoor and indoor use.",
                    introText2: "Flow-mark-free, high-gloss, with a Gloss Value of 110 or more. Comply with EU REACH, RoHS, the U.S. CPSIA, and other environmental regulations. The patented process can manufacture exceptional gloss on double-polishe), suitable for screen /off-set printing, advertising, and vehicle wraps.",
                    swiper_Img: [
                        "Products/PVC/Clear PVC Film/products discription_Clear PVC Film1.jpg",
                        "Products/PVC/Clear PVC Film/products discription_Clear PVC Film2.jpg",
                    ],
                    featureList: [
                        "Comply with RoHS and REACH regulations if required.",
                        "High transparency, flow-mark-free and good lay flat.",
                        "Special requirements are available, such as flame-resistant, anti-mildew, non-phthalate, weather-resistant, etc.",
                        "Suitable for high-frequency welding, sewing, printing, and other processing.",
                    ]
                },
                productApp: {
                    appText: "Stationery, packaging, advertising & graphics, window graphics, window advertising, window clings, static clings, self-adhesive trademark stickers, logo decals",
                    circle_Img:[
                        {
                            imgSrc: "Products/PVC/Clear PVC Film/products application_Clear PVC1.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Clear PVC Film/products application_Clear PVC2.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Clear PVC Film/products application_Clear PVC3.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Clear PVC Film/products application_Clear PVC4.jpg",
                            altText: ""
                        }
                    ]
                },

                specification_table_Data: [
                    {
                        thead: "Application",
                        td_Array: ["Interior finishes", "Graphic"]
                    },
                    {
                        thead: "Thickness",
                        td_Array: ["0.07~0.20mm", "0.08~0.50mm"]
                    },
                    {
                        thead: "Width",
                        td_Array: ["13~56", "18~74"]
                    },
                    {
                        thead: "Put up",
                        td_Array: ["500~2,000yard", "100~600yard"]
                    },
                    {
                        thead: "PHR",
                        td_Array: ["3~29PHR", "21~29PHR"]
                    }
                ],

                Catalogue_PDF_file: [
                    {
                        fileName: "Clear PVC film",
                        fileSrc: "Clear PVC film.pdf"
                    },
                    // {
                    //     fileName: "2021 Funiture",
                    //     fileSrc: "PU_ISO9001.pdf"
                    // },
                ]
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

</style>        