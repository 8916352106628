<template lang="pug">
article#Home_our_Products
    h1 Our Products
    .productsButton_Container
        button.products_Category_Button.PVC_button(v-scroll-to=`"#PVC_Film_Container"`)
            h2.products_Category_Button_Text PVC Film
            b-icon(icon="caret-down-fill")
        button.products_Category_Button.PU_button(v-scroll-to=`"#PU_Container"`)
            h2.products_Category_Button_Text PU Leather
            b-icon(icon="caret-down-fill")
    //- PVC區塊
    section#PVC_Film_Container
        section.item_Group
            article.textGroup
                h2.Container_Text PVC Film
                p.Container_Text PVC film is an excellent choices when versatility and value are a must, which is quite economical and highly durable – two of the many reasons.
            swiper.our_Products_Swiper(:options='PVC_Swiper')
                swiper-slide(v-for="item of PVC_Swiper_Data" :key="item.Product_Name")
                    router-link.products_Link(:to="{name: item.href}")
                        figure
                            img.deskTop_Img(:src="getImgUrl(`Home_PVC/${item.imgSrc}`)", :alt="item.Product_Name")
                            img.mobile_Img(:src="getImgUrl(`Home_PVC/${item.imgSrc_mobile}`)", :alt="item.Product_Name")
                            figcaption {{item.Product_Name}}
                        .viewmore_Block
                            span.viewmoreText View more
                            b-icon.icon(icon="chevron-right")
                //- .PVC_Swiper-pagination(slot="pagination")
                //- .PVC_swiper-button-prev(slot='button-prev')
                //-     b-icon.icon(icon="chevron-left")
                //- .PVC_swiper-button-next(slot='button-next')
                //-     b-icon.icon(icon="chevron-right")
            Button_Style.button_more(btnTitle="All PVC products" linkType="routerName" link="PVC_Film" arrow="right")
    //- PU區塊
    section#PU_Container
        section.item_Group
            article.textGroup
                h2.Container_Text PU Leather
                p.Container_Text The main applications of PU artificial leather and Nosew are for middle and high-end shoes, furniture, gloves, luggage, clothing, etc.
            section.PU_button_Group
                button.PU_button(:class="{PU_button_Show: PU_isActive}" @click="PU_change") PU
                button.PU_button(:class="{PU_button_Show: NoSew_isActive}" @click="NoSew_change") No Sew
            //- PU的Swiper
            section(v-show="PU_isActive")
                swiper.our_Products_Swiper(:options='PU_Swiper')
                    swiper-slide(v-for="item of PU_Swiper_Data" :key="item.Product_Name")
                        //- 傳送指定產品index sort
                        router-link.products_Link(:to="{name: 'PU'}" @click.native="mutations_product_Display_index(item.sort)")
                            figure
                                img.deskTop_Img(:src="getImgUrl(`Home_PU/${item.imgSrc}`)", :alt="item.Product_Name")
                                img.mobile_Img(:src="getImgUrl(`Home_PU/${item.imgSrc_mobile}`)", :alt="item.Product_Name")
                                figcaption  {{item.Product_Name}}
                            .viewmore_Block
                                span.viewmoreText View more
                                b-icon.icon(icon="chevron-right")
                    .PU_Swiper-pagination(slot="pagination")
                    .PU_swiper-button-prev(slot='button-prev')
                        b-icon.icon(icon="chevron-left")
                    .PU_swiper-button-next(slot='button-next')
                        b-icon.icon(icon="chevron-right")
            //- NoSew的Swiper
            section(v-show="NoSew_isActive")
                swiper.our_Products_Swiper(:options='NoSew_Swiper')
                    swiper-slide(v-for="item of NoSew_Swiper_Data" :key="item.Product_Name")
                        //- 傳送指定產品index sort
                        router-link.products_Link(:to="{name: 'No_Sew'}" @click.native="mutations_product_Display_index(item.sort)")
                            figure
                                img.deskTop_Img(:src="getImgUrl(`Home_PU/${item.imgSrc}`)", :alt="item.Product_Name")
                                img.mobile_Img(:src="getImgUrl(`Home_PU/${item.imgSrc_mobile}`)", :alt="item.Product_Name")
                                figcaption {{item.Product_Name}}
                            .viewmore_Block
                                span.viewmoreText View more
                                b-icon.icon(icon="chevron-right")
                    .NoSew_Swiper-pagination(slot="pagination")
                    //- .NoSew_swiper-button-prev(slot='button-prev')
                        b-icon.icon(icon="chevron-left")
                    //- .NoSew_swiper-button-next(slot='button-next')
                        b-icon.icon(icon="chevron-right")
            Button_Style.button_more(btnTitle="All PU products" linkType="routerName" link="PU_Leather" arrow="right")

</template>

<script>
import cardBody from "@/components/cardBody.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {

    components: {
        cardBody,
        Button_Style
    },

    data() {
        return{
            PVC_Swiper: {
                spaceBetween: 50,
                effect: 'slide',
                // observeParents: true,
                // observer: true,
                pagination: {
                    el: '.PVC_Swiper-pagination',
                    type: 'fraction',
                    // clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.PVC_swiper-button-prev',
                    nextEl: '.PVC_swiper-button-next'
                },
                // autoplay: {delay: 5000},   // 輪播
                speed: 300,   // 切換速度
                loop: false,
                // autoHeight: true,
                slidesPerGroup: 3,
                slidesPerView: 3,
                breakpoints: {
                    992: { slidesPerGroup: 2, slidesPerView: 2.5},
                    // 768: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 20},
                    576: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 20},
                },
            },
            PU_Swiper: {
                spaceBetween: 50,
                effect: 'slide',
                pagination: {
                    el: '.PU_Swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    prevEl: '.PU_swiper-button-prev',
                    nextEl: '.PU_swiper-button-next'
                },
                speed: 300,   // 切換速度
                loop: false,
                slidesPerGroup: 3,
                slidesPerView: 3,
                breakpoints: {
                    992: { slidesPerGroup: 2, slidesPerView: 2.5},
                    // 768: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 20},
                    576: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 20},
                },
            },
            NoSew_Swiper: {
                spaceBetween: 50,
                effect: 'slide',
                pagination: {
                    el: '.NoSew_Swiper-pagination',
                    type: 'fraction',
                    // clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.NoSew_swiper-button-prev',
                    nextEl: '.NoSew_swiper-button-next'
                },
                speed: 300,   // 切換速度
                loop: false,
                slidesPerGroup: 3,
                slidesPerView: 3,
                breakpoints: {
                    992: { slidesPerGroup: 2, slidesPerView: 2.5},
                    // 768: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 20},
                    576: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 20},
                },
            },
            PU_isActive: true,
            NoSew_isActive: false,

            PVC_Swiper_Data: [
                {
                    Product_Name: "Tape PVC Sheet",
                    imgSrc: "PRODUCT CARD_tape_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_tape_mobile.jpg",
                    href: "Tape_PVC_Sheet"
                },
                {
                    Product_Name: " Semi-rigid film",
                    imgSrc: "PRODUCT CARD_semi grid_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_semi grid_mobile.jpg",
                    href: "Semi_Rigid_Film"
                },
                {
                    Product_Name: " Clear Film",
                    imgSrc: "PRODUCT CARD_clear_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_clear_mobile.jpg",
                    href: "Clear_Film"
                },
            ],
            PU_Swiper_Data: [
                {
                    sort: 0,
                    Product_Name: "Double coating leather",
                    imgSrc: "PRODUCT CARD_DOUBLE COATING_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_DOUBLE COATING_mobile.jpg",
                },
                {
                    sort: 1,
                    Product_Name: "Shifting effect leather",
                    imgSrc: "PRODUCT CARD_ SHIFTING EFFECT LEATHER _deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_ SHIFTING EFFECT LEATHER_mobile.jpg",
                },
                {
                    sort: 2,
                    Product_Name: "Two-tone leather",
                    imgSrc: "PRODUCT CARD_ TWO-TONE LEATHER_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_ TWO-TONE LEATHER_mobile.jpg",
                },
                {
                    sort: 3,
                    Product_Name: "Printing leather",
                    imgSrc: "PRODUCT CARD_ PRINTING LEATHER_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_ PRINTING LEATHER_mobile.jpg",
                },
                {
                    sort: 4,
                    Product_Name: "4-way Stretchable Leather",
                    imgSrc: "PRODUCT CARD_4-WAY STRETCHABLE LEATHER_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_4-WAY STRETCHABLE LEATHER_mobile.jpg",
                },
                {
                    sort: 5,
                    Product_Name: "Anti-bacterial Leather",
                    imgSrc: "PRODUCT CARD_ANTI-BACTERIAL PU_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_ANTI-BACTERIAL PU_mobile.jpg",
                },
                {
                    sort: 6,
                    Product_Name: "Microfriber",
                    imgSrc: "PRODUCT CARD_MICROFIBER _deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_MICROFIBER _mobile.jpg",
                },
                {
                    sort: 7,
                    Product_Name: "Prote PU",
                    imgSrc: "PRODUCT CARD_PROTE PU_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_PROTE PU_mobile.jpg",
                },
                {
                    sort: 8,
                    Product_Name: "WPN PFCs free leather",
                    imgSrc: "PRODUCT CARD_WPN PFCs FREE LEATHER_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_WPN PFCs FREE LEATHER_mobile.jpg",
                },
                {
                    sort: 9,
                    Product_Name: "PU Skin",
                    imgSrc: "PRODUCT CARD_PU SKIN_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_PU SKIN_mobile.jpg",
                },
            ],
            NoSew_Swiper_Data: [
                {
                    sort: 0,
                    Product_Name: "TPU hot melt film",
                    imgSrc: "PRODUCT CARD_TPU HOT MELT FILM_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_TPU HOT MELT FILM_mobile.jpg",
                },
                {
                    sort: 1,
                    Product_Name: "Hot melt film",
                    imgSrc: "PRODUCT CARD_HOT MELT FILM_deskstop.jpg",
                    imgSrc_mobile: "PRODUCT CARD_HOT MELT FILM_mobile.jpg",
                },
            ]
        }
    },

    // computed: {},

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
        PU_change() {
            this.PU_isActive = true,
            this.NoSew_isActive = false
        },
        NoSew_change() {
            this.PU_isActive = false,
            this.NoSew_isActive = true
        },
        mutations_product_Display_index(input_index) {
            this.$store.commit('change_product_Display_index', input_index);
        }
    },

    // mounted() {},

    // created() {}
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#Home_our_Products
    +size(100%, auto)
    margin: 120px auto 0 auto
    // *
    //     border: 1px solid #000
    h1
        +text_Setting(42px, normal, 1.1px, $title2_color, 600, "Noto Sans", center)
    h2.products_Category_Button_Text
        +text_Setting(28px, 1, 2.1px, #fff, 400, "Noto Sans", center)
    h2.Container_Text
        +text_Setting(36px, 1, 2px, #000, 600, "Noto Sans", center)
    p.Container_Text
        +text_Setting(20px, 1.5, 0.22px, #495057, 400, "Noto Sans", center)
    span.viewmoreText
        +text_Setting(20px, 1, 1.4px, #fff, 400, "Noto Sans", center)
    span.swiper-pagination-current
        +text_Setting(22px, normal, 2.1px, #443e3e, 600, "Noto Sans", center)
    span.swiper-pagination-total
        +text_Setting(22px, normal, 2.1px, #c8c8c8, 400, "Noto Sans", center)
    figcaption
        +text_Setting(20px, 1, 2.1px, #fff, 400, "Noto Sans", center)
    button.PU_button
        +text_Setting(24px, 1, 2.1px, #c8c8c8, 400, "Noto Sans", center)
    img
        +size(100%)
        // box-shadow: 5px 5px 10px 0 rgba(73, 80, 87, 0.5)
    @include _under_768_width()
        h2.products_Category_Button_Text
            font-size: 22px
        span.viewmoreText
            font-size: 16px
        figcaption
            font-size: 16px
            letter-spacing: 1px
    @include _under_576_width()
        h1
            font-size: 32px
        h2.Container_Text
            font-size: 28px
        p.Container_Text
            font-size: 16px
        button.PU_button
            font-size: 20px


    .productsButton_Container
        +flex_Center_Setting(space-between)
        width: $max_Width_Percent
        max-width: $max_Container_Width - 400px
        margin: 45px auto 0

        button.products_Category_Button
            +flex_Center_Setting
            width: calc(50% - 12px)
            padding: 15px 0
            box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16)
            border: solid 2px #fafafa
            border-radius: 4px
            transition: border 0.5s
            position: relative
            .b-icon
                +size(30px)
                margin-left: 30px
                color: #fff
            &::before
                +pseudoBlock(100%, 100%)
                left: 0
                top: 0
                z-index: -1
                transition: 0.3s
        .PVC_button
            &::before
                background-image: linear-gradient(to left, #d4de17 -40%, #d4de17 -26%, #2edbef 111%)
            &:hover 
                border: 2px solid $PVC_title_color
                h2, .b-icon
                    color: $PVC_title_color
                &::before
                    opacity: 0
        .PU_button
            &::before
                background-image: linear-gradient(to right, #fdc215 5%, #fdac15 24%, #fe9117 52%, #fe8017 77%, #ff7b18 96%)
            &:hover 
                border: 2px solid $PU_title_color
                h2, .b-icon
                    color: $PU_title_color
                &::before
                    opacity: 0
        @include _under_576_width()
            flex-direction: column
            button.products_Category_Button
                width: 100%
                justify-content: space-between
                padding-left: 30px
                padding-right: 30px
                &:last-child
                    margin-top: 20px

    // Swiper共同CSS
    @mixin our_Products_Swiper($product)
        // +flex_Center_Setting()
        width: 100%
        padding: 0 30px
        animation: fadeIn 0.2s
        .swiper-slide
            box-shadow: 5px 5px 10px 0 rgba(73, 80, 87, 0.5)
        .products_Link
            display: block
            figure
                img.mobile_Img
                    display: none
                figcaption
                    +size(100%, 25%)
                    +flex_Center_Setting()
                    position: absolute
                    left: 0
                    bottom: 0
                    transition: 0.3s 0.1s
                @include _under_768_width()
                    img.deskTop_Img
                        display: none
                    img.mobile_Img
                        display: block
                    figcaption
                        height: 20%
            .viewmore_Block
                +flex_Center_Setting(space-between)
                width: 100%
                max-width: 200px
                position: absolute
                left: 50%
                top: 50%
                padding: 15px 20px
                transform: translate(-50%, -50%)
                border: 2px solid #fff
                transition: 0.3s 0.1s
                opacity: 0
                pointer-events: none
                .b-icon
                    +size(25px)
                    color: #fff
                @include _under_768_width()
                    width: 80%
                    justify-content: center
                    padding: 12px 10px
                    .b-icon
                        +size(18px)
                        display: none
            &::before
                +pseudoBlock(100%, 25%, rgba(#261a11, 0.44))
                left: 0
                bottom: 0
                transition: 0.5s
                @include _under_768_width()
                    height: 20%
            &:hover
                figcaption
                    opacity: 0
                .viewmore_Block
                    opacity: 1
                &::before
                    height: 100%
        .swiper-pagination-fraction
            color: #c8c8c8
            text-align: center
            margin-top: 35px
            font-size: 22px
        .PVC_swiper-button-prev, .PVC_swiper-button-next, 
        .PU_swiper-button-prev, .PU_swiper-button-next,
        .NoSew_swiper-button-prev, .NoSew_swiper-button-next
            +size(30px, 50px)
            position: absolute
            margin-top: 0
            top: calc(50% - 35px)
            transform: translateY(-50%)
            color: #000
            z-index: 1
            @if $product == PU
                color: #e1bd87
            @else
                color: #000
            &::after
                display: none
            .icon
                +size(100%)
            @include _under_992_width()
                display: none
        .PVC_swiper-button-prev, .PU_swiper-button-prev, .NoSew_swiper-button-prev
            left: 0
        .PVC_swiper-button-next, .PU_swiper-button-next, .NoSew_swiper-button-next
            right: 0

    @mixin our_Products_Container($product)
        width: 100%
        background-color: #fff
        position: relative
        margin-top: 65px
        &::before, &::after
            +pseudoBlock(100%,10px)
            left: 0
            @if $product == PVC
                background-image: linear-gradient(to left, #d4de17 -13%, #d4de17 -1%, #2edbef 113%)
            @if $product == PU
                background-image: linear-gradient(to right, #fdc215 -5%, #fdc215 13%, #fdac15 25%, #fe9117 53%, #fe8017 83%, #ff7b18 106%)
        &::before
            top: 0
        &::after
            bottom: 0
        section.item_Group
            // width: $max_Width_Percent + 5%
            width: 100%
            max-width: $max_Container_Width + 200px
            margin: 0 auto
            padding: 70px 0 80px 0
            .textGroup
                width: $max_Width_Percent
                max-width: $max_Container_Width - 200px
                margin: 0 auto
                h2.Container_Text
                    @if $product == PVC
                        color: $PVC_title_color
                    @if $product == PU
                        color: $PU_title_color
                p.Container_Text
                    margin-top: 16px
            
            .our_Products_Swiper
                +our_Products_Swiper($product)
                margin-top: 40px

            .button_more
                +flex_Center_Setting
                margin-top: 60px
                .button_url
                    +text_Setting(font(5), 1, 1.5px, #fff, 400, "Noto Sans", center)
                    box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.16)
                    @if $product == PVC
                        background-color: #28bbad
                    @if $product == PU
                        background-color: #fe7d17
                    .arrowRight::before, .arrowRight::after
                        background-color: #fff
                @include _under_576_width()
                    .button_url
                        font-size: 16px
    
    #PVC_Film_Container
        +our_Products_Container(PVC)
    #PU_Container
        +our_Products_Container(PU)
        *
            // border: 1px solid #000
        section.PU_button_Group
            +size($max_Width_Percent, auto)
            +flex_Center_Setting(space-between)
            max-width: 420px
            margin: 40px auto 0
            .PU_button
                width: calc(50% - 11px)
                padding: 15px 0
                border: solid 3px #decebe
                border-radius: 8px
            .PU_button.PU_button_Show
                background-image: linear-gradient(99deg, #fdc215 -8%, #fdac15 15%, #fe9117 51%, #fe8017 82%, #ff7b18 106%)
                color: #fff
                border-width: 0px
                padding: calc(15px + 3px) 0
                animation: fadeIn 0.2s

</style>