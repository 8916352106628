<template lang="pug">
#Product_Template_1
    section.intro1_Container
        section.intro1
            article.title
                h2.productTemplate_H2 {{importProduct_Data.introduce.title}}
                p.intro_Text {{importProduct_Data.introduce.introText1}}
                p.intro_Text {{importProduct_Data.introduce.introText2}}
            swiper.cardSwiper(:options='cardSwiper')
                swiper-slide.cardSlide(v-for="swiperImg of importProduct_Data.introduce.swiper_Img" :key="swiperImg")
                    img(:src="getImgUrl(swiperImg)", :alt="importProduct_Data.introduce.title")
                .cardSwiper-pagination(slot="pagination")
                .swiper-button-prev(slot='button-prev')
                    b-icon.icon(icon="chevron-left")
                .swiper-button-next(slot='button-next')
                    b-icon.icon(icon="chevron-right")
        
    section.intro2_Container
        //- Characteristics
        .content_Characteristics
            section.blockSection.Characteristics_section
                h2.productTemplate_H2 Characteristics
                article.featureList
                    .list(v-for="feature of importProduct_Data.introduce.featureList")
                        b-icon(icon="check2")
                        p.featureText {{feature}}
        //- Applications
        .content_productApp
            section.productApp_section
                .productApp
                    article.blockSection.title
                        h2.productTemplate_H2 Applications
                        h3.productTemplate_H3 {{importProduct_Data.productApp.appText}}
                    swiper.blockSection.circle_Group_Swiper(:options="circle_Group_Swiper")
                        swiper-slide.circle(v-for="(circleImg, index) of importProduct_Data.productApp.circle_Img" :key="index")
                            img(:src="getImgUrl(circleImg.imgSrc)", :alt="circleImg.altText")
                        .circle_Group_Swiper-pagination(slot="pagination")
    
        .slotspecification_Form_Container(v-if="importProduct_Data.specification_table_Data")
            //- Specification規格表
            section.blockSection.specification_section
                h2.productTemplate_H2 Specification
                section.formatTable
                    ul(v-for="item of importProduct_Data.specification_table_Data")
                        li.form_head {{item.thead}}
                        li.form_items(v-for="td of item.td_Array") {{td}}
            section.blockSection.Catalogue_section
                h2.productTemplate_H2 Catalogue
                .fileGroup
                    a.file(v-for="file of importProduct_Data.Catalogue_PDF_file" :key="file.fileName"
                                    :href="`./pdf/Catalogue/${file.fileSrc}`", :title="file.fileName" target="_blank")
                        .pdf_icon
                            img(:src="getImgUrl(`pdfIcon_blue.png`)", alt="pdf Icon")
                        p {{file.fileName}}
                        b-icon(icon="download")

    Button_Style.button_more(btnTitle="Contact Us" linkType="routerName" link="Contact" arrow="right")
</template>

<script>
// import pageTop from "@/components/pageTop.vue"
import Button_Style from "@/components/Button_Style.vue"

export default {
    components: {
        // pageTop,
        Button_Style
    },

    props: {
        // colorType: {
        //     type: String,
        //     default: "coil"
        // },
        importProduct_Data: Object
    },

    data() {
        return{
            cardSwiper: {
                spaceBetween: 10,
                effect: 'slide',
                pagination: {
                    el: '.cardSwiper-pagination',
                    // type: 'fraction',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next'
                },
                // pagination: '.swiper-pagination',
                // autoplay: {delay: 5000},   // 輪播
                speed: 300,   // 切換速度
                loop: false,
                autoHeight: true
            },

            circle_Group_Swiper: {
                effect: 'slide',
                allowTouchMove: false,
                spaceBetween: 20,
                slidesPerGroup: 4,
                slidesPerView: 4,
                pagination: {
                    el: '.circle_Group_Swiper-pagination',
                    clickable: true   // 滑鼠可點擊導覽點點
                },
                breakpoints: {
                    // 992: { slidesPerGroup: 3, slidesPerView: 3 },
                    768: { slidesPerGroup: 1, slidesPerView: 2, allowTouchMove: true },
                    576: { slidesPerGroup: 1, slidesPerView: 1, allowTouchMove: true }
                },
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
        // changeMedia(media_ID) {
        //     this.importProduct_Data.mediaList.map( (value, index) => {
        //         media_ID === index ? value.isDisplay = true : value.isDisplay = false
        //     })
        // }
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import "@/css/section_Style"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

#Product_Template_1
    // h1.productTemplate_H1
    //     +text_Setting(32px, 1.3, 1.6px, #223b51, 500, "Noto Sans TC", left)
    h2.productTemplate_H2
        +text_Setting(42px, 1.3, 1px, #223b51, 600, "Noto Sans TC", left)
    h3.productTemplate_H3
        +text_Setting(20px, 1.3, 0.28px, #223b51, 400, "Noto Sans TC", center)
    a.file
        +text_Setting(18px, 1.33, 1.2px, #223b51, 400, "Noto Sans", center)
    p.intro_Text
        +text_Setting(20px, 1.36, 0.22px, #515354, 300, "Noto Sans TC", left)
    p.featureText
        +text_Setting(20px, 1.6, 0.2px, #515354, 400, "Noto Sans TC", left)
    li.form_head
        +text_Setting(18px, 1.6, 0.2px, #515354, 400, "Noto Sans TC", left)
    li.form_items
        +text_Setting(18px, 1.6, 0.2px, #515354, 300, "Noto Sans TC", left)
    img
        +size(100%)
    padding: 66px 0 80px

    @include _under_768_width()
        h2.productTemplate_H2
            font-size: 32px
    @include _under_576_width()
        h2.productTemplate_H2
            font-size: 28px
        h3.productTemplate_H3
            font-size: 18px
        p.featureText
            font-size: 18px
        li.form_head
            font-size: 14px
        li.form_items
            font-size: 14px
    @include _under_375_width()
        li.form_head
            font-size: 12px
        li.form_items
            font-size: 12px

    *
        // border: 1px solid #000
    section.intro1_Container
        +size($max_Width_Percent, auto)
        max-width: $max_Container_Width
        margin: 0 auto
        section.intro1
            +flex_Center_Setting(space-between)
            article.title
                width: 48%
                p
                    margin-top: 20px
                    &:nth-child(2)
                        font-weight: 400
                        color: #223b51
            .cardSwiper
                width: 48%
                margin: 0
                .cardSlide
                    +size(100%, auto)
                .cardSwiper-pagination
                    +flex_Center_Setting
                    margin-top: 20px
                    span
                        +size(15px)
                        &.swiper-pagination-bullet-active
                            background-color: #2edbef
                .swiper-button-prev, .swiper-button-next
                    +size(40px, calc(100% - 20px - 18px))   // 高度100%減掉margin-top和pagination點點的height
                    display: flex
                    top: 0
                    color: #a49d9d
                    margin-top: 0
                    &::after
                        display: none
                    .icon
                        +size(100%, auto)
                        color: #fefefe
                .swiper-button-prev
                    left: 0
                .swiper-button-next
                    right: 0
        @include _under_768_width()
            section.intro1
                flex-direction: column-reverse
                h2, p
                    text-align: center
                article.title, .cardSwiper
                    width: 100%
                article.title
                    margin-top: 20px

    section.intro2_Container
        margin-top: 100px
        position: relative
        &::before, &::after
            +pseudoBlock(100%, 10px)
            background-image: linear-gradient(79deg, #d4de17 -6%, #d4de17 3%, #2edbef 92%)
            left: 0
        &::before
            top: 0
        &::after
            bottom: 0

        .content_Characteristics, .slotspecification_Form_Container
            background-color: #f5fffe
        .content_productApp
            background-color: #d5efee
        .blockSection
            +size($max_Width_Percent, auto)
            max-width: $max_Container_Width
            margin: 0 auto
            // padding: 60px 0 90px

        section.Characteristics_section
            padding: 60px 0 90px
            h2
                border-bottom: 1px solid #707070
                padding-bottom: 18px
            article.featureList
                display: flex
                flex-direction: column
                // flex-wrap: wrap
                margin-top: 30px
                .list 
                    +flex_Center_Setting(flex-start)
                    // width: 100%
                    padding: 20px 0
                    .bi-check2
                        +size(45px)
                        min-width: 45px
                        border-radius: 50%
                        border: 3px solid #70f1e6
                        color: #70f1e6
                        padding: 5px
                        margin-right: 20px

        section.productApp_section
            padding: 60px 0 90px
            .productApp
                article.title
                    h2
                        text-align: center
                    h3
                        margin-top: 10px
                .circle_Group_Swiper
                    +flex_Center_Setting(space-between)
                    margin-top: 40px
                    .circle
                        +flex_Center_Setting
                        padding: 9px
                        border-radius: 50%
                        background-color: #fff
                        position: relative
                        z-index: 2
                        // @for $i from 1 through 5
                        //     &:nth-child(#{$i})
                        //         animation: fadeInUp 1.5s (0.3s*$i) backwards

                        &::before
                            +pseudoBlock(100%)
                            background-image: linear-gradient(to bottom, #cde13d, #2edbef)
                            left: 0
                            top: 0
                            border-radius: 50%
                            z-index: -1
                            transition: 0.3s
                            opacity: 0
                        &:hover
                            &::before
                                opacity: 1
                        img
                            border-radius: 50%
                    .circle_Group_Swiper-pagination
                        position: absolute
                        bottom: 0
                        display: none
                        span
                            +size(12px)
                            background-color: #5ab8eb
            @include _under_576_width()
                .productApp
                    .circle_Group_Swiper
                        width: 100%
                        padding: 0 25% calc(12px + 32px)
                        .circle
                            pointer-events: none
                            opacity: 0.3
                        .circle_Group_Swiper-pagination
                            +flex_Center_Setting
                        .circle.swiper-slide-active
                            opacity: 1
                            &::before
                                opacity: 1
        .slotspecification_Form_Container
            padding: 60px 0 90px
            section.specification_section
                h2
                    border-bottom: 1px solid #707070
                    padding-bottom: 18px
                    margin-bottom: 30px
                section.formatTable
                    +flex_Center_Setting(center, stretch)
                    background-color: #fff
                    ul
                        display: flex
                        flex-direction: column
                        flex: 1
                        li
                            +flex_Center_Setting
                            border: solid 1px #b5baba
                            padding: 15px 3px
                            &.form_head
                                background-color: #223b51
                                color: #fafafa
                            &.form_items
                                color: #707070
                                height: 100%
                @include _under_768_width()
                    section.formatTable
                        flex-direction: column
                        ul
                            flex-direction: row
                            li.form_head
                                width: 30%
                                min-width: 120px
                            li.form_items
                                +size(70%, auto)

            section.blockSection.Catalogue_section
                margin-top: 60px
                h2
                    border-bottom: 1px solid #707070
                    padding-bottom: 18px
                    margin-bottom: 30px
                +fileGroup(#2dd0e2) // 在css/section_Style.sass檔案裡面
        
    .button_more
        width: 260px
        margin: 80px auto 0
        & > a
            width: 260px
            box-shadow: 3px 3px 6px 0 rgba(29, 81, 97, 0.34)
            background-color: #4edcc6
</style>        