<template lang="pug">
#Semi_Rigid_Film
    pageTop(pageTitle="Semi Rigid Film" imgSrc="Products/PVC/Semi-rigid PVC Film/page top img_semi_desktop.jpg" imgSrc_Mobile="Products/PVC/Semi-rigid PVC Film/page top img_semi_mobile.jpg")
    Product_Template_1(:importProduct_Data="Semi_Rigid_Film_Data")

</template>

<script>
import pageTop from "@/components/pageTop.vue"
import Product_Template_1 from "@/components/Product_Template_1.vue"

export default {
    metaInfo() {
        return{
            title: 'Semi Rigid Film-',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Professional customized product service which apply in interior finishes and various advertisement."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "Professional customized product service which apply in interior finishes and various advertisement." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "Professional customized product service which apply in interior finishes and various advertisement." },
            ]
        }
    },

    components: {
        pageTop,
        Product_Template_1
        // Button_Style
    },

    data() {
        return{
            Semi_Rigid_Film_Data: {
                introduce: {
                    title: "Semi Rigid Film",
                    introText1: "Professional customized product service which apply in interior finishes and various advertisement.",
                    introText2: "Free of contamination, fish eye, and flow mark. Great heat resistance and lay flat. Suitable for gravure, ink-jet printing and oil ink typed offset or screen printing. The appearance finish falls into 3 categories: gloss, semi- gloss, and matte. Color and opacity can be customized by client demand.",
                    swiper_Img: [
                        "Products/PVC/Semi-rigid PVC Film/products discription_Semi-rigid PVC Film1.jpg",
                        "Products/PVC/Semi-rigid PVC Film/products discription_Semi-rigid PVC Film2.jpg",
                    ],
                    featureList: [
                        "Perfect lay-flat and uniform thickness. Matte, semi-gloss, and gloss surfaces are available",
                        "Good dimensional stability and low shrinkage and printing ability, which can be used for plastic flooring, furniture, vehicle graphics, wayfinding signs, and so on.",
                        "Special requirements are available, such as flame-resistant, anti-mildew, non-phthalate, weather-resistant, etc.",
                        "Customized low-volatility and non-phthalate component, meet the requirements of EU and US environmental regulations.",
                    ]
                },
                productApp: {
                    appText: "Doors, partitions, wallcovering, ceiling, flooring, furniture, post, bulletin board, advertisement in shopping malls, department stores. ",
                    circle_Img:[
                        {
                            imgSrc: "Products/PVC/Semi-rigid PVC Film/products application_Semi-rigid PVC1.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Semi-rigid PVC Film/products application_Semi-rigid PVC2.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Semi-rigid PVC Film/products application_Semi-rigid PVC3.jpg",
                            altText: ""
                        },
                        {
                            imgSrc: "Products/PVC/Semi-rigid PVC Film/products application_Semi-rigid PVC4.jpg",
                            altText: ""
                        }
                    ]
                },

                specification_table_Data: [
                    {
                        thead: "Thickness",
                        td_Array: ["0.07~0.50mm"]
                    },
                    {
                        thead: "Width",
                        td_Array: ["54”(max)"]
                    },
                    {
                        thead: "Put up",
                        td_Array: ["500yard(min)"]
                    },
                    {
                        thead: "PHR",
                        td_Array: ["32~58PHR"]
                    }
                ],

                Catalogue_PDF_file: [
                    {
                        fileName: "Semi rigid PVC film",
                        fileSrc: "semi rigid PVC film.pdf"
                    },
                    // {
                    //     fileName: "2021 Funiture",
                    //     fileSrc: "PU_ISO9001.pdf"
                    // },
                ]
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },
    },
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

</style>        