<template lang="pug">
main#Contact
    pageTop(pageTitle="Contact" imgSrc="Contact/Contact page top img_desktop.jpg" imgSrc_Mobile="Contact/Contact page top img_desktop.jpg")
    section.Contact_Container
        article
            h1.Contact_H1 Contact us
            p.Contact_Text If there's any advice or suggestion, welcome to email or call us. We're willing to respond soon and appreciate your feedbacks.
        form
            .inputBlock
                label(for="userName") Name
                input#userName(type="text" name="userName" v-model="inputStatus.name")
                span.warnningStatus {{warnningStatus.name_Warnning}}
            .inputBlock
                label(for="phone") Phone
                input#phone(type="tel" name="phone" v-model="inputStatus.phone")
                span.warnningStatus {{warnningStatus.phone_Warnning}}
            .inputBlock
                label(for="email") E-mail
                input#email(type="email" name="email" v-model="inputStatus.mail")
                span.warnningStatus {{warnningStatus.mail_Warnning}}
            .inputBlock
                label(for="products_DropdownList") Products
                select#products_DropdownList(name="products_DropdownList" v-model="inputStatus.selectClass")
                    option(selected disabled)
                    option(v-for="product of optionList" :value="product") {{product}}
                    //- option(value="Products_2") Products_2
                    //- option(value="Products_3") Products_3
                b-icon.select_icon(icon="caret-down-fill")
                span.warnningStatus {{warnningStatus.selectClass_Warnning}}
            .inputBlock
                label(for="Contents_Textarea") Contents
                textarea#Contents_Textarea(name="Contents_Textarea" v-model="inputStatus.message")
                span.warnningStatus {{warnningStatus.message_Warnning}}
            .inputBlock
                label.code_Verification_label(for="code_Verification") Verification Code
                input#code_Verification(type="text" name="code_Verification" v-model="inputStatus.code")
                span.warnningStatus {{warnningStatus.code_Warnning}}
                .code
                    img(src='@/assets/code.jpg')
                    p.codeText {{codeText}}
                    b-icon.codeRefresh_icon(icon='arrow-repeat' @click='codeVerification_Refresh')
        Button.button_Send(@click="sendMail_function") Send
        figure.backgroundImg
            img(:src="getImgUrl(`home_backgroundImg1.png`)", alt="alt")

    section.Contact_Information_Container
        hgroup
            h1.Contact_H1 Contact Information
            h2.Contact_H2 - Formosa Plastics Corporation - Plastics 1st Division -
        section.Contact_CardGroup
            article.Contact_article.Contact_PVC
                .Contact_Card
                    h2.Contact_H2 PVC Film
                    .content
                        p.Contact_Card_Text Name: Steve Chiou
                        p.Contact_Card_Text Tel: (+84) 2513560309 Ext 7407
                        p.Contact_Card_Text Fax: (+84) 2513560995
                        p.Contact_Card_Text Email: steveccc@npc.com.tw
            article.Contact_article.Contact_PU
                .Contact_Card
                    h2.Contact_H2 PU Leather
                    .content
                        p.Contact_Card_Text Name: Alex Chen
                        p.Contact_Card_Text Tel: (+84) 2513560309 Ext 7308
                        p.Contact_Card_Text Fax: (+84) 2513560327
                        p.Contact_Card_Text Email: nanya_alex@fic.com.vn
        figure.backgroundImg.bg_1
            img(:src="getImgUrl(`home_backgroundImg1.png`)", alt="alt")
        figure.backgroundImg.bg_2
            img(:src="getImgUrl(`home_backgroundImg1.png`)", alt="alt")

    mailLoadingAnimation(:isMailing="isMailing_Display")
</template>

<script>
import pageTop from "@/components/pageTop.vue"
import $ from 'jquery'
import mailLoadingAnimation from "@/components/mailLoadingAnimation.vue"
// import Button_Style from "@/components/Button_Style.vue"

export default {
    metaInfo() {
        return{
            title: `Contact-`,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "If there's any advice or suggestion, welcome to email or call us. We're willing to respond soon and appreciate your feedbacks."
                },
                // Open Graph / Facebook
                { property: 'og:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'og:description', content: "If there's any advice or suggestion, welcome to email or call us. We're willing to respond soon and appreciate your feedbacks." },

                // Twitter
                { property: 'twitter:url', content: "https://www.fpgpvcpuplastics.com/" },
                { property: 'twitter:description', content: "If there's any advice or suggestion, welcome to email or call us. We're willing to respond soon and appreciate your feedbacks." },
            ]
        }
    },

    components: {
        pageTop,
        mailLoadingAnimation
        // Button_Style
    },

    data() {
        return{
            optionList: [
                "Tape PVC Sheet",
                "Semi-rigid PVC Film",
                "Clear PVC Film",
                "PU",
                "No Sew",
                "Others"
            ],
            codeText: '',

            inputStatus: {
                name: '',
                phone: '',
                mail: '',
                selectClass: '',
                message: '',
                code: '',
            },

            warnningStatus: {
                name_Warnning: '',
                phone_Warnning: '',
                mail_Warnning: '',
                selectClass_Warnning: '',
                message_Warnning: '',
                code_Warnning: ''
            },

            isMailing_Display: false
        }
    },

    computed: {
        warnningText: {
            get() {
                return {
                    name: {
                        warn_1: "Type in your name.",
                    },
                    phone: {
                        warn_1: "Enter your phone.",
                    },
                    mail: {
                        warn_1: "Enter your e-mail.",
                        warn_2: "Invalid format,please try again.",
                    },
                    selectClass: {
                        warn_1: "Select category.",
                    },
                    message: {
                        warn_1: "Enter your suggestion.",
                    },
                    code: {
                        warn_1: "Enter verification code",
                        warn_2: "Incorrect code,please enter again.",
                    },
                    send_Warn: {
                        warn_1: "Not Completed！",
                        warn_2: "Completed！",
                        warn_3: "Error！",
                    }
                }
            }
        },

        sendData: {
            get() {
                return {
                    ...this.inputStatus,
                    contact_Title: "Contact Details",
                    sender: "Formosa Industries Corporation",
                    subject: "We have recived your suggestion!",
                    name_Title: "Name",
                    phone_Title: "Phone",
                    mail_Title: "E-mail",
                    class_Title: "Products",
                    message_Title: "Contents",
                    endText1: "Thank you for contacting us.",
                    endText2: " If you have any further queries,please email to：steveccc@npc.com.tw or nanya_alex@fic.com.vn",
                    Company_Title: "Formosa Industries Corporation"

                    // 對應的語系mail標題
                    // contact_Title: "聯絡資料",
                    // sender: "台灣興業責任股份有限公司",
                    // subject: "已收到您的聯絡資料",
                    // name_Title: "姓名",
                    // phone_Title: "電話",
                    // mail_Title: "信箱",
                    // class_Title: "產品類別",
                    // message_Title: "留言內容",
                    // endText1: "感謝您的來信",
                    // endText2: "如有任何疑問可直接來信：customerfirst@npc.com.tw",
                    // Company_Title: "台灣興業責任股份有限公司"
                }
            }
        }
    },

    methods: {
        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        // 亂數產生驗證碼
        codeVerification_Refresh() {
            let codeArray = [0,1,2,3,4,5,6,7,8,9,
                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
				'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
                'U', 'V', 'W', 'X', 'Y', 'Z']
            let newcode = []
            let index = Math.floor(Math.random() * codeArray.length)

            for(let i=0; i < 6; i++){
                newcode.push(codeArray[index])
                index = Math.floor(Math.random() * codeArray.length)
            }
            this.codeText = newcode.join('')
        },

        // 檢查每個input是否有空白，有填入東西則 completeCount + 1，這裡全部完成計數總共6
        check_input_noBlankCount() {
            let count = 0
            Object.keys(this.inputStatus).forEach((key)=>{
                if(this.inputStatus[key] === ''){
                    this.warnningStatus[`${key}_Warnning`] = this.warnningText[key].warn_1
                }
                else{
                    this.warnningStatus[`${key}_Warnning`] = ''
                    count++
                }
            })
            return count
        },

        // 檢查mail格式
        check_mail_isCorrect() {
            const emailRule =  /[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z0-9_]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,4})$/
            const mail = this.inputStatus.mail
            // return emailRule.test(mail) ? true : false
            if(!emailRule.test(mail) && mail !== ''){
                this.warnningStatus.mail_Warnning = this.warnningText.mail.warn_2
            }
            return emailRule.test(mail) ? true : false
            // return true
        },

        // 檢查驗證碼，全部正確才return true
        check_code_isCorrect() {
            const code = this.inputStatus.code
            if(code !== this.codeText){
                if(code !== ''){
                    this.warnningStatus.code_Warnning = this.warnningText.code.warn_2
                }
                return false
            }
            return true
        },

        // 確認表單是否完成填入
        check_Form_isCompleted() {
            // const _this = this
            let completeCount = 0

            // 檢查每個input是否有空白，計數6
            completeCount += this.check_input_noBlankCount()

            // 檢查mail格式，計數1
            this.check_mail_isCorrect() ? completeCount++ : false
            
            // 檢查驗證碼，計數1
            this.check_code_isCorrect() ? completeCount++ : false
            // console.log(completeCount)
            
            // 全部表單檢查計數完成，回傳true
            return completeCount === 8 ? true : false
        },

        sendMail_function() {
            if(!this.check_Form_isCompleted()){
                return alert(this.warnningText.send_Warn.warn_1)
            }

            this.isMailing_Display = true;
            $.ajax({
                type: 'post',
                url: '/email',
                data: this.sendData,
                // async: false,
                success: () => {
                    alert(this.warnningText.send_Warn.warn_2)
                    this.isMailing_Display = false
                    Object.keys(this.inputStatus).forEach(key => {this.inputStatus[key] = '' })
                },
                error: () => {
                    alert(this.warnningText.send_Warn.warn_3)
                    this.isMailing_Display = false
                },
            })
        }
    },

    created() {
        // 載入更新一次驗證碼
        this.codeVerification_Refresh()
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

main#Contact
    h1.Contact_H1
        +text_Setting(42px, 1, 2.1px, #223b51, 600, "Noto Sans", center)
    h2.Contact_H2
        +text_Setting(30px, 1.31, 0.32px, #4e4e4e, 400, "Noto Sans", center)
    p.Contact_Text
        +text_Setting(20px, 1.33, 0.24px, #074150, 400, "Noto Sans", center)
    p.codeText
        +text_Setting(32px, 1.7, 4px, #1a4766, 400, 'Noto Sans TC', center)
        font-style: italic
    p.Contact_Card_Text
        +text_Setting(20px, 2, 1.2px, #000, 400, 'Noto Sans TC', left)
    span.warnningStatus
        +text_Setting(font(2), 1.7, 2px, red, 400, "Noto Sans TC", left)
    label, input, select, textarea
        +text_Setting(18px, 1.55, 1.1px, #1a4766, 400, "Noto Sans", left)
    figure.backgroundImg
        position: absolute
        z-index: -1
    img
        +size(100%)
    @include _under_576_width()
        h1.Contact_H1
            font-size: 32px
            letter-spacing: normal
        p.Contact_Text
            font-size: 16px
        h2.Contact_H2
            font-size: 20px
            letter-spacing: normal
        p.Contact_Card_Text
            font-size: 16px
            letter-spacing: normal

    *
        // border: 1px solid #000
    padding-bottom: 190px

    section.Contact_Container
        +size($max_Width_Percent, auto)
        margin: 75px auto 0
        max-width: $max_Container_Width - 240px
        position: relative
        article
            p.Contact_Text
                margin-top: 30px
        form
            margin-top: 55px
            .inputBlock
                +flex_Center_Setting(flex-start)
                position: relative
                &:nth-child(1) ~ .inputBlock
                    margin-top: 50px
                label, input, select, textarea
                    +flex_Center_Setting(flex-start)
                label, input, select
                    height: 60px
                input, select, textarea
                    flex: 8
                    padding-left: 15px
                label
                    flex: 1
                    margin-right: 20px
                    min-width: 115px
                    max-width: 115px
                span.warnningStatus
                    position: absolute
                    left: calc(115px + 20px)
                    top: 100%
                select
                    appearance: none    // select預設下拉的icon取消
                    &::-ms-expand   // select預設下拉的icon取消(for IE)
                        display: none
                textarea
                    height: 200px
                & > .select_icon
                    +size(20px)
                    color: #707070
                    position: absolute
                    top: 50%
                    right: 20px
                    transform: translateY(-50%)

                .code_Verification_label, #code_Verification
                    flex: none
                #code_Verification
                    margin-right: 20px
                .code
                    +size(200px, 60px)
                    position: relative
                    p.codeText
                        position: absolute
                        left: 50%
                        top: 50%
                        transform: translate(-50%, -50%) skew(-39deg, 7deg) scale(1, 1.3) rotate(-0.2rad)
                        pointer-events: none
                        user-select: none // 禁止區塊選取
                    .codeRefresh_icon
                        +size(40px)
                        position: absolute
                        left: calc(100% + 10px)
                        top: 10px
                        cursor: pointer
                        transition: transform 0.3s
                        &:hover
                            transform: rotate(60deg)
        .button_Send
            +button_Style(20px)
            margin: 80px auto 0
        figure.backgroundImg
            +size(600px, auto)
            left: 80%
            top: 40%

        @include _under_768_width
            // article
            form
                .inputBlock
                    flex-direction: column
                    align-items: flex-start
                    // &:nth-child(1) ~ .inputBlock
                        // margin-top: 30px
                    label, select, textarea, input
                        flex: none
                        width: 100%
                    span.warnningStatus
                        left: 0
                    label, .code_Verification_label
                        +size(auto)
                        min-width: auto
                        max-width: none
                        margin-right: 0
                    & > .select_icon
                        top: auto
                        bottom: 30px
                        transform: translateY(50%)
                    #code_Verification
                        margin-right: 0
        @include _under_576_width()
            article
                p.Contact_Text
                    margin-top: 20px


    section.Contact_Information_Container
        +size($max_Width_Percent, auto)
        margin: 150px auto 0
        max-width: $max_Container_Width + 200px
        position: relative
        hgroup > h2.Contact_H2
            margin-top: 40px
        section.Contact_CardGroup
            +flex_Center_Setting(space-between)
            margin-top: 30px
            article.Contact_article
                +size(48.5%, auto)
                +flex_Center_Setting(space-between)
                padding: 15px
                border-radius: 16px
                position: relative
                .Contact_Card
                    +size(100%, auto)
                    +flex_Center_Setting(space-between)
                    background-color: #fff
                    border-radius: 16px
                    padding: 4%
                    h2.Contact_H2
                        width: 30%
                    .content
                        width: 65%
            .Contact_PVC
                background-image: linear-gradient(111deg, #d4de17 -13%, #d4de17 -1%, #2edbef 112%)
                h2
                    color: #4edcc6
                p
                    color: #4edcc6
            .Contact_PU
                background-image: linear-gradient(101deg, #fdc215 -7%, #fdac15 16%, #fe9117 50%, #fe8017 81%, #ff7b18 105%)
                h2
                    color: #fe7d17
                p
                    color: #fe7d17
        .bg_1
            +size(500px, auto)
            left: 0
            top: 0
            transform: translate(-60%, -30%)
        .bg_2
            +size(700px, auto)
            right: 0
            bottom: 0
            transform: translate(60%, 60%)

        @include _under_992_width
            section.Contact_CardGroup
                flex-direction: column
                article.Contact_article
                    width: 100%
                .Contact_PU
                    margin-top: 30px

</style>
