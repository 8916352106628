<template lang="pug">

#Button_Style(v-if="linkType === 'routerName'")
    router-link.button_url(:to="{name: link}" :class="arrowActive_computedClass") {{btnTitle}}
        .arrowRight(v-if="arrow === 'right' ")
        .arrowLeft(v-else-if="arrow === 'left' ")

#Button_Style(v-else-if="linkType === 'url'")
    a.button_url(:href="link" target="_blank" :class="arrowActive_computedClass") {{btnTitle}}
        .arrowRight(v-if="arrow === 'right' ")
        .arrowLeft(v-else-if="arrow === 'left' ")
</template>

<script>
export default {
    props: {
        btnTitle: String,
        linkType: String,
        link: String,
        arrow: {
            type: String,
            default: null
        },
    },

    computed: {
        arrowActive_computedClass() {
        
        // 三元判斷式寫法：時間複雜度4
        return this.arrow === 'right' ? 'arrowActiveRight' : this.arrow === 'left' ? 'arrowActiveLeft' : false

        // switch寫法：時間複雜度8
            // switch(this.arrow){
            //     case 'right': 
            //         return 'arrowActiveRight'
            //     case 'left': 
            //         return 'arrowActiveLeft'
            //     default: 
            //         return false
            // }

        // if-else寫法：時間複雜度8
            // if(this.arrow === 'right'){
            //     return 'arrowActiveRight'
            // }
            // else if(this.arrow === 'left'){
            //     return 'arrowActiveLeft'
            // }
            // else{
            //     return false
            // }
        }
    }
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#Button_Style
    *
        // border: 1px solid #000
    .button_url
        +size(auto, 69px)
        +flex_Center_Setting()
        +text_Setting(font(3), normal, 2.8px, white, 400, 'Noto Sans', center)
        min-width: 240px
        padding: 0 30px
        position: relative
        border-radius: 35px
        transition: 0.2s linear 0s
        border: solid 1px #fff
        transition: 0.2s
        +arrowRight(45px, 10px, #fff)
        +arrowLeft(45px, 10px, #fff)

        // &:hover
    
    .button_url.arrowActiveRight
        justify-content: space-around
        .arrowRight
            margin-left: 20px
            transition: 0.2s
        &:hover > .arrowRight
            transform: translateX(10px)

    .button_url.arrowActiveLeft
        justify-content: space-around
        flex-direction: row-reverse
        .arrowLeft
            margin-right: 20px
            transition: 0.2s
        &:hover > .arrowLeft
            transform: translateX(-10px)

    // .button_url.style2
    //     background-image: linear-gradient(58deg, #63c6bc 11%, #83cd8e 49%,  #d4de17 90%)
    //     &:hover
    //         background-image: linear-gradient(58deg, #63c6bc 27%, #83cd8e 62%,  #d4de17 86%)
</style>