<template lang="pug">
footer#footer_body
    section#footerBlock
        section.footer_container
            .leftColumn
                router-link.logoLink(:to="{name: 'Home'}" v-scroll-to="{el:'#navbar'}")
                    img(src="@/assets/logo_white.png")
                .textBlock1
                    p Nhon Trach III, Industrial Zone,
                    p Hiep Phuoc Commune,
                    p Nhon Trach District, Dong Nai Province, Viet Nam
                .textBlock2
                    p Tel：84-251-3560309 Ext 7407
                    p Fax：84-251-3560995
                    p Email：nanya_alex@fic.com.vn / mikechien@npc.com.tw
            .midColumn
                router-link.H1(:to="{name: 'Company'}" v-scroll-to="{el:'#navbar'}") COMPANY
                router-link.H1(:to="{name: 'Quality'}" v-scroll-to="{el:'#navbar'}") QUALITY
                router-link.H1(:to="{name: 'Contact'}" v-scroll-to="{el:'#navbar'}") CONTACT
                a.H1(href="https://www.nanya-cyber-shopping-mall.com/" target="_blank") NAN YA Cyber Shopping Mall
                a.H1(href="https://www.nanya-plastics-mall.com.tw/" target="_blank") More NAN YA Products
                    img.mallIMG(:src="getImgUrl('nanya-plastics-mall-IMG.png')")
            .rightColumn
                .row
                    h1.footer_H1 PRODUCTS
                .bottom
                    .column.PVC_Film_column
                        .row
                            router-link.H2(:to="{name: 'PVC_Film'}" v-scroll-to="{el:'#navbar'}") PVC FILM
                        .row
                            router-link.H4(:to="{name: 'Tape_PVC_Sheet'}" v-scroll-to="{el:'#navbar'}") Tape PVC Sheet
                            router-link.H4(:to="{name: 'Semi_Rigid_Film'}" v-scroll-to="{el:'#navbar'}") Semi-rigid PVC Film
                            router-link.H4(:to="{name: 'Clear_Film'}" v-scroll-to="{el:'#navbar'}") Clear PVC Film
                    .column.PU_Leather_column
                        .row
                            router-link.H2(:to="{name: 'PU_Leather'}" v-scroll-to="{el:'#navbar'}") PU Leather
                        .row.PU_row
                            .row
                                router-link.H3(:to="{name: 'PU'}" @click.native="mutations_product_Display_index(0)" v-scroll-to="{el:'#navbar'}") PU
                            .row.PU_products_List
                                router-link.H4(v-for="(productPU, index) of PU_List" :key="index" @click.native="mutations_product_Display_index(index)"
                                                :to="{name: 'PU'}" v-scroll-to="{el:'#navbar', offset: 350}") {{productPU}}
                        .row.NoSew_row
                            .row
                                router-link.H3(:to="{name: 'No_Sew'}" @click.native="mutations_product_Display_index(0)" v-scroll-to="{el:'#navbar'}") No Sew
                            .row.PU_products_List
                                router-link.H4(:to="{name: 'No_Sew'}" v-scroll-to="{el:'#navbar', offset: 385}" @click.native="mutations_product_Display_index(0)") TPU HOT MELT FILM
                                router-link.H4(:to="{name: 'No_Sew'}" v-scroll-to="{el:'#navbar', offset: 385}" @click.native="mutations_product_Display_index(1)") HOT MELT FILM
            section.product_section_phone
                router-link.H1(:to="{name: 'Company'}" v-scroll-to="{el:'#navbar'}") COMPANY
                    b-icon(icon="chevron-right")
                router-link.H1(:to="{name: 'Quality'}" v-scroll-to="{el:'#navbar'}") QUALITY
                    b-icon(icon="chevron-right")
                router-link.H1(:to="{name: 'PVC_Film'}" v-scroll-to="{el:'#navbar'}") PVC Film
                    b-icon(icon="chevron-right")
                router-link.H1(:to="{name: 'PU_Leather'}" v-scroll-to="{el:'#navbar'}") PU Leather
                    b-icon(icon="chevron-right")
                router-link.H1(:to="{name: 'Contact'}" v-scroll-to="{el:'#navbar'}") CONTACT
                    b-icon(icon="chevron-right")
                a.H1(href="https://www.nanya-cyber-shopping-mall.com/" target="_blank") NAN YA Cyber Shopping Mall
                    b-icon(icon="chevron-right")
                a.H1(href="https://www.nanya-plastics-mall.com.tw/" target="_blank") More NAN YA Products
                    b-icon(icon="chevron-right")

        //- button.backButton(v-scroll-to="{el:'#navbar'}")
        //-     .back
        //-     p Back to top
        //- button.backButton(v-scroll-to="{el:'#navbar'}")
        //-     b-icon(icon="chevron-up")
        //-     p Back to top

    section#copyright
        p Copyright © 2021 Formosa Plastics CO.,LTD.  All Rights Reserved.
        p (NPC, Mar 1, 2023)
</template>

<script>
// import cardBody from "@/components/cardBody.vue"
// import Button_Style from "@/components/Button_Style.vue"

export default {

    components: {
        // cardBody,
        // Button_Style
    },

    data() {
        return{
            PU_List: [
                "double coating Leather",
                "Shifting Effect Leather",
                "Two-tone Leather",
                "Printing Leather",
                "4-way Stretchable Leather",
                "Anti-bacterial PU Leather",
                "Microfriber",
                "Prote PU",
                "WPN PFCs Free Leather",
                "PU Skin"
            ]
        }
    },

    // computed: {},

    methods: {
        getImgUrl(pic) {
            return require("@/assets/" + pic);
        },
        mutations_product_Display_index(input_index) {
            this.$store.commit('change_product_Display_index', input_index);
        },
    },

    // mounted() {},

    // created() {}
}
</script>

<style lang="sass">
@import "@/css/my_Style.sass"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#footer_body
    // border: 1px solid #fff
    *
        // border: 1px solid #fff
    
    .mallIMG
        +size(100%, auto)
        margin-top: 10px
        
    #footerBlock
        background-color: #292929
        padding: 60px 0
        position: relative
        a.H1, h1.footer_H1
            +text_Setting(font(2), 1, 1.2px, #2edbef, 600, "Noto Sans", left)
        a.H2
            +text_Setting(font(2), 1, 1.6px, #fafafa, 600, "Noto Sans", left)
        a.H3
            +text_Setting(font(1), 1, 0.35px, #fafafa, 400, "Noto Sans", left)
            margin-top: 22px
        a.H4
            +text_Setting(font(1), 1.2, 0.35px, #fafafa, 400, "Noto Sans", left)
        p
            +text_Setting(font(1), 1.8, normal, #fafafa, 400, "Noto Sans", left)
        .row
            margin-right: 0
            margin-left: 0

        .footer_container
            +size(95%, auto)
            +flex_Center_Setting(space-between, flex-start)
            max-width: 1500px
            margin: 0 auto
            position: relative
            h1.footer_H1
                padding-bottom: 8px
            a
                padding-bottom: 8px
                transition: transform 0.3s
            a.H1, a.H2
                position: relative
                &::before
                    +pseudoBlock(100%, 3px, #2edbef)
                    left: 0
                    bottom: 0
                    opacity: 0
                    transition: opacity 0.5s
                &:hover::before
                    opacity: 1
            a.H3, a.H4
                &:hover
                    transform: scale(1.1)
                
            .leftColumn
                width: 20%
                .logoLink
                    +size(100%, auto)
                    display: block
                    img
                        +size(100%)
                .textBlock1
                    margin-top: 30px
                .textBlock2
                    margin-top: 20px
            .midColumn
                +flex_Center_Setting(center, flex-start)
                flex-direction: column
                width: 10%
                a
                    margin-top: 30px
                    &:first-child
                        margin-top: 0
            .rightColumn
                width: 63%
                .bottom
                    display: flex
                    justify-content: space-between
                    margin-top: 30px
                    .column
                        &.PVC_Film_column
                            width: 15%
                            .row:nth-child(2)
                                flex-direction: column
                                a
                                    margin-top: 20px
                        &.PU_Leather_column
                            width: 82%
                            .PU_row
                                flex-direction: column
                                .PU_products_List
                                    flex-wrap: wrap
                                    margin-top: 20px
                                    a
                                        width: 15%
                                        padding-bottom: 0
                                    a:not(:nth-child(6n+1))
                                        margin-left: 2%
                                    a:nth-child(6) ~ a
                                        margin-top: 20px
                            .NoSew_row
                                flex-direction: column
                                .PU_products_List
                                    flex-wrap: wrap
                                    margin-top: 20px
                                    a:not(:first-child)
                                        margin-left: 2%
            section.product_section_phone
                // +flex_Center_Setting(space-between)
                justify-content: space-between
                flex-direction: column
                width: 48%
                display: none
                a
                    +flex_Center_Setting(space-between)
                    +size(100%, auto)
                    border-bottom: 1px solid #fafafa
                    padding-right: 15px
                    &::before
                        display: none
                    .b-icon
                        +size(20px)
            
            @include _under_992_width()
                .rightColumn
                    .bottom .column
                        &.PU_Leather_column
                            .PU_row
                                .PU_products_List
                                    a
                                        width: 22%
                                    a:not(:nth-child(6n+1))
                                        margin-left: 0
                                    a:not(:nth-child(4n+1))
                                        margin-left: 4%
                                    a:nth-child(4) ~ a
                                        margin-top: 20px
            @include _under_768_width()
                width: $max_Width_Percent
                // align-items: stretch
                a:nth-child(1) ~ a
                    margin-top: 30px
                .leftColumn
                    width: 48%
                .midColumn, .rightColumn
                    display: none
                section.product_section_phone
                    display: flex
                    align-self: stretch
            @include _under_576_width()
                flex-direction: column
                .leftColumn
                    width: 100%
                    display: flex
                    justify-content: space-between
                    flex-wrap: wrap
                    .logoLink
                        max-width: 330px
                    .textBlock1, .textBlock2
                        width: 46%
                        margin-top: 30px
                section.product_section_phone
                    width: 100%
                    margin-top: 30px
                    flex-direction: row
                    flex-wrap: wrap
                    a
                        width: 46%
                        height: 50px
                        &:nth-child(1), &:nth-child(2)
                            margin-top: 0
        // .backButton
        //     +size(80px)
        //     border: 3px solid #2edbef
        //     border-radius: 50%
        //     padding: 15px
        //     position: absolute
        //     right: 5%
        //     top: 55px
        //     svg.b-icon
        //         +size(100%)
        //         color: #2edbef
        //     p
        //         +text_Setting(font(2), 1, 0.16px, #2edbef, 300, "Noto Sans", center)
        //         width: 150%
        //         position: absolute
        //         left: 50%
        //         bottom: -30px
        //         transform: translateX(-50%)

    #copyright
        *
            // border: 1px solid #fff
        background-color: #4e4e4e
        padding: 15px 0
        p
            +text_Setting(font(1), 1, 1.4px, #fff, 400, "Noto Sans", center)
            width: $max_Width_Percent
            max-width: $max_Container_Width
            margin: 0 auto
            padding: 5px 0
        @include _under_576_width()
            p
                font-size: 12px
                line-height: 1.5
</style>